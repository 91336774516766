import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IFiyatKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IFiyatKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  SaveFiyatListe: number;
  SaveFiyatListe_dummy: number;
  GoBack: string;
  GoBack_dummy: string;
  getFiyatListById: any[];
  getFiyatListById_dummy: any[];
  ProjeTuruSelectById: any[];
  ProjeTuruSelectById_dummy: any[];
  getListFiyatListeAd: any[];
  getListFiyatListeAd_dummy: any[];
  sbProjeTuruList: any[];
  sbProjeTuruList_dummy: any[];
  sbBolgeList: any[];
  sbBolgeList_dummy: any[];
  sbFiyatlandirmaTipi: any[];
  sbFiyatlandirmaTipi_dummy: any[];
  sbMecraList: any[];
  sbMecraList_dummy: any[];
  sbParaBirim: any[];
  sbParaBirim_dummy: any[];
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  SaveFiyatListeAd: number;
  SaveFiyatListeAd_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  SetValueOf: string;
  SetValueOf_dummy: string;
  isComp165525Visible: "visible" | "hidden";
  isComp208845Visible: "visible" | "hidden";
  isComp587971Visible: "visible" | "hidden";
}

export class FiyatKart_ScreenBase extends React.PureComponent<IFiyatKart_ScreenProps, any> {
  fiyatkart_2793_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatkart_56970_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatkart_188314_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatkart_546790_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatkart_463516_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatkart_958097_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "b971fd7a-bdb3-4384-8d97-a9d1fe1b6511",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 604367, PropertyName: "label", Value: "Kaydet" },
        { Id: 400481, PropertyName: "value", Value: "Fiyat Listesi" },
        { Id: 566524, PropertyName: "value", Value: "Yeni fiyat listesi ekle" },
        { Id: 295397, PropertyName: "placeholder", Value: "Yeni fiyat listesi ismini yazınız..." },
        { Id: 2793, PropertyName: "placeholder", Value: "..." },
        { Id: 318503, PropertyName: "value", Value: "Proje Türü" },
        { Id: 56970, PropertyName: "placeholder", Value: "..." },
        { Id: 993411, PropertyName: "value", Value: "Bölge" },
        { Id: 188314, PropertyName: "placeholder", Value: "..." },
        { Id: 11299, PropertyName: "value", Value: "Mecra" },
        { Id: 546790, PropertyName: "placeholder", Value: "..." },
        { Id: 979337, PropertyName: "value", Value: "Fiyatlandırma Tipi" },
        { Id: 463516, PropertyName: "placeholder", Value: "..." },
        { Id: 458810, PropertyName: "value", Value: "Fiyat" },
        { Id: 719769, PropertyName: "value", Value: "İndirimli Fiyat" },
        { Id: 928314, PropertyName: "value", Value: "Para Birimi" },
        { Id: 958097, PropertyName: "placeholder", Value: "..." },
        { Id: 3708, PropertyName: "value", Value: "Birim/Block Sn." },
        { Id: 628236, PropertyName: "value", Value: "Min Sınır Sn." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.fiyatkart_2793_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatkart_56970_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatkart_188314_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatkart_546790_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatkart_463516_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatkart_958097_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      SaveFiyatListe: "",
      GoBack: "",
      getFiyatListById: [],
      ProjeTuruSelectById: [],
      getListFiyatListeAd: [],
      sbProjeTuruList: [],
      sbBolgeList: [],
      sbFiyatlandirmaTipi: [],
      sbMecraList: [],
      sbParaBirim: [],
      ToggleVisibilityOf: "",
      SaveFiyatListeAd: "",
      Notify: false,
      SetValueOf: "",
      isComp165525Visible: "hidden",
      isComp208845Visible: "hidden",
      isComp587971Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("fiyatkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.FiyatKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("fiyatkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("fiyatkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.FiyatKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      fiyatkart_2793_value: this.state.getFiyatListById?.at?.(0)?.fiyatListeAdID ?? undefined,
      fiyatkart_56970_value: this.state.getFiyatListById?.at?.(0)?.projeTuruID ?? undefined,
      fiyatkart_188314_value: this.state.getFiyatListById?.at?.(0)?.bolgeID ?? undefined,
      fiyatkart_546790_value: this.state.getFiyatListById?.at?.(0)?.mecraID ?? undefined,
      fiyatkart_463516_value: this.state.getFiyatListById?.at?.(0)?.fiyatlandirmaTipiID ?? undefined,
      fiyatkart_513542_value: this.state.getFiyatListById?.at?.(0)?.fiyat ?? undefined,
      fiyatkart_696454_value: this.state.getFiyatListById?.at?.(0)?.fiyatIndirimli ?? undefined,
      fiyatkart_958097_value: this.state.getFiyatListById?.at?.(0)?.paraBirimID ?? undefined,
      fiyatkart_256996_value: this.state.getFiyatListById?.at?.(0)?.snModBlock ?? undefined,
      fiyatkart_345880_value: this.state.getFiyatListById?.at?.(0)?.snEsitKucuk ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  FiyatKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatKart/FiyatKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFiyatListById = result?.data.getFiyatListById;
    formVars.fiyatkart_2793_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatListeAdID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatListeAdID
        : null;
    formVars.fiyatkart_2793_options = stateVars.getListFiyatListeAd;
    formVars.fiyatkart_56970_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].projeTuruID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].projeTuruID
        : null;
    formVars.fiyatkart_56970_options = stateVars.sbProjeTuruList;
    formVars.fiyatkart_188314_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].bolgeID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].bolgeID
        : null;
    formVars.fiyatkart_188314_options = stateVars.sbBolgeList;
    formVars.fiyatkart_546790_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].mecraID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].mecraID
        : null;
    formVars.fiyatkart_546790_options = stateVars.sbMecraList;
    formVars.fiyatkart_463516_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatlandirmaTipiID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatlandirmaTipiID
        : null;
    formVars.fiyatkart_463516_options = stateVars.sbFiyatlandirmaTipi;
    formVars.fiyatkart_513542_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyat
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyat
        : null
    );
    formVars.fiyatkart_696454_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatIndirimli
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatIndirimli
        : null
    );
    formVars.fiyatkart_958097_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].paraBirimID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].paraBirimID
        : null;
    formVars.fiyatkart_958097_options = stateVars.sbParaBirim;
    formVars.fiyatkart_256996_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].snModBlock
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].snModBlock
        : null
    );
    formVars.fiyatkart_345880_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].snEsitKucuk
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].snEsitKucuk
        : null
    );
    stateVars.ProjeTuruSelectById = result?.data.projeTuruSelectById;
    stateVars.getListFiyatListeAd = result?.data.getListFiyatListeAd;

    formVars.fiyatkart_2793_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatListeAdID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatListeAdID
        : null;
    formVars.fiyatkart_2793_options = stateVars.getListFiyatListeAd;
    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;

    formVars.fiyatkart_56970_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].projeTuruID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].projeTuruID
        : null;
    formVars.fiyatkart_56970_options = stateVars.sbProjeTuruList;
    stateVars.sbBolgeList = result?.data.sbBolgeList;

    formVars.fiyatkart_188314_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].bolgeID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].bolgeID
        : null;
    formVars.fiyatkart_188314_options = stateVars.sbBolgeList;
    stateVars.sbFiyatlandirmaTipi = result?.data.sbFiyatlandirmaTipi;

    formVars.fiyatkart_463516_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatlandirmaTipiID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatlandirmaTipiID
        : null;
    formVars.fiyatkart_463516_options = stateVars.sbFiyatlandirmaTipi;
    stateVars.sbMecraList = result?.data.sbMecraList;

    formVars.fiyatkart_546790_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].mecraID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].mecraID
        : null;
    formVars.fiyatkart_546790_options = stateVars.sbMecraList;
    stateVars.sbParaBirim = result?.data.sbParaBirim;

    formVars.fiyatkart_958097_value =
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].paraBirimID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].paraBirimID
        : null;
    formVars.fiyatkart_958097_options = stateVars.sbParaBirim;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.fiyatkart_2793_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatListeAdID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatListeAdID
        : null
    );

    stateVars.dataSource_2793 = this.state.getListFiyatListeAd;
    stateVars.dataSource_2793 = this.state.getListFiyatListeAd;
    formVars.fiyatkart_56970_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].projeTuruID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].projeTuruID
        : null
    );

    stateVars.dataSource_56970 = this.state.sbProjeTuruList;
    stateVars.dataSource_56970 = this.state.sbProjeTuruList;
    formVars.fiyatkart_188314_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].bolgeID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].bolgeID
        : null
    );

    stateVars.dataSource_188314 = this.state.sbBolgeList;
    stateVars.dataSource_188314 = this.state.sbBolgeList;
    formVars.fiyatkart_546790_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].mecraID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].mecraID
        : null
    );

    stateVars.dataSource_546790 = this.state.sbMecraList;
    stateVars.dataSource_546790 = this.state.sbMecraList;
    formVars.fiyatkart_463516_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatlandirmaTipiID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatlandirmaTipiID
        : null
    );

    stateVars.dataSource_463516 = this.state.sbFiyatlandirmaTipi;
    stateVars.dataSource_463516 = this.state.sbFiyatlandirmaTipi;
    formVars.fiyatkart_513542_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyat
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyat
        : null
    );

    formVars.fiyatkart_696454_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].fiyatIndirimli
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].fiyatIndirimli
        : null
    );

    formVars.fiyatkart_958097_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].paraBirimID
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].paraBirimID
        : null
    );

    stateVars.dataSource_958097 = this.state.sbParaBirim;
    stateVars.dataSource_958097 = this.state.sbParaBirim;
    formVars.fiyatkart_256996_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].snModBlock
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].snModBlock
        : null
    );

    formVars.fiyatkart_345880_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatListById?.length > 0
        ? stateVars.getFiyatListById[0].snEsitKucuk
        : this.state.getFiyatListById?.length > 0
        ? this.state.getFiyatListById[0].snEsitKucuk
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  FiyatKartComponent_604367_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_958097_value", "value", "sbParaBirim", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatkart_958097_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_188314_value", "value", "sbBolgeList", "id", "id")
        ),
        "Guid"
      ),
      Fiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_513542_value", "value", "", "", "")
        ),
        "number"
      ),
      FiyatIndirimli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_696454_value", "value", "", "", "")
        ),
        "number"
      ),
      FiyatlandirmaTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_463516_value", "value", "sbFiyatlandirmaTipi", "id", "id")
        ),
        "Guid"
      ),
      FiyatListeAdID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_2793_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_546790_value", "value", "sbMecraList", "id", "id")
        ),
        "Guid"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_958097_value", "value", "sbParaBirim", "id", "id")
        ),
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_56970_value", "value", "sbProjeTuruList", "id", "id")
        ),
        "Guid"
      ),
      snEsitKucuk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_345880_value", "value", "", "", "")
        ),
        "number"
      ),
      snModBlock_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_256996_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatKart/FiyatKartComponent_604367_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveFiyatListe = result?.data.saveFiyatListe;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatKartComponent_604367_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatKartComponent_604367_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatKartComponent_707304_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatKartComponent_566524_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp165525Visible", "string")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatKartComponent_24681_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_295397_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "fiyatkart_295397_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aktif_0: ReactSystemFunctions.convertToTypeByName("True", "boolean"),
      OrderBy_0: ReactSystemFunctions.convertToTypeByName("1", "number"),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_295397_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatKart/FiyatKartComponent_24681_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveFiyatListeAd = result?.data.saveFiyatListeAd;
    stateVars.getListFiyatListeAd = result?.data.getListFiyatListeAd;

    formVars.fiyatkart_2793_options = stateVars.getListFiyatListeAd;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatKartComponent_24681_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatKartComponent_24681_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp165525Visible", "string")
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "cfc426dc_c47c_c5e5_081e_ee31260c2df6_notify",
        this.defaultML,
        "Yeni fiyat listesi eklendi."
      ),
      ReactSystemFunctions.convertToTypeByName("info", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "number"),
      null,
      null,
      null
    );

    stateVars.dataSource_2793 = this.state.getListFiyatListeAd;
    stateVars.dataSource_2793 = this.state.getListFiyatListeAd;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatKartComponent_255726_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp165525Visible", "string")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatKartComponent_56970_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_56970_value", "value", "sbProjeTuruList", "id", "projeTuruRefKod")
        ),
        "string"
      ),
      ProjeTuruRefKod_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_56970_value", "value", "sbProjeTuruList", "id", "projeTuruRefKod")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "FiyatKart/FiyatKartComponent_56970_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbBolgeList = result?.data.sbBolgeList;

    formVars.fiyatkart_188314_options = stateVars.sbBolgeList;
    stateVars.sbFiyatlandirmaTipi = result?.data.sbFiyatlandirmaTipi;

    formVars.fiyatkart_463516_options = stateVars.sbFiyatlandirmaTipi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatKartComponent_56970_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatKartComponent_56970_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_188314 = this.state.sbBolgeList;
    stateVars.dataSource_188314 = this.state.sbBolgeList;

    stateVars.dataSource_463516 = this.state.sbFiyatlandirmaTipi;
    stateVars.dataSource_463516 = this.state.sbFiyatlandirmaTipi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatKartComponent_463516_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatkart_256996_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatkart_345880_value", "string"),
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatKartComponent_463516_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  FiyatKartComponent_463516_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_463516_value", "value", "sbFiyatlandirmaTipi", "id", "tip")
        ),
        "1"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("fiyatkart_256996_value", "string"),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_463516_value", "value", "sbFiyatlandirmaTipi", "id", "birim")
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatKartComponent_463516_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatKartComponent_463516_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  FiyatKartComponent_463516_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_463516_value", "value", "sbFiyatlandirmaTipi", "id", "tip")
        ),
        "2"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("fiyatkart_345880_value", "string"),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatkart_463516_value", "value", "sbFiyatlandirmaTipi", "id", "birim")
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
