import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IIsTakipListNot_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IIsTakipListNot_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  IsTakipListNotByBFId: any[];
  IsTakipListNotByBFId_dummy: any[];
  setIsTakipListNotOkunduUpdate: number;
  setIsTakipListNotOkunduUpdate_dummy: number;
  SetValueOf: string;
  SetValueOf_dummy: string;
  setIsTakipListNotSave: number;
  setIsTakipListNotSave_dummy: number;
  isComp571985Visible: "visible" | "hidden";
  isComp802602Visible: "visible" | "hidden";
}

export class IsTakipListNot_ScreenBase extends React.PureComponent<IIsTakipListNot_ScreenProps, any> {
  istakiplistnot_563986_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "b8423ff0-b97d-4072-ab55-dd88cba66e5c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 351749, PropertyName: "value", Value: "Notlar" },
        { Id: 396237, PropertyName: "value", Value: "Notu Yaz" },
        { Id: 617040, PropertyName: "placeholder", Value: "..." },
        { Id: 101563, PropertyName: "label", Value: "Vazgeç" },
        { Id: 274994, PropertyName: "label", Value: "Kaydet" },
        { Id: 563986, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 457094, PropertyName: "value", Value: "[datafield:mesaj]" },
        { Id: 802602, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 33395, PropertyName: "value", Value: "[datafield:nick]" },
        { Id: 449248, PropertyName: "value", Value: "[datafield:olusturmatarihiformat]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.istakiplistnot_563986_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ChangeVisibilityOf: "",
      IsTakipListNotByBFId: [],
      setIsTakipListNotOkunduUpdate: "",
      SetValueOf: "",
      setIsTakipListNotSave: "",
      isComp571985Visible: "hidden",
      isComp802602Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("istakiplistnot", "");
      return;
    }

    if (true) {
      await this.IsTakipListNotPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("istakiplistnot", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("istakiplistnot", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.IsTakipListNotPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  IsTakipListNotPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BasvuruFormuID ?? this.props.screenInputs.basvuruformuid,
        "Guid"
      ),
      BFId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BasvuruFormuID ?? this.props.screenInputs.basvuruformuid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IsTakipListNot/IsTakipListNotPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsTakipListNotByBFId = result?.data.isTakipListNotByBFId;

    stateVars.setIsTakipListNotOkunduUpdate = result?.data.setIsTakipListNotOkunduUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListNotPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListNotPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_563986 = this.state.IsTakipListNotByBFId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  IsTakipListNotComponent_251494_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp571985Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("visible", "string")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListNotComponent_101563_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp571985Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("istakiplistnot_617040_value", "string"),
      "",
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IsTakipListNotComponent_274994_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BasvuruFormuID ?? this.props.screenInputs.basvuruformuid,
        "Guid"
      ),
      Mesaj_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "istakiplistnot_617040_value", "value", "", "", "")
        ),
        "string"
      ),
      Okundu_0: ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      OlusturanKisi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      OlusturmaTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BasvuruFormuID ?? this.props.screenInputs.basvuruformuid,
        "Guid"
      ),
      BFId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BasvuruFormuID ?? this.props.screenInputs.basvuruformuid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IsTakipListNot/IsTakipListNotComponent_274994_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setIsTakipListNotSave = result?.data.setIsTakipListNotSave;
    stateVars.IsTakipListNotByBFId = result?.data.isTakipListNotByBFId;

    stateVars.setIsTakipListNotOkunduUpdate = result?.data.setIsTakipListNotOkunduUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.IsTakipListNotComponent_274994_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IsTakipListNotComponent_274994_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp571985Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("istakiplistnot_617040_value", "string"),
      "",
      null
    );

    stateVars.dataSource_563986 = this.state.IsTakipListNotByBFId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
