import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUrunPayDagilimKartelifsiz_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUrunPayDagilimKartelifsiz_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setEserPayDagilimTelifsizSave: number;
  setEserPayDagilimTelifsizSave_dummy: number;
  GoBack: string;
  GoBack_dummy: string;
  sbEserSahibiList: any[];
  sbEserSahibiList_dummy: any[];
  sbCariList: any[];
  sbCariList_dummy: any[];
  sbYayimciList: any[];
  sbYayimciList_dummy: any[];
  sbMBirligiList: any[];
  sbMBirligiList_dummy: any[];
  getEserPayDagilimTelifsizKartById: any[];
  getEserPayDagilimTelifsizKartById_dummy: any[];
  isComp131824Visible: "visible" | "hidden";
  isComp535955Visible: "visible" | "hidden";
  isComp448302Visible: "visible" | "hidden";
  isComp258332Visible: "visible" | "hidden";
}

export class UrunPayDagilimKartelifsiz_ScreenBase extends React.PureComponent<
  IUrunPayDagilimKartelifsiz_ScreenProps,
  any
> {
  urunpaydagilimkartelifsiz_131824_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunpaydagilimkartelifsiz_602641_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunpaydagilimkartelifsiz_327310_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "db45686f-5262-47ef-a82e-36952d9c62cf",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 604367, PropertyName: "label", Value: "Kaydet" },
        { Id: 400481, PropertyName: "value", Value: "Eser Sahibi" },
        { Id: 131824, PropertyName: "placeholder", Value: "..." },
        { Id: 993411, PropertyName: "value", Value: "Görevi" },
        { Id: 980508, PropertyName: "placeholder", Value: "..." },
        { Id: 11299, PropertyName: "value", Value: "Eser Payı" },
        { Id: 807853, PropertyName: "value", Value: "Yayımcı" },
        { Id: 602641, PropertyName: "placeholder", Value: "..." },
        { Id: 91291, PropertyName: "value", Value: "M.Birliği" },
        { Id: 327310, PropertyName: "placeholder", Value: "..." },
        { Id: 979337, PropertyName: "value", Value: "MUZ (Mekanik Payı)" },
        { Id: 206244, PropertyName: "value", Value: "MUZ (Temsili Payı)" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.urunpaydagilimkartelifsiz_131824_value_kuikaSelectBoxRef = React.createRef();
    this.urunpaydagilimkartelifsiz_602641_value_kuikaSelectBoxRef = React.createRef();
    this.urunpaydagilimkartelifsiz_327310_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setEserPayDagilimTelifsizSave: 0,
      GoBack: "",
      sbEserSahibiList: [],
      sbCariList: [],
      sbYayimciList: [],
      sbMBirligiList: [],
      getEserPayDagilimTelifsizKartById: [],
      isComp131824Visible: "hidden",
      isComp535955Visible: "hidden",
      isComp448302Visible: "hidden",
      isComp258332Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("urunpaydagilimkartelifsiz", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UrunPayDagilimKartelifsizPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("urunpaydagilimkartelifsiz", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("urunpaydagilimkartelifsiz", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UrunPayDagilimKartelifsizPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      urunpaydagilimkartelifsiz_509834_value:
        this.state.getEserPayDagilimTelifsizKartById?.at?.(0)?.dagitimYapilacakKisi ?? undefined,
      urunpaydagilimkartelifsiz_980508_value:
        this.state.getEserPayDagilimTelifsizKartById?.at?.(0)?.gorevi ?? undefined,
      urunpaydagilimkartelifsiz_981175_value:
        this.state.getEserPayDagilimTelifsizKartById?.at?.(0)?.hakedisOran ?? undefined,
      urunpaydagilimkartelifsiz_602641_value:
        this.state.getEserPayDagilimTelifsizKartById?.at?.(0)?.yayimciID ?? undefined,
      urunpaydagilimkartelifsiz_730302_value: undefined,
      urunpaydagilimkartelifsiz_422522_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  UrunPayDagilimKartelifsizPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UrunPayDagilimKartelifsiz/UrunPayDagilimKartelifsizPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbEserSahibiList = result?.data.sbEserSahibiList;

    formVars.urunpaydagilimkartelifsiz_131824_options = stateVars.sbEserSahibiList;
    stateVars.sbCariList = result?.data.sbCariList;
    stateVars.sbYayimciList = result?.data.sbYayimciList;

    formVars.urunpaydagilimkartelifsiz_602641_value =
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].yayimciID
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].yayimciID
        : null;
    formVars.urunpaydagilimkartelifsiz_602641_options = stateVars.sbYayimciList;
    stateVars.sbMBirligiList = result?.data.sbMBirligiList;

    formVars.urunpaydagilimkartelifsiz_327310_options = stateVars.sbMBirligiList;
    stateVars.getEserPayDagilimTelifsizKartById = result?.data.getEserPayDagilimTelifsizKartById;
    formVars.urunpaydagilimkartelifsiz_509834_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].dagitimYapilacakKisi
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].dagitimYapilacakKisi
        : null
    );
    formVars.urunpaydagilimkartelifsiz_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].gorevi
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].gorevi
        : null
    );
    formVars.urunpaydagilimkartelifsiz_981175_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].hakedisOran
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].hakedisOran
        : null
    );
    formVars.urunpaydagilimkartelifsiz_602641_value =
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].yayimciID
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].yayimciID
        : null;
    formVars.urunpaydagilimkartelifsiz_602641_options = stateVars.sbYayimciList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunPayDagilimKartelifsizPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunPayDagilimKartelifsizPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.urunpaydagilimkartelifsiz_509834_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].dagitimYapilacakKisi
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].dagitimYapilacakKisi
        : null
    );

    stateVars.dataSource_131824 = this.state.sbEserSahibiList;
    stateVars.dataSource_131824 = this.state.sbEserSahibiList;
    formVars.urunpaydagilimkartelifsiz_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].gorevi
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].gorevi
        : null
    );

    formVars.urunpaydagilimkartelifsiz_981175_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].hakedisOran
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].hakedisOran
        : null
    );

    formVars.urunpaydagilimkartelifsiz_602641_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimTelifsizKartById?.length > 0
        ? stateVars.getEserPayDagilimTelifsizKartById[0].yayimciID
        : this.state.getEserPayDagilimTelifsizKartById?.length > 0
        ? this.state.getEserPayDagilimTelifsizKartById[0].yayimciID
        : null
    );

    stateVars.dataSource_602641 = this.state.sbYayimciList;
    stateVars.dataSource_602641 = this.state.sbYayimciList;

    stateVars.dataSource_327310 = this.state.sbMBirligiList;
    stateVars.dataSource_327310 = this.state.sbMBirligiList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UrunPayDagilimKartelifsizComponent_604367_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      DagitimYapilacakKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkartelifsiz_509834_value", "value", "", "", "")
        ),
        "string"
      ),
      Gorevi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkartelifsiz_980508_value", "value", "", "", "")
        ),
        "string"
      ),
      HakedisOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkartelifsiz_981175_value", "value", "", "", "")
        ),
        "number"
      ),
      StokID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmStokID ?? this.props.screenInputs.prmstokid,
        "Guid"
      ),
      YayimciID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "urunpaydagilimkartelifsiz_602641_value",
            "value",
            "sbYayimciList",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UrunPayDagilimKartelifsiz/UrunPayDagilimKartelifsizComponent_604367_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setEserPayDagilimTelifsizSave = result?.data.setEserPayDagilimTelifsizSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunPayDagilimKartelifsizComponent_604367_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunPayDagilimKartelifsizComponent_604367_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunPayDagilimKartelifsizComponent_12531_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
