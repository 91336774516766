import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBFormuTicariPrint_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBFormuTicariPrint_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  getBasvuruTuruKodById: any[];
  getBasvuruTuruKodById_dummy: any[];
  getProjeTuruFormKodById: any[];
  getProjeTuruFormKodById_dummy: any[];
  getFormNo: any[];
  getFormNo_dummy: any[];
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  sbFirmaTuruList: any[];
  sbFirmaTuruList_dummy: any[];
  sbProjeTuruList: any[];
  sbProjeTuruList_dummy: any[];
  sbMecraList: any[];
  sbMecraList_dummy: any[];
  sbIzinTuruList: any[];
  sbIzinTuruList_dummy: any[];
  sbMunhasirlikList: any[];
  sbMunhasirlikList_dummy: any[];
  getBasvuruFormuById: any[];
  getBasvuruFormuById_dummy: any[];
  getListKullanimByBasvuruFId: any[];
  getListKullanimByBasvuruFId_dummy: any[];
  SetValueOf: string;
  SetValueOf_dummy: string;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  getDokumansListByBFId: any[];
  getDokumansListByBFId_dummy: any[];
  sbBasvuruTuruList: any[];
  sbBasvuruTuruList_dummy: any[];
  sbProjeFormTuruList: any[];
  sbProjeFormTuruList_dummy: any[];
  rwDigeriseVisible01: any[];
  rwDigeriseVisible01_dummy: any[];
  ComponentToPdf: string;
  ComponentToPdf_dummy: string;
  getPdfFileName: any[];
  getPdfFileName_dummy: any[];
  DownloadFile: string;
  DownloadFile_dummy: string;
  GoBack: string;
  GoBack_dummy: string;
  isComp437424Visible: "visible" | "hidden";
  isComp312802Visible: "visible" | "hidden";
  isComp128084Visible: "visible" | "hidden";
  isComp556886Visible: "visible" | "hidden";
  isComp561077Visible: "visible" | "hidden";
  isComp280419Visible: "visible" | "hidden";
  isComp173740Visible: "visible" | "hidden";
  isComp119700Visible: "visible" | "hidden";
  isComp208142Visible: "visible" | "hidden";
  isComp455722Visible: "visible" | "hidden";
  isComp861186Visible: "visible" | "hidden";
  isComp468627Visible: "visible" | "hidden";
  isComp10355Visible: "visible" | "hidden";
  isComp139406Visible: "visible" | "hidden";
  isComp967257Visible: "visible" | "hidden";
  isComp938143Visible: "visible" | "hidden";
  isComp274058Visible: "visible" | "hidden";
  isComp256251Visible: "visible" | "hidden";
  isComp28903Visible: "visible" | "hidden";
  isComp918779Visible: "visible" | "hidden";
  isComp115665Visible: "visible" | "hidden";
  isComp3330Visible: "visible" | "hidden";
  isComp695955Visible: "visible" | "hidden";
  isComp25348Visible: "visible" | "hidden";
  isComp487276Visible: "visible" | "hidden";
  isComp981156Visible: "visible" | "hidden";
  isComp169331Visible: "visible" | "hidden";
  isComp998670Visible: "visible" | "hidden";
  isComp406152Visible: "visible" | "hidden";
  isComp712408Visible: "visible" | "hidden";
  isComp27582Visible: "visible" | "hidden";
  isComp441370Visible: "visible" | "hidden";
  isComp439592Visible: "visible" | "hidden";
  isComp595160Visible: "visible" | "hidden";
  isComp743653Visible: "visible" | "hidden";
  isComp57055Visible: "visible" | "hidden";
  isComp951915Visible: "visible" | "hidden";
  isComp220908Visible: "visible" | "hidden";
  isComp846395Visible: "visible" | "hidden";
  isComp723855Visible: "visible" | "hidden";
  isComp306128Visible: "visible" | "hidden";
  isComp755011Visible: "visible" | "hidden";
  isComp543508Visible: "visible" | "hidden";
  isComp852087Visible: "visible" | "hidden";
  isComp466555Visible: "visible" | "hidden";
  isComp947730Visible: "visible" | "hidden";
  isComp744907Visible: "visible" | "hidden";
  isComp434573Visible: "visible" | "hidden";
  isComp845643Visible: "visible" | "hidden";
  isComp535471Visible: "visible" | "hidden";
  isComp160306Visible: "visible" | "hidden";
  isComp332643Visible: "visible" | "hidden";
  isComp564896Visible: "visible" | "hidden";
  isComp383924Visible: "visible" | "hidden";
  isComp720067Visible: "visible" | "hidden";
  isComp315445Visible: "visible" | "hidden";
  isComp393979Visible: "visible" | "hidden";
  isComp998433Visible: "visible" | "hidden";
  isComp783152Visible: "visible" | "hidden";
  isComp481428Visible: "visible" | "hidden";
  isComp165470Visible: "visible" | "hidden";
  isComp389696Visible: "visible" | "hidden";
  isComp572237Visible: "visible" | "hidden";
  isComp149325Visible: "visible" | "hidden";
  isComp202654Visible: "visible" | "hidden";
  isComp307737Visible: "visible" | "hidden";
  isComp810747Visible: "visible" | "hidden";
  isComp201385Visible: "visible" | "hidden";
  isComp269167Visible: "visible" | "hidden";
  isComp977493Visible: "visible" | "hidden";
  isComp903184Visible: "visible" | "hidden";
  isComp67119Visible: "visible" | "hidden";
  isComp169770Visible: "visible" | "hidden";
}

export class BFormuTicariPrint_ScreenBase extends React.PureComponent<IBFormuTicariPrint_ScreenProps, any> {
  bformuticariprint_907796_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_96573_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_655326_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_59667_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_499516_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_42241_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_151733_value_kuikaMultiSelectRef: React.RefObject<any>;
  bformuticariprint_314315_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_180071_value_kuikaDateRef: React.RefObject<any>;
  bformuticariprint_554864_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_193622_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_139423_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_175576_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_563208_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_398647_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_49563_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_763581_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_161165_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_599586_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_636418_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_952938_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_925097_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_776748_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_423768_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_508876_value_kuikaDateRef: React.RefObject<any>;
  bformuticariprint_958009_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_114157_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_945615_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_38491_value_kuikaDateRef: React.RefObject<any>;
  bformuticariprint_475588_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_841151_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_653975_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticariprint_830230_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "461aec7a-913f-4719-be91-0da8086f68dd",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 861186, PropertyName: "value", Value: "BELGESEL İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 468627, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A DOCUMENTARY" },
        { Id: 10355, PropertyName: "value", Value: "DİZİ İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 139406, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A SERIES" },
        { Id: 967257, PropertyName: "value", Value: "DİZİ (GENEL) İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 938143, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A SERIES" },
        { Id: 274058, PropertyName: "value", Value: "FİLM İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 256251, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A FILM" },
        { Id: 28903, PropertyName: "value", Value: "KONSER VE ETKİNLİKLER  İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 918779, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR CONCERTS & EVENTS" },
        { Id: 115665, PropertyName: "value", Value: "REKLAM İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 3330, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A COMMERCIAL" },
        { Id: 695955, PropertyName: "value", Value: "TİYATRO İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 25348, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A THEATRE PRODUCTION" },
        { Id: 487276, PropertyName: "value", Value: "PROGRAM İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 981156, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A PROGRAMME" },
        { Id: 169331, PropertyName: "value", Value: "Diğer Projeler için Başvuru Formu" },
        { Id: 998670, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR OTHER PROJECTS" },
        { Id: 406152, PropertyName: "value", Value: "MÜZİK YAPIM (CD/PLAK/KASET/DIJITAL) BAŞVURU FORMU" },
        { Id: 712408, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A MUSICAL RELEASE" },
        { Id: 346979, PropertyName: "value", Value: "Başvuruyu Yapan Kişi" },
        { Id: 750474, PropertyName: "placeholder", Value: "..." },
        { Id: 213845, PropertyName: "value", Value: "İletişim Bilgileri (E-mail)" },
        { Id: 192848, PropertyName: "placeholder", Value: "..." },
        { Id: 186660, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 390390, PropertyName: "placeholder", Value: "..." },
        { Id: 543730, PropertyName: "value", Value: "Başvuruyu Yapan Firma" },
        { Id: 389012, PropertyName: "placeholder", Value: "..." },
        { Id: 556749, PropertyName: "value", Value: "Firma Türü" },
        { Id: 376687, PropertyName: "value", Value: "Type Of Company" },
        { Id: 907796, PropertyName: "placeholder", Value: "..." },
        { Id: 176074, PropertyName: "value", Value: "İş Yapılacak Firma" },
        { Id: 654168, PropertyName: "placeholder", Value: "..." },
        { Id: 669324, PropertyName: "value", Value: "Kullanılacak Eserin Adı" },
        { Id: 424359, PropertyName: "value", Value: "Name Of The Requested Work" },
        { Id: 405451, PropertyName: "placeholder", Value: "..." },
        { Id: 295487, PropertyName: "value", Value: "Eser Sahibi Adı" },
        { Id: 166941, PropertyName: "value", Value: "Name Of The Right Owner" },
        { Id: 788421, PropertyName: "placeholder", Value: "..." },
        { Id: 89366, PropertyName: "value", Value: "Belgesel 1" },
        { Id: 739201, PropertyName: "value", Value: "Proje (Belgesel) Adı" },
        { Id: 181465, PropertyName: "value", Value: "Name Of The Documentary" },
        { Id: 757838, PropertyName: "placeholder", Value: "..." },
        { Id: 143432, PropertyName: "value", Value: "Toplam Bütçesi" },
        { Id: 464344, PropertyName: "value", Value: "Total Budget" },
        { Id: 613968, PropertyName: "placeholder", Value: "..." },
        { Id: 933944, PropertyName: "value", Value: "Prodüktör" },
        { Id: 135971, PropertyName: "value", Value: "Producer" },
        { Id: 819000, PropertyName: "placeholder", Value: "..." },
        { Id: 387556, PropertyName: "value", Value: "Yönetmen" },
        { Id: 179965, PropertyName: "value", Value: "Director" },
        { Id: 441327, PropertyName: "placeholder", Value: "..." },
        { Id: 528904, PropertyName: "value", Value: "Belgeselin Konusu" },
        { Id: 617592, PropertyName: "value", Value: "Subject Of The Documentary" },
        { Id: 164797, PropertyName: "placeholder", Value: "..." },
        { Id: 986753, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 713473, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 709057, PropertyName: "placeholder", Value: "..." },
        { Id: 126444, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 322148, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 96573, PropertyName: "placeholder", Value: "..." },
        { Id: 56894, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 259234, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 904040, PropertyName: "placeholder", Value: "..." },
        { Id: 675625, PropertyName: "value", Value: "Yurtdışında Herhangi Bir Festivalde Gösterilecek mi?" },
        { Id: 720911, PropertyName: "value", Value: "Will It Be Shown At Any Festivals?" },
        { Id: 655326, PropertyName: "placeholder", Value: "..." },
        { Id: 363998, PropertyName: "placeholder", Value: "..." },
        { Id: 958777, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 810513, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 59667, PropertyName: "placeholder", Value: "..." },
        { Id: 846743, PropertyName: "placeholder", Value: "..." },
        { Id: 728545, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 159456, PropertyName: "value", Value: "..." },
        { Id: 301253, PropertyName: "placeholder", Value: "..." },
        { Id: 760794, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 947230, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 499516, PropertyName: "placeholder", Value: "..." },
        { Id: 200574, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 385899, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 806100, PropertyName: "placeholder", Value: "..." },
        { Id: 334745, PropertyName: "value", Value: "CD Kaset LP 2" },
        { Id: 457053, PropertyName: "value", Value: "Yapım (CD/PLAK/KASET/DIJITAL) Adı" },
        { Id: 56240, PropertyName: "value", Value: "Name Of The Release" },
        { Id: 727135, PropertyName: "placeholder", Value: "..." },
        { Id: 953111, PropertyName: "value", Value: "Yapımda Kullanılan Diğer Eserler ve Eser Sahipleri" },
        { Id: 646489, PropertyName: "value", Value: "..." },
        { Id: 419406, PropertyName: "placeholder", Value: "..." },
        { Id: 742073, PropertyName: "value", Value: "Yapımcı Firma" },
        { Id: 625218, PropertyName: "value", Value: "Recording Company" },
        { Id: 464905, PropertyName: "placeholder", Value: "..." },
        { Id: 33877, PropertyName: "value", Value: "Sanatçı / Grup Adı" },
        { Id: 647385, PropertyName: "value", Value: "Name Of The Artist / Group" },
        { Id: 919241, PropertyName: "placeholder", Value: "..." },
        { Id: 802533, PropertyName: "value", Value: "Sanatçı/Grup Sosyal Medya Hesaplarını Ekleyin;" },
        { Id: 297204, PropertyName: "value", Value: "Please Add The Socials Of The Artist;" },
        { Id: 16118, PropertyName: "value", Value: "Facebook" },
        { Id: 74883, PropertyName: "placeholder", Value: "facebook.com/" },
        { Id: 627888, PropertyName: "value", Value: "Instagram" },
        { Id: 136717, PropertyName: "placeholder", Value: "instagram.com/" },
        { Id: 158771, PropertyName: "value", Value: "TikTok" },
        { Id: 212231, PropertyName: "placeholder", Value: "@..." },
        { Id: 187627, PropertyName: "value", Value: "Twitter" },
        { Id: 628115, PropertyName: "placeholder", Value: "twitter.com/" },
        { Id: 151977, PropertyName: "value", Value: "YouTube" },
        { Id: 774689, PropertyName: "placeholder", Value: "youtube.com/channel/" },
        { Id: 378733, PropertyName: "value", Value: "Diğer" },
        { Id: 960125, PropertyName: "value", Value: "/ Other" },
        { Id: 176725, PropertyName: "placeholder", Value: "..." },
        { Id: 554583, PropertyName: "value", Value: "Yayınlanma Türü" },
        { Id: 93222, PropertyName: "value", Value: "Type Of Release" },
        { Id: 42241, PropertyName: "placeholder", Value: "..." },
        { Id: 921473, PropertyName: "value", Value: "Format" },
        { Id: 440226, PropertyName: "value", Value: "Release Format" },
        { Id: 151733, PropertyName: "placeholder", Value: "..." },
        { Id: 74421, PropertyName: "value", Value: "Aradığınız Format Menü’de Yok İse Detay Verin Lütfen" },
        { Id: 860210, PropertyName: "value", Value: "If The Your Format Is Missing Please Enter Below" },
        { Id: 643220, PropertyName: "placeholder", Value: "..." },
        { Id: 993383, PropertyName: "value", Value: "Planlanan Yayın Tarihi" },
        { Id: 15418, PropertyName: "value", Value: "Release Date" },
        { Id: 174173, PropertyName: "placeholder", Value: "..." },
        { Id: 399941, PropertyName: "value", Value: "Yayım Bölgesi" },
        { Id: 62671, PropertyName: "value", Value: "Territory Of Release" },
        { Id: 314315, PropertyName: "placeholder", Value: "..." },
        {
          Id: 317331,
          PropertyName: "value",
          Value: "Yapımın Üzerinde Bir Ürün / Marka Adı Olacak mı? Evet ise detayını rica ederiz"
        },
        {
          Id: 378854,
          PropertyName: "value",
          Value: "Will There Be Product Placement And/Or Sponsorship On The Release? If Yes, Please Give Detail"
        },
        { Id: 363699, PropertyName: "placeholder", Value: "..." },
        {
          Id: 53752,
          PropertyName: "value",
          Value:
            "Eserde orjinalinden farklı yapılan bir düzenlenme, sampling, melodi veya yeni söz yazımı var mı? Varsa detayını rica ederiz"
        },
        {
          Id: 227995,
          PropertyName: "value",
          Value:
            "If The Original Work Has Been Adapted, Sampled, Melody Changed And/Or New Lyrics Written Please Give Further Info"
        },
        { Id: 631318, PropertyName: "placeholder", Value: "..." },
        { Id: 227848, PropertyName: "value", Value: "Dizi 3" },
        { Id: 386576, PropertyName: "value", Value: "Proje (Dizinin) Adı" },
        { Id: 425832, PropertyName: "value", Value: "Name Of The Series" },
        { Id: 731780, PropertyName: "placeholder", Value: "..." },
        { Id: 417927, PropertyName: "value", Value: "Dizinin Yayınlandığı TV Kanalı ve/veya Platform" },
        { Id: 418119, PropertyName: "value", Value: "Name Of Channel Or Platform It’s Broadcast" },
        { Id: 530996, PropertyName: "placeholder", Value: "..." },
        { Id: 148877, PropertyName: "value", Value: "Prodüktör" },
        { Id: 554040, PropertyName: "value", Value: "Producer" },
        { Id: 35217, PropertyName: "placeholder", Value: "..." },
        { Id: 725698, PropertyName: "value", Value: "Yönetmen" },
        { Id: 445016, PropertyName: "value", Value: "Director" },
        { Id: 215099, PropertyName: "placeholder", Value: "..." },
        { Id: 203778, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 722907, PropertyName: "value", Value: "Main Cast" },
        { Id: 503111, PropertyName: "placeholder", Value: "..." },
        { Id: 319972, PropertyName: "value", Value: "Dizinin Konusu" },
        { Id: 797730, PropertyName: "value", Value: "Subject Of The Series" },
        { Id: 800510, PropertyName: "placeholder", Value: "..." },
        { Id: 151120, PropertyName: "value", Value: "Sezon" },
        { Id: 15206, PropertyName: "value", Value: "Season" },
        { Id: 125636, PropertyName: "placeholder", Value: "..." },
        { Id: 880384, PropertyName: "value", Value: "Bölüm Numarası" },
        { Id: 48258, PropertyName: "value", Value: "Episode Number" },
        { Id: 949599, PropertyName: "placeholder", Value: "..." },
        { Id: 64119, PropertyName: "value", Value: "Yayın Tarihi" },
        { Id: 877174, PropertyName: "value", Value: "Release Date" },
        { Id: 180071, PropertyName: "placeholder", Value: "..." },
        { Id: 123612, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 783474, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 496974, PropertyName: "placeholder", Value: "..." },
        { Id: 435457, PropertyName: "value", Value: "Eserin Kullanılacağı Yaklaşık Süresi" },
        { Id: 734824, PropertyName: "value", Value: "Approximate Duration Of Usage" },
        { Id: 408183, PropertyName: "placeholder", Value: "..." },
        { Id: 962391, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 506579, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 554864, PropertyName: "placeholder", Value: "..." },
        { Id: 761157, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 8328, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 711138, PropertyName: "placeholder", Value: "..." },
        { Id: 357522, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 869275, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 193622, PropertyName: "placeholder", Value: "..." },
        { Id: 296139, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 988356, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 918859, PropertyName: "value", Value: "..." },
        { Id: 54329, PropertyName: "placeholder", Value: "..." },
        { Id: 845989, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 192527, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 139423, PropertyName: "placeholder", Value: "..." },
        { Id: 782166, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 128287, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 315845, PropertyName: "placeholder", Value: "..." },
        { Id: 345052, PropertyName: "value", Value: "Dizi Genel 4" },
        { Id: 476457, PropertyName: "value", Value: "Proje (Dizinin) Adı" },
        { Id: 291649, PropertyName: "value", Value: "Name Of The Series" },
        { Id: 953799, PropertyName: "placeholder", Value: "Proje Adı Giriniz..." },
        { Id: 324637, PropertyName: "value", Value: "Dizinin Yayınlandığı TV Kanalı" },
        { Id: 298608, PropertyName: "value", Value: "Name Of Channel Or Platform It’s Broadcast" },
        { Id: 795343, PropertyName: "placeholder", Value: "Toplam Bütçe Giriniz..." },
        { Id: 327229, PropertyName: "value", Value: "Prodüktör" },
        { Id: 757771, PropertyName: "value", Value: "Producer" },
        { Id: 894235, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 973067, PropertyName: "value", Value: "Yönetmen" },
        { Id: 546087, PropertyName: "value", Value: "Director" },
        { Id: 752355, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 223091, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 392461, PropertyName: "value", Value: "Main Cast" },
        { Id: 37671, PropertyName: "placeholder", Value: "Toplam Bütçe Giriniz..." },
        { Id: 341326, PropertyName: "value", Value: "Kaç Bölüm İçin İsteniyor" },
        { Id: 887514, PropertyName: "value", Value: "..." },
        { Id: 183550, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 284585, PropertyName: "value", Value: "Sinopsis" },
        { Id: 780979, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 500664, PropertyName: "value", Value: "Eser Nasıl Kullanılacak" },
        { Id: 729249, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 175576, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 81696, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 932763, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 563208, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 512575, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 325672, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 861385, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 883904, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor ?" },
        { Id: 942289, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 482186, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 556956, PropertyName: "value", Value: "Eserin Nasıl Kullanılacağı" },
        { Id: 311922, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 398647, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 912114, PropertyName: "value", Value: "Sinema 5" },
        { Id: 896912, PropertyName: "value", Value: "Proje (Filmin) Adı" },
        { Id: 334888, PropertyName: "value", Value: "Name Of The Film" },
        { Id: 433250, PropertyName: "placeholder", Value: "..." },
        { Id: 841530, PropertyName: "value", Value: "Toplam Bütçesi" },
        { Id: 5627, PropertyName: "value", Value: "Total Budget" },
        { Id: 401545, PropertyName: "placeholder", Value: "..." },
        { Id: 348454, PropertyName: "value", Value: "Prodüktör" },
        { Id: 415291, PropertyName: "value", Value: "Producer" },
        { Id: 548831, PropertyName: "placeholder", Value: "..." },
        { Id: 487282, PropertyName: "value", Value: "Yönetmen" },
        { Id: 514296, PropertyName: "value", Value: "Director" },
        { Id: 241884, PropertyName: "placeholder", Value: "..." },
        { Id: 503556, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 168384, PropertyName: "value", Value: "Main Cast" },
        { Id: 154279, PropertyName: "placeholder", Value: "..." },
        { Id: 904626, PropertyName: "value", Value: "Sinopsis" },
        { Id: 306823, PropertyName: "placeholder", Value: "..." },
        { Id: 504443, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 616327, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 821567, PropertyName: "placeholder", Value: "..." },
        { Id: 199034, PropertyName: "value", Value: "Eserin Kullanılacağı Yaklaşık Süresi" },
        { Id: 56838, PropertyName: "value", Value: "Approximate Duration Of Usage Of The Work" },
        { Id: 135744, PropertyName: "placeholder", Value: "..." },
        { Id: 585806, PropertyName: "value", Value: "Eser; Film Tanıtım ve Fragmanlarında Geçecek mi?" },
        { Id: 284844, PropertyName: "value", Value: "Will The Work Be Featured In Teasers And/Or Trailers?" },
        { Id: 109874, PropertyName: "placeholder", Value: "..." },
        { Id: 896508, PropertyName: "value", Value: "Filmin öncelikli olarak yayınlanacağı mecra?" },
        { Id: 525934, PropertyName: "value", Value: "Where Will The Film Premiere?" },
        { Id: 43394, PropertyName: "placeholder", Value: "..." },
        { Id: 533678, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 960863, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 49563, PropertyName: "placeholder", Value: "..." },
        { Id: 366291, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 746191, PropertyName: "value", Value: "In No, Please State Other Territories And Countries" },
        { Id: 437312, PropertyName: "placeholder", Value: "..." },
        { Id: 465402, PropertyName: "value", Value: "Yurtdışında Herhangi Bir Festivalde Gösterilecek mi?" },
        { Id: 303894, PropertyName: "value", Value: "Will It Be Shown At Any Festivals?" },
        { Id: 763581, PropertyName: "placeholder", Value: "..." },
        { Id: 478463, PropertyName: "placeholder", Value: "..." },
        { Id: 618413, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 660459, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 161165, PropertyName: "placeholder", Value: "..." },
        { Id: 597208, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 717525, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 981943, PropertyName: "value", Value: "..." },
        { Id: 108219, PropertyName: "placeholder", Value: "..." },
        { Id: 533641, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 598093, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 599586, PropertyName: "placeholder", Value: "..." },
        { Id: 61789, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 129258, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 179006, PropertyName: "placeholder", Value: "..." },
        { Id: 516000, PropertyName: "value", Value: "Konser 6" },
        { Id: 571477, PropertyName: "value", Value: "Proje (Konser/Etkinlik) Adı" },
        { Id: 346100, PropertyName: "value", Value: "Name Of The Project (Concert/Event)" },
        { Id: 779822, PropertyName: "placeholder", Value: "..." },
        { Id: 504417, PropertyName: "value", Value: "Sahne Alacak Sanatçı/Sanatçılar" },
        { Id: 39030, PropertyName: "value", Value: "Names of the Act/Acts" },
        { Id: 139034, PropertyName: "placeholder", Value: "..." },
        { Id: 103880, PropertyName: "value", Value: "Yapımcı" },
        { Id: 91568, PropertyName: "value", Value: "Producer" },
        { Id: 213030, PropertyName: "placeholder", Value: "..." },
        { Id: 852214, PropertyName: "value", Value: "Sponsor Var mı? / Kimler?" },
        { Id: 910891, PropertyName: "value", Value: "Is There A Sponser?" },
        { Id: 895754, PropertyName: "placeholder", Value: "..." },
        {
          Id: 817656,
          PropertyName: "value",
          Value: "Organizasyon Yeri ve Tarihi (Çoklu Bir Gösterim İse Her Bir Etkinlik Günü ve Tarihini Yazınız)"
        },
        {
          Id: 907140,
          PropertyName: "value",
          Value: "Event Location And Date (For Multiple Shows, State the Date & Location Of Each)"
        },
        { Id: 569899, PropertyName: "placeholder", Value: "..." },
        { Id: 72135, PropertyName: "value", Value: "Etkinlik Kişi Kapasitesi" },
        { Id: 221165, PropertyName: "value", Value: "Capacity Of The Event" },
        { Id: 179729, PropertyName: "placeholder", Value: "..." },
        { Id: 497710, PropertyName: "value", Value: "Bilet Fiyatları" },
        { Id: 306774, PropertyName: "value", Value: "Ticket Prices" },
        { Id: 936236, PropertyName: "placeholder", Value: "..." },
        {
          Id: 882188,
          PropertyName: "value",
          Value:
            "Konser Tanıtım ve Afişi Hakkında Bilgi (İzin Alınan Eser Yada Sanatçıya Tanıtım ve Afişte Yer Verilecek mi?)"
        },
        {
          Id: 988857,
          PropertyName: "value",
          Value: "Info About Marketing And Poster (Will The Work or Artist/Composer Be Featured?)"
        },
        { Id: 46982, PropertyName: "placeholder", Value: "..." },
        {
          Id: 37454,
          PropertyName: "value",
          Value: "Konser Profesyonel Kayıt Altına Alınacak mı? (Ses Ya da Görüntülü Olarak)"
        },
        {
          Id: 684377,
          PropertyName: "value",
          Value: "Will The Concert Be Professionally Recorded (Sound and/or Video)"
        },
        { Id: 925632, PropertyName: "placeholder", Value: "..." },
        {
          Id: 725703,
          PropertyName: "value",
          Value:
            "Konser Görüntüleri ya da  Tamamı Daha Sonra Herhangi bir mecra da Yayınlanacak mı? (Lütfen Detay Giriniz - Hangi Kanal, Sosyal Medya vb.)"
        },
        {
          Id: 679747,
          PropertyName: "value",
          Value:
            "Will The Event In Part Or In Full Be Broadcast Via Any Media? (Please Give Details i.e: Channel, Social Media etc)"
        },
        { Id: 753776, PropertyName: "placeholder", Value: "..." },
        { Id: 917119, PropertyName: "value", Value: "Kayıtlar CD/DVD Gibi Bir Yapım İçin de Kullanılacak mı?" },
        { Id: 175915, PropertyName: "value", Value: "Will The Recordings/Footage Be Made Into A CD/DVD?" },
        { Id: 118061, PropertyName: "placeholder", Value: "..." },
        { Id: 576150, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 861564, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 447965, PropertyName: "placeholder", Value: "..." },
        { Id: 445544, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 473701, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 636418, PropertyName: "placeholder", Value: "..." },
        { Id: 136186, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 403672, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 606315, PropertyName: "placeholder", Value: "..." },
        {
          Id: 347217,
          PropertyName: "value",
          Value: "Hologram Benzeri Bir Kullanım, Sunum Ya da Görsel Bir Malzeme Kullanımı Var mı?"
        },
        { Id: 600466, PropertyName: "value", Value: "Will There Be Hologram Use / Presentation or Images Used?" },
        { Id: 243523, PropertyName: "placeholder", Value: "..." },
        {
          Id: 193478,
          PropertyName: "value",
          Value: "Konserden Bir Gelir Elde Ediliyor mu? (Direk Ya da Sponsor Destek Yolu İle Dolaylı)"
        },
        { Id: 76537, PropertyName: "value", Value: "Will Revenue Be Generated (Direct Or Through Sponsership)" },
        { Id: 252338, PropertyName: "placeholder", Value: "..." },
        {
          Id: 107141,
          PropertyName: "value",
          Value: "Elde Edilen Gelirin Paylaşanları Kimdir? Bağış vs. Söz Konusu İse Lütfen Belirtiniz."
        },
        {
          Id: 732757,
          PropertyName: "value",
          Value: "If Yes, Who Will Be Keeping/Sharing The Revenue? If Donating Please State."
        },
        { Id: 63361, PropertyName: "placeholder", Value: "..." },
        { Id: 594473, PropertyName: "value", Value: "Reklam 7" },
        { Id: 922693, PropertyName: "value", Value: "Proje (Reklam) Adı" },
        { Id: 361357, PropertyName: "value", Value: "Name Of The Project (Commercial)" },
        { Id: 160425, PropertyName: "placeholder", Value: "..." },
        { Id: 625517, PropertyName: "value", Value: "Ürün" },
        { Id: 910071, PropertyName: "value", Value: "Product" },
        { Id: 634083, PropertyName: "placeholder", Value: "..." },
        { Id: 231733, PropertyName: "value", Value: "Parça" },
        { Id: 579871, PropertyName: "value", Value: "..." },
        { Id: 375623, PropertyName: "placeholder", Value: "..." },
        { Id: 93632, PropertyName: "value", Value: "Besteci ve Söz Yazarı" },
        { Id: 972922, PropertyName: "value", Value: "..." },
        { Id: 84279, PropertyName: "placeholder", Value: "..." },
        { Id: 331858, PropertyName: "value", Value: "Talep Edilen İzin" },
        { Id: 711631, PropertyName: "value", Value: "..." },
        { Id: 952938, PropertyName: "placeholder", Value: "..." },
        { Id: 663220, PropertyName: "value", Value: "Mecra" },
        { Id: 524095, PropertyName: "value", Value: "Media" },
        { Id: 330499, PropertyName: "placeholder", Value: "..." },
        { Id: 890990, PropertyName: "value", Value: "Spot / Timing" },
        { Id: 828213, PropertyName: "value", Value: "..." },
        { Id: 575302, PropertyName: "placeholder", Value: "..." },
        { Id: 314967, PropertyName: "value", Value: "Film Sayısı" },
        { Id: 943966, PropertyName: "value", Value: "Number Of Main Commercial Films" },
        { Id: 275256, PropertyName: "placeholder", Value: "..." },
        { Id: 378150, PropertyName: "value", Value: "Versiyon Sayısı" },
        { Id: 659334, PropertyName: "value", Value: "Versiyon Sayısı" },
        { Id: 33436, PropertyName: "placeholder", Value: "..." },
        { Id: 615338, PropertyName: "value", Value: "Gösterim Yapılacak Bölge/Ülkeleri Belirtiniz" },
        { Id: 587303, PropertyName: "value", Value: "State The Territories/Countries It Will Be Broadcast" },
        { Id: 601579, PropertyName: "placeholder", Value: "..." },
        { Id: 186097, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 496812, PropertyName: "value", Value: "Not: Reklam başvurularında süresiz izin verilmemektedir" },
        { Id: 415839, PropertyName: "value", Value: "Requested Term Of Licence" },
        {
          Id: 115178,
          PropertyName: "value",
          Value: "Please Note: Perpetuity licenses are not granted for commercials"
        },
        { Id: 925097, PropertyName: "placeholder", Value: "..." },
        { Id: 713576, PropertyName: "placeholder", Value: "Diğer/Other..." },
        {
          Id: 938367,
          PropertyName: "value",
          Value: "Sosyal Medya veya Websitesinden Arşiv Amaçlı İzin İsteniliyor Mu?"
        },
        { Id: 703006, PropertyName: "value", Value: "Not: Bu talepler verilen lisans bütçesini etkilemektedir" },
        {
          Id: 304379,
          PropertyName: "value",
          Value: "Is There A Request To Archive The Ad On Social Media And/Or A Website?"
        },
        { Id: 236248, PropertyName: "value", Value: "Please Note: These requests affect the license fee" },
        { Id: 776748, PropertyName: "placeholder", Value: "..." },
        { Id: 687776, PropertyName: "value", Value: "Ne Kadar Süreyle İsteniyor ?" },
        { Id: 17666, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 883613, PropertyName: "placeholder", Value: "..." },
        { Id: 488501, PropertyName: "value", Value: "Script /Storyboard/Scenario" },
        { Id: 723115, PropertyName: "value", Value: "..." },
        { Id: 69287, PropertyName: "placeholder", Value: "..." },
        { Id: 572426, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 662780, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 423768, PropertyName: "placeholder", Value: "..." },
        { Id: 473920, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 773399, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 680656, PropertyName: "placeholder", Value: "..." },
        {
          Id: 298287,
          PropertyName: "value",
          Value: "Söz Değişimi Varsa; Sözleri Yazınız veya Aşağıdaki  Belgeler Yükleme Bölümünden Yükleyin"
        },
        {
          Id: 490903,
          PropertyName: "value",
          Value:
            "If There Are Lyric Changes; Please Write Them In The Box Or Upload Them Via The File Upload Area Below."
        },
        { Id: 826306, PropertyName: "placeholder", Value: "..." },
        { Id: 264901, PropertyName: "value", Value: "Yayın Tarihi" },
        { Id: 645397, PropertyName: "value", Value: "Release Date" },
        { Id: 508876, PropertyName: "placeholder", Value: "..." },
        { Id: 524042, PropertyName: "value", Value: "Münhasırlık İsteniliyor mu?" },
        { Id: 70753, PropertyName: "value", Value: "Is Exclusivity Requested?" },
        { Id: 958009, PropertyName: "placeholder", Value: "..." },
        { Id: 429605, PropertyName: "value", Value: "Tiyatro Oyunu 8" },
        { Id: 156908, PropertyName: "value", Value: "Proje (Oyunun) Adı" },
        { Id: 653901, PropertyName: "value", Value: "Name Of The Theatre Production" },
        { Id: 41842, PropertyName: "placeholder", Value: "..." },
        { Id: 550195, PropertyName: "value", Value: "Toplam Bütçesi" },
        { Id: 646392, PropertyName: "value", Value: "Total Budget" },
        { Id: 769963, PropertyName: "placeholder", Value: "..." },
        { Id: 828086, PropertyName: "value", Value: "Prodüktör" },
        { Id: 147974, PropertyName: "value", Value: "Producer" },
        { Id: 745722, PropertyName: "placeholder", Value: "..." },
        { Id: 228415, PropertyName: "value", Value: "Yönetmen" },
        { Id: 180982, PropertyName: "value", Value: "Director" },
        { Id: 678596, PropertyName: "placeholder", Value: "..." },
        { Id: 528445, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 592537, PropertyName: "value", Value: "Main Cast" },
        { Id: 13402, PropertyName: "placeholder", Value: "..." },
        { Id: 743001, PropertyName: "value", Value: "Sinopsis" },
        { Id: 298537, PropertyName: "value", Value: "Synopsis" },
        { Id: 98625, PropertyName: "placeholder", Value: "..." },
        { Id: 294851, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 171599, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 977010, PropertyName: "placeholder", Value: "..." },
        { Id: 961458, PropertyName: "value", Value: "Nerede Sahneleniyor" },
        { Id: 91412, PropertyName: "value", Value: "Which Theatre Will It Be Shown At" },
        { Id: 939868, PropertyName: "placeholder", Value: "..." },
        { Id: 446724, PropertyName: "value", Value: "Koltuk Kapasitesi" },
        { Id: 23593, PropertyName: "value", Value: "Seat Capacity" },
        { Id: 605461, PropertyName: "placeholder", Value: "..." },
        { Id: 212652, PropertyName: "value", Value: "Oynanma Sıklığı" },
        { Id: 94689, PropertyName: "value", Value: "Performance Schedule" },
        { Id: 348650, PropertyName: "placeholder", Value: "..." },
        { Id: 306213, PropertyName: "value", Value: "Bilet Fiyatları" },
        { Id: 52685, PropertyName: "value", Value: "Ticket Prices" },
        { Id: 785985, PropertyName: "placeholder", Value: "..." },
        { Id: 865241, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 691289, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 114157, PropertyName: "placeholder", Value: "..." },
        { Id: 961538, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 127567, PropertyName: "value", Value: "Oyunun Sponsoru Var mı?" },
        { Id: 938530, PropertyName: "value", Value: "Does The Production Have A Sponsor?" },
        { Id: 531317, PropertyName: "placeholder", Value: "..." },
        { Id: 335364, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 126531, PropertyName: "value", Value: "..." },
        { Id: 595383, PropertyName: "placeholder", Value: "..." },
        { Id: 249799, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 505005, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 945615, PropertyName: "placeholder", Value: "..." },
        { Id: 822386, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 668749, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 997705, PropertyName: "placeholder", Value: "..." },
        { Id: 485494, PropertyName: "value", Value: "Eser; Tiyatronun Tanıtım ve Fragmanlarında Geçecek mi?" },
        { Id: 7316, PropertyName: "value", Value: "Will The Work Be Featured In Teasers And/Or Trailers?" },
        { Id: 834746, PropertyName: "placeholder", Value: "..." },
        { Id: 307262, PropertyName: "value", Value: "TV Program 9" },
        { Id: 888270, PropertyName: "value", Value: "Proje (Program) Adı" },
        { Id: 671637, PropertyName: "value", Value: "Name Of The Programme" },
        { Id: 970226, PropertyName: "placeholder", Value: "..." },
        { Id: 12307, PropertyName: "value", Value: "Programın Yayınlandığı TV Kanalı ve/veya Platform" },
        { Id: 392486, PropertyName: "value", Value: "Name Of Channel Or Platform It’s Broadcast" },
        { Id: 284840, PropertyName: "placeholder", Value: "..." },
        { Id: 679905, PropertyName: "value", Value: "Prodüktör" },
        { Id: 109213, PropertyName: "value", Value: "Producer" },
        { Id: 584964, PropertyName: "placeholder", Value: "..." },
        { Id: 359392, PropertyName: "value", Value: "Yönetmen" },
        { Id: 611955, PropertyName: "value", Value: "Director" },
        { Id: 151522, PropertyName: "placeholder", Value: "..." },
        { Id: 782842, PropertyName: "value", Value: "Programın İçeriği" },
        { Id: 928045, PropertyName: "value", Value: "Subject Of The Programme" },
        { Id: 818678, PropertyName: "placeholder", Value: "..." },
        { Id: 390909, PropertyName: "value", Value: "Sezon" },
        { Id: 209584, PropertyName: "value", Value: "Season" },
        { Id: 300311, PropertyName: "placeholder", Value: "..." },
        { Id: 439235, PropertyName: "value", Value: "Bölüm Numarası" },
        { Id: 40130, PropertyName: "value", Value: "Episode Number" },
        { Id: 596953, PropertyName: "placeholder", Value: "..." },
        { Id: 435909, PropertyName: "value", Value: "Yayın Tarihi" },
        { Id: 517660, PropertyName: "value", Value: "Release Date" },
        { Id: 38491, PropertyName: "placeholder", Value: "..." },
        { Id: 346715, PropertyName: "value", Value: "Programın Yayınlanma Sıklığı" },
        { Id: 206320, PropertyName: "value", Value: "The Programmes Broadcast Schedule" },
        { Id: 701444, PropertyName: "placeholder", Value: "..." },
        { Id: 975995, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 403387, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 475588, PropertyName: "placeholder", Value: "..." },
        { Id: 725834, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 146790, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 382382, PropertyName: "placeholder", Value: "..." },
        { Id: 921985, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 897299, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 841151, PropertyName: "placeholder", Value: "..." },
        { Id: 691237, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 735192, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 137547, PropertyName: "value", Value: "..." },
        { Id: 232600, PropertyName: "placeholder", Value: "..." },
        { Id: 206723, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 595972, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 653975, PropertyName: "placeholder", Value: "..." },
        { Id: 614126, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 40989, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 607210, PropertyName: "placeholder", Value: "..." },
        { Id: 281356, PropertyName: "value", Value: "Diğer 10" },
        { Id: 562480, PropertyName: "value", Value: "Proje Adı" },
        { Id: 61977, PropertyName: "value", Value: "Name Of Your Project" },
        { Id: 709919, PropertyName: "placeholder", Value: "..." },
        { Id: 242048, PropertyName: "value", Value: "Projenin Türü" },
        { Id: 638593, PropertyName: "value", Value: "Type Of Your Project" },
        { Id: 830230, PropertyName: "placeholder", Value: "..." },
        { Id: 490197, PropertyName: "value", Value: "Projenizi Anlatın" },
        { Id: 763387, PropertyName: "value", Value: "Tell Us About Your Project" },
        { Id: 169770, PropertyName: "placeholder", Value: "..." },
        { Id: 211926, PropertyName: "placeholder", Value: "..." },
        { Id: 489554, PropertyName: "value", Value: "Ek Bilgi / Soru" },
        { Id: 280014, PropertyName: "value", Value: "Additional Info / Questions" },
        { Id: 409878, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.bformuticariprint_907796_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_96573_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_655326_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_59667_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_499516_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_42241_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_151733_value_kuikaMultiSelectRef = React.createRef();
    this.bformuticariprint_314315_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_180071_value_kuikaDateRef = React.createRef();
    this.bformuticariprint_554864_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_193622_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_139423_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_175576_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_563208_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_398647_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_49563_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_763581_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_161165_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_599586_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_636418_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_952938_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_925097_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_776748_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_423768_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_508876_value_kuikaDateRef = React.createRef();
    this.bformuticariprint_958009_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_114157_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_945615_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_38491_value_kuikaDateRef = React.createRef();
    this.bformuticariprint_475588_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_841151_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_653975_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticariprint_830230_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      getBasvuruTuruKodById: [],
      getProjeTuruFormKodById: [],
      getFormNo: [],
      getUserInfo: [],
      sbFirmaTuruList: [],
      sbProjeTuruList: [],
      sbMecraList: [],
      sbIzinTuruList: [],
      sbMunhasirlikList: [],
      getBasvuruFormuById: [],
      getListKullanimByBasvuruFId: [],
      SetValueOf: "",
      ChangeVisibilityOf: "",
      getDokumansListByBFId: [],
      sbBasvuruTuruList: [],
      sbProjeFormTuruList: [],
      rwDigeriseVisible01: [],
      ComponentToPdf: "",
      getPdfFileName: [],
      DownloadFile: "",
      GoBack: "",
      dataSource_96573: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_655326: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_59667: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_499516: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_42241: [
        { key: "1", text: "Albüm" },
        { key: "2", text: "Single" },
        { key: "3", text: "EP" },
        { key: "4", text: "Derleme (Compilation) Albüm" },
        { key: "5", text: "Soundtrack" }
      ],
      dataSource_151733: [
        { key: "1", text: "CD" },
        { key: "2", text: "Plak" },
        { key: "3", text: "Kaset" },
        { key: "4", text: "Dijital" }
      ],
      dataSource_314315: [
        { key: "1", text: "Türkiye / Turkey" },
        { key: "2", text: "Avrupa / Europe " },
        { key: "3", text: "Dünya / World" }
      ],
      dataSource_554864: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_193622: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_139423: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_175576: [
        { key: "1", text: "Jenerik" },
        { key: "2", text: "Muhtelif bölüm için sahneler" },
        { key: "3", text: "Tanıtım" },
        { key: "4", text: "Dizinin Adı" },
        { key: "5", text: "Hepsi" }
      ],
      dataSource_563208: [
        { key: "1", text: "Evet" },
        { key: "0", text: "Hayır" }
      ],
      dataSource_398647: [
        { key: "1", text: "Orjinal Kayıttan" },
        { key: "2", text: "Yeniden Kayıttan" }
      ],
      dataSource_49563: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_763581: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_161165: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_599586: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_636418: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_925097: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_776748: [
        { key: "0", text: "Hayır / No" },
        { key: "1", text: "Evet / Yes" }
      ],
      dataSource_423768: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_114157: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_945615: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_475588: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_841151: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_653975: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan/ Re-Recording" },
        { key: "3", text: "Canlı Okunacak/ Performed Live" }
      ],
      isComp437424Visible: "hidden",
      isComp312802Visible: "hidden",
      isComp128084Visible: "hidden",
      isComp556886Visible: "hidden",
      isComp561077Visible: "hidden",
      isComp280419Visible: "hidden",
      isComp173740Visible: "hidden",
      isComp119700Visible: "hidden",
      isComp208142Visible: "hidden",
      isComp455722Visible: "hidden",
      isComp861186Visible: "hidden",
      isComp468627Visible: "hidden",
      isComp10355Visible: "hidden",
      isComp139406Visible: "hidden",
      isComp967257Visible: "hidden",
      isComp938143Visible: "hidden",
      isComp274058Visible: "hidden",
      isComp256251Visible: "hidden",
      isComp28903Visible: "hidden",
      isComp918779Visible: "hidden",
      isComp115665Visible: "hidden",
      isComp3330Visible: "hidden",
      isComp695955Visible: "hidden",
      isComp25348Visible: "hidden",
      isComp487276Visible: "hidden",
      isComp981156Visible: "hidden",
      isComp169331Visible: "hidden",
      isComp998670Visible: "hidden",
      isComp406152Visible: "hidden",
      isComp712408Visible: "hidden",
      isComp27582Visible: "hidden",
      isComp441370Visible: "hidden",
      isComp439592Visible: "hidden",
      isComp595160Visible: "hidden",
      isComp743653Visible: "hidden",
      isComp57055Visible: "hidden",
      isComp951915Visible: "hidden",
      isComp220908Visible: "hidden",
      isComp846395Visible: "hidden",
      isComp723855Visible: "hidden",
      isComp306128Visible: "hidden",
      isComp755011Visible: "hidden",
      isComp543508Visible: "hidden",
      isComp852087Visible: "hidden",
      isComp466555Visible: "hidden",
      isComp947730Visible: "hidden",
      isComp744907Visible: "hidden",
      isComp434573Visible: "hidden",
      isComp845643Visible: "hidden",
      isComp535471Visible: "hidden",
      isComp160306Visible: "hidden",
      isComp332643Visible: "hidden",
      isComp564896Visible: "hidden",
      isComp383924Visible: "hidden",
      isComp720067Visible: "hidden",
      isComp315445Visible: "hidden",
      isComp393979Visible: "hidden",
      isComp998433Visible: "hidden",
      isComp783152Visible: "hidden",
      isComp481428Visible: "hidden",
      isComp165470Visible: "hidden",
      isComp389696Visible: "hidden",
      isComp572237Visible: "hidden",
      isComp149325Visible: "hidden",
      isComp202654Visible: "hidden",
      isComp307737Visible: "hidden",
      isComp810747Visible: "hidden",
      isComp201385Visible: "hidden",
      isComp269167Visible: "hidden",
      isComp977493Visible: "hidden",
      isComp903184Visible: "hidden",
      isComp67119Visible: "hidden",
      isComp169770Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bformuticariprint", "");
      return;
    }

    if (true) {
      await this.BFormuTicariPrintPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("bformuticariprint", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bformuticariprint", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BFormuTicariPrintPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      bformuticariprint_389012_value: this.state.getBasvuruFormuById?.at?.(0)?.basvuranSirketAd ?? undefined,
      bformuticariprint_907796_value: this.state.getBasvuruFormuById?.at?.(0)?.firmaTuruID ?? undefined,
      bformuticariprint_654168_value: this.state.getBasvuruFormuById?.at?.(0)?.sirketAd ?? undefined,
      bformuticariprint_405451_value: this.state.getBasvuruFormuById?.at?.(0)?.eserAd ?? undefined,
      bformuticariprint_788421_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSahibi ?? undefined,
      bformuticariprint_757838_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_613968_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
      bformuticariprint_819000_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticariprint_441327_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticariprint_164797_value: this.state.getBasvuruFormuById?.at?.(0)?.belgeselinKonusu ?? undefined,
      bformuticariprint_709057_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticariprint_96573_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticariprint_904040_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticariprint_655326_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalmi ?? undefined,
      bformuticariprint_363998_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalAciklama ?? undefined,
      bformuticariprint_59667_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticariprint_846743_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticariprint_301253_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticariprint_499516_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_806100_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticariprint_727135_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_419406_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticariprint_464905_value: this.state.getBasvuruFormuById?.at?.(0)?.yapimci ?? undefined,
      bformuticariprint_919241_value: this.state.getBasvuruFormuById?.at?.(0)?.sanatcilar ?? undefined,
      bformuticariprint_74883_value: this.state.getBasvuruFormuById?.at?.(0)?.facebook ?? undefined,
      bformuticariprint_136717_value: this.state.getBasvuruFormuById?.at?.(0)?.instagram ?? undefined,
      bformuticariprint_212231_value: this.state.getBasvuruFormuById?.at?.(0)?.tikTok ?? undefined,
      bformuticariprint_628115_value: this.state.getBasvuruFormuById?.at?.(0)?.twitter ?? undefined,
      bformuticariprint_774689_value: this.state.getBasvuruFormuById?.at?.(0)?.youTube ?? undefined,
      bformuticariprint_176725_value: this.state.getBasvuruFormuById?.at?.(0)?.digerSosyalMedya ?? undefined,
      bformuticariprint_42241_value: this.state.getBasvuruFormuById?.at?.(0)?.kullanimSekliCD ?? undefined,
      bformuticariprint_151733_value: this.state.getBasvuruFormuById?.at?.(0)?.formats ?? undefined,
      bformuticariprint_643220_value: this.state.getBasvuruFormuById?.at?.(0)?.formatAciklama ?? undefined,
      bformuticariprint_174173_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
      bformuticariprint_314315_value: this.state.getBasvuruFormuById?.at?.(0)?.yayimBolgesi ?? undefined,
      bformuticariprint_363699_value: this.state.getBasvuruFormuById?.at?.(0)?.markaUrunVarsa ?? undefined,
      bformuticariprint_631318_value: this.state.getBasvuruFormuById?.at?.(0)?.sozDegisimi ?? undefined,
      bformuticariprint_731780_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_530996_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
      bformuticariprint_35217_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticariprint_215099_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticariprint_503111_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticariprint_800510_value: this.state.getBasvuruFormuById?.at?.(0)?.diziKonu ?? undefined,
      bformuticariprint_125636_value: this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined,
      bformuticariprint_949599_value: this.state.getBasvuruFormuById?.at?.(0)?.diziBolumNo ?? undefined,
      bformuticariprint_180071_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
      bformuticariprint_496974_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticariprint_408183_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSure ?? undefined,
      bformuticariprint_554864_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticariprint_711138_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticariprint_193622_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticariprint_296139_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticariprint_54329_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticariprint_139423_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_315845_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticariprint_953799_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_795343_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
      bformuticariprint_894235_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticariprint_752355_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticariprint_37671_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticariprint_183550_value: this.state.getBasvuruFormuById?.at?.(0)?.kacBolum ?? undefined,
      bformuticariprint_780979_value: this.state.getBasvuruFormuById?.at?.(0)?.sinopsis ?? undefined,
      bformuticariprint_175576_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimYeri ?? undefined,
      bformuticariprint_563208_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticariprint_861385_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticariprint_482186_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticariprint_398647_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_433250_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_401545_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
      bformuticariprint_548831_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticariprint_241884_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticariprint_154279_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticariprint_306823_value: this.state.getBasvuruFormuById?.at?.(0)?.senaryoOzeti ?? undefined,
      bformuticariprint_821567_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticariprint_135744_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSure ?? undefined,
      bformuticariprint_109874_value: this.state.getBasvuruFormuById?.at?.(0)?.fragmandaVarmi ?? undefined,
      bformuticariprint_43394_value: this.state.getBasvuruFormuById?.at?.(0)?.filmMecra ?? undefined,
      bformuticariprint_49563_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticariprint_437312_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticariprint_763581_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalmi ?? undefined,
      bformuticariprint_478463_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalAciklama ?? undefined,
      bformuticariprint_161165_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticariprint_597208_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticariprint_108219_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticariprint_599586_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_179006_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticariprint_779822_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_139034_value: this.state.getBasvuruFormuById?.at?.(0)?.sanatcilar ?? undefined,
      bformuticariprint_213030_value: this.state.getBasvuruFormuById?.at?.(0)?.yapimci ?? undefined,
      bformuticariprint_895754_value: this.state.getBasvuruFormuById?.at?.(0)?.sponsor ?? undefined,
      bformuticariprint_569899_value: this.state.getBasvuruFormuById?.at?.(0)?.konserYerTarih ?? undefined,
      bformuticariprint_179729_value: this.state.getBasvuruFormuById?.at?.(0)?.konserKapasitesi ?? undefined,
      bformuticariprint_936236_value: this.state.getBasvuruFormuById?.at?.(0)?.biletFiyatlari ?? undefined,
      bformuticariprint_46982_value: this.state.getBasvuruFormuById?.at?.(0)?.konserAfis ?? undefined,
      bformuticariprint_925632_value: this.state.getBasvuruFormuById?.at?.(0)?.konserKayit ?? undefined,
      bformuticariprint_753776_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGoruntu ?? undefined,
      bformuticariprint_118061_value: this.state.getBasvuruFormuById?.at?.(0)?.konserCDvd ?? undefined,
      bformuticariprint_447965_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticariprint_636418_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_606315_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticariprint_243523_value: this.state.getBasvuruFormuById?.at?.(0)?.konserHologram ?? undefined,
      bformuticariprint_252338_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGelir ?? undefined,
      bformuticariprint_63361_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGelirPay ?? undefined,
      bformuticariprint_160425_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_634083_value: this.state.getBasvuruFormuById?.at?.(0)?.urun ?? undefined,
      bformuticariprint_375623_value: this.state.getBasvuruFormuById?.at?.(0)?.parca ?? undefined,
      bformuticariprint_84279_value: this.state.getBasvuruFormuById?.at?.(0)?.besteciSozYazari ?? undefined,
      bformuticariprint_952938_value: this.state.getBasvuruFormuById?.at?.(0)?.izinTuruID ?? undefined,
      bformuticariprint_330499_value: this.state.getBasvuruFormuById?.at?.(0)?.mecras ?? undefined,
      bformuticariprint_575302_value: this.state.getBasvuruFormuById?.at?.(0)?.spot ?? undefined,
      bformuticariprint_275256_value: this.state.getBasvuruFormuById?.at?.(0)?.filmSayisi ?? undefined,
      bformuticariprint_33436_value: this.state.getBasvuruFormuById?.at?.(0)?.versiyonSayisi ?? undefined,
      bformuticariprint_601579_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticariprint_925097_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticariprint_713576_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticariprint_776748_value: this.state.getBasvuruFormuById?.at?.(0)?.arsiv ?? undefined,
      bformuticariprint_883613_value: this.state.getBasvuruFormuById?.at?.(0)?.arsivSure ?? undefined,
      bformuticariprint_69287_value: this.state.getBasvuruFormuById?.at?.(0)?.script ?? undefined,
      bformuticariprint_423768_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_680656_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticariprint_826306_value: this.state.getBasvuruFormuById?.at?.(0)?.sozDegisimi ?? undefined,
      bformuticariprint_508876_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
      bformuticariprint_958009_value: this.state.getBasvuruFormuById?.at?.(0)?.munhasirlikID ?? undefined,
      bformuticariprint_41842_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_769963_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
      bformuticariprint_745722_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticariprint_678596_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticariprint_13402_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticariprint_98625_value: this.state.getBasvuruFormuById?.at?.(0)?.senaryoOzeti ?? undefined,
      bformuticariprint_977010_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticariprint_939868_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneVeKoltuk ?? undefined,
      bformuticariprint_605461_value: this.state.getBasvuruFormuById?.at?.(0)?.koltuk ?? undefined,
      bformuticariprint_348650_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinlanmaFrekans ?? undefined,
      bformuticariprint_785985_value: this.state.getBasvuruFormuById?.at?.(0)?.biletFiyatlari ?? undefined,
      bformuticariprint_114157_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticariprint_961538_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticariprint_531317_value: this.state.getBasvuruFormuById?.at?.(0)?.sponsor ?? undefined,
      bformuticariprint_595383_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticariprint_945615_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_997705_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticariprint_834746_value: this.state.getBasvuruFormuById?.at?.(0)?.fragmandaVarmi ?? undefined,
      bformuticariprint_970226_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_284840_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
      bformuticariprint_584964_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticariprint_151522_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticariprint_818678_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
      bformuticariprint_300311_value: this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined,
      bformuticariprint_596953_value: this.state.getBasvuruFormuById?.at?.(0)?.tvBolumNo ?? undefined,
      bformuticariprint_38491_value: this.state.getBasvuruFormuById?.at?.(0)?.tvYayimTarihi ?? undefined,
      bformuticariprint_701444_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinlanmaFrekans ?? undefined,
      bformuticariprint_475588_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticariprint_382382_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticariprint_841151_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticariprint_691237_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticariprint_232600_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticariprint_653975_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticariprint_607210_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticariprint_709919_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticariprint_830230_value: this.state.getBasvuruFormuById?.at?.(0)?.projeTuruID ?? undefined,
      bformuticariprint_169770_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
      bformuticariprint_211926_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
      bformuticariprint_409878_value: this.state.getBasvuruFormuById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BFormuTicariPrintPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      Id_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_9: ReactSystemFunctions.convertToTypeByName("", "string"),
      BasvuruFormuID_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicariPrint/BFormuTicariPrintPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getBasvuruTuruKodById = result?.data.getBasvuruTuruKodById;
    stateVars.getProjeTuruFormKodById = result?.data.getProjeTuruFormKodById;
    stateVars.getFormNo = result?.data.getFormNo;
    stateVars.isComp861186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468627Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10355Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp967257Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp938143Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274058Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256251Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28903Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115665Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3330Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695955Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp25348Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp487276Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981156Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169331Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp998670Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp406152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712408Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp951915Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp220908Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846395Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp755011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466555Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp535471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564896Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp572237Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp307737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269167Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.sbFirmaTuruList = result?.data.sbFirmaTuruList;

    formVars.bformuticariprint_907796_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null;
    formVars.bformuticariprint_907796_options = stateVars.sbFirmaTuruList;
    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;

    formVars.bformuticariprint_830230_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null;
    formVars.bformuticariprint_830230_options = stateVars.sbProjeTuruList;
    stateVars.sbMecraList = result?.data.sbMecraList;
    stateVars.sbIzinTuruList = result?.data.sbIzinTuruList;

    formVars.bformuticariprint_952938_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].izinTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].izinTuruID
        : null;
    formVars.bformuticariprint_952938_options = stateVars.sbIzinTuruList;
    stateVars.sbMunhasirlikList = result?.data.sbMunhasirlikList;

    formVars.bformuticariprint_958009_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].munhasirlikID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].munhasirlikID
        : null;
    formVars.bformuticariprint_958009_options = stateVars.sbMunhasirlikList;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.bformuticariprint_389012_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basvuranSirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basvuranSirketAd
        : null
    );
    formVars.bformuticariprint_907796_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null;
    formVars.bformuticariprint_907796_options = stateVars.sbFirmaTuruList;
    formVars.bformuticariprint_654168_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sirketAd
        : null
    );
    formVars.bformuticariprint_405451_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserAd
        : null
    );
    formVars.bformuticariprint_788421_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSahibi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSahibi
        : null
    );
    formVars.bformuticariprint_757838_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_613968_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );
    formVars.bformuticariprint_819000_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticariprint_441327_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticariprint_164797_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].belgeselinKonusu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].belgeselinKonusu
        : null
    );
    formVars.bformuticariprint_709057_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticariprint_96573_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticariprint_904040_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticariprint_655326_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null;
    formVars.bformuticariprint_363998_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );
    formVars.bformuticariprint_59667_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticariprint_846743_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticariprint_301253_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticariprint_499516_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_806100_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticariprint_727135_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_419406_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticariprint_464905_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );
    formVars.bformuticariprint_919241_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );
    formVars.bformuticariprint_74883_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].facebook
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].facebook
        : null
    );
    formVars.bformuticariprint_136717_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].instagram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].instagram
        : null
    );
    formVars.bformuticariprint_212231_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tikTok
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tikTok
        : null
    );
    formVars.bformuticariprint_628115_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].twitter
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].twitter
        : null
    );
    formVars.bformuticariprint_774689_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].youTube
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].youTube
        : null
    );
    formVars.bformuticariprint_176725_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].digerSosyalMedya
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].digerSosyalMedya
        : null
    );
    formVars.bformuticariprint_42241_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimSekliCD
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimSekliCD
        : null;
    formVars.bformuticariprint_151733_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formats
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formats
        : null;
    formVars.bformuticariprint_643220_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formatAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formatAciklama
        : null
    );
    formVars.bformuticariprint_174173_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );
    formVars.bformuticariprint_314315_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayimBolgesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayimBolgesi
        : null;
    formVars.bformuticariprint_363699_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].markaUrunVarsa
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].markaUrunVarsa
        : null
    );
    formVars.bformuticariprint_631318_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );
    formVars.bformuticariprint_731780_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_530996_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );
    formVars.bformuticariprint_35217_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticariprint_215099_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticariprint_503111_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticariprint_800510_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziKonu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziKonu
        : null
    );
    formVars.bformuticariprint_125636_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );
    formVars.bformuticariprint_949599_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziBolumNo
        : null
    );
    formVars.bformuticariprint_180071_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );
    formVars.bformuticariprint_496974_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticariprint_408183_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );
    formVars.bformuticariprint_554864_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticariprint_711138_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticariprint_193622_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticariprint_296139_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticariprint_54329_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticariprint_139423_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_315845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticariprint_953799_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_795343_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );
    formVars.bformuticariprint_894235_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticariprint_752355_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticariprint_37671_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticariprint_183550_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kacBolum
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kacBolum
        : null
    );
    formVars.bformuticariprint_780979_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sinopsis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sinopsis
        : null
    );
    formVars.bformuticariprint_175576_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimYeri
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimYeri
        : null;
    formVars.bformuticariprint_563208_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticariprint_861385_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticariprint_482186_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );
    formVars.bformuticariprint_398647_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_433250_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_401545_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );
    formVars.bformuticariprint_548831_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticariprint_241884_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticariprint_154279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticariprint_306823_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );
    formVars.bformuticariprint_821567_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticariprint_135744_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );
    formVars.bformuticariprint_109874_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );
    formVars.bformuticariprint_43394_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmMecra
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmMecra
        : null
    );
    formVars.bformuticariprint_49563_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticariprint_437312_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticariprint_763581_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null;
    formVars.bformuticariprint_478463_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );
    formVars.bformuticariprint_161165_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticariprint_597208_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticariprint_108219_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticariprint_599586_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_179006_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticariprint_779822_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_139034_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );
    formVars.bformuticariprint_213030_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );
    formVars.bformuticariprint_895754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );
    formVars.bformuticariprint_569899_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserYerTarih
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserYerTarih
        : null
    );
    formVars.bformuticariprint_179729_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKapasitesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKapasitesi
        : null
    );
    formVars.bformuticariprint_936236_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );
    formVars.bformuticariprint_46982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserAfis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserAfis
        : null
    );
    formVars.bformuticariprint_925632_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKayit
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKayit
        : null
    );
    formVars.bformuticariprint_753776_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGoruntu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGoruntu
        : null
    );
    formVars.bformuticariprint_118061_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserCDvd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserCDvd
        : null
    );
    formVars.bformuticariprint_447965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticariprint_636418_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_606315_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticariprint_243523_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserHologram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserHologram
        : null
    );
    formVars.bformuticariprint_252338_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelir
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelir
        : null
    );
    formVars.bformuticariprint_63361_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelirPay
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelirPay
        : null
    );
    formVars.bformuticariprint_160425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_634083_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].urun
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].urun
        : null
    );
    formVars.bformuticariprint_375623_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].parca
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].parca
        : null
    );
    formVars.bformuticariprint_84279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].besteciSozYazari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].besteciSozYazari
        : null
    );
    formVars.bformuticariprint_952938_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].izinTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].izinTuruID
        : null;
    formVars.bformuticariprint_952938_options = stateVars.sbIzinTuruList;
    formVars.bformuticariprint_330499_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].mecras
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].mecras
        : null
    );
    formVars.bformuticariprint_575302_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].spot
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].spot
        : null
    );
    formVars.bformuticariprint_275256_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmSayisi
        : null
    );
    formVars.bformuticariprint_33436_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].versiyonSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].versiyonSayisi
        : null
    );
    formVars.bformuticariprint_601579_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticariprint_925097_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticariprint_713576_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticariprint_776748_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsiv
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsiv
        : null;
    formVars.bformuticariprint_883613_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsivSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsivSure
        : null
    );
    formVars.bformuticariprint_69287_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].script
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].script
        : null
    );
    formVars.bformuticariprint_423768_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_680656_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticariprint_826306_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );
    formVars.bformuticariprint_508876_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );
    formVars.bformuticariprint_958009_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].munhasirlikID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].munhasirlikID
        : null;
    formVars.bformuticariprint_958009_options = stateVars.sbMunhasirlikList;
    formVars.bformuticariprint_41842_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_769963_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );
    formVars.bformuticariprint_745722_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticariprint_678596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticariprint_13402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticariprint_98625_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );
    formVars.bformuticariprint_977010_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticariprint_939868_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneVeKoltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneVeKoltuk
        : null
    );
    formVars.bformuticariprint_605461_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].koltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].koltuk
        : null
    );
    formVars.bformuticariprint_348650_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );
    formVars.bformuticariprint_785985_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );
    formVars.bformuticariprint_114157_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticariprint_961538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticariprint_531317_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );
    formVars.bformuticariprint_595383_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticariprint_945615_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_997705_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticariprint_834746_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );
    formVars.bformuticariprint_970226_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_284840_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );
    formVars.bformuticariprint_584964_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticariprint_151522_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticariprint_818678_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );
    formVars.bformuticariprint_300311_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );
    formVars.bformuticariprint_596953_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvBolumNo
        : null
    );
    formVars.bformuticariprint_38491_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvYayimTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvYayimTarihi
        : null
    );
    formVars.bformuticariprint_701444_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );
    formVars.bformuticariprint_475588_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticariprint_382382_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticariprint_841151_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticariprint_691237_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticariprint_232600_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticariprint_653975_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticariprint_607210_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticariprint_709919_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticariprint_830230_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null;
    formVars.bformuticariprint_830230_options = stateVars.sbProjeTuruList;
    formVars.bformuticariprint_169770_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );
    formVars.bformuticariprint_211926_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );
    formVars.bformuticariprint_409878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].aciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].aciklama
        : null
    );
    stateVars.getListKullanimByBasvuruFId = result?.data.getListKullanimByBasvuruFId;
    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;

    formVars.bformuticariprint_830230_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null;
    formVars.bformuticariprint_830230_options = stateVars.sbProjeTuruList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPrintPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticariprint_750474_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].adSoyadTEMP
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].adSoyadTEMP
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPrintPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].adSoyadTEMP
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].adSoyadTEMP
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformuticariprint_750474_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].fullName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].fullName
            : null
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticariprint_192848_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].eMailTEMP
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].eMailTEMP
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPrintPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].eMailTEMP
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].eMailTEMP
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformuticariprint_192848_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].userName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].userName
            : null
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticariprint_390390_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].gsmtemp
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].gsmtemp
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPrintPageInit6_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].gsmtemp
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].gsmtemp
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformuticariprint_390390_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].gsm
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].gsm
            : null
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_653975_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_653975_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_653975_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_945615_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_945615_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_945615_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_423768_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_423768_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_423768_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_636418_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_636418_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_636418_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_599586_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_599586_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_599586_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_139423_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_139423_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_139423_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit25_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_499516_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit26_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit26_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_499516_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit27_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit27_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_499516_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit28_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintPageInit28_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit28_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      FormNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "number"
      ),
      Nerden_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicariPrint/BFormuTicariPrintPageInit28_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDokumansListByBFId = result?.data.getDokumansListByBFId;
    stateVars.sbBasvuruTuruList = result?.data.sbBasvuruTuruList;
    stateVars.sbProjeFormTuruList = result?.data.sbProjeFormTuruList;
    stateVars.rwDigeriseVisible01 = result?.data.rwDigeriseVisible01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPrintPageInit29_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariPrintPageInit29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp208142Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewDisplay ?? this.props.screenInputs.newdisplay, "D") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp455722Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewDisplay ?? this.props.screenInputs.newdisplay, "D") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp861186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468627Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10355Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp967257Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp938143Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274058Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256251Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28903Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115665Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3330Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695955Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp25348Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp487276Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981156Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169331Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp998670Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp406152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712408Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_389012_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basvuranSirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basvuranSirketAd
        : null
    );

    formVars.bformuticariprint_907796_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null
    );

    stateVars.dataSource_907796 = this.state.sbFirmaTuruList;
    stateVars.dataSource_907796 = this.state.sbFirmaTuruList;
    formVars.bformuticariprint_654168_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sirketAd
        : null
    );

    stateVars.isComp951915Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_405451_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserAd
        : null
    );

    stateVars.isComp220908Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_788421_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSahibi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSahibi
        : null
    );

    stateVars.isComp846395Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_757838_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_613968_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );

    formVars.bformuticariprint_819000_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticariprint_441327_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticariprint_164797_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].belgeselinKonusu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].belgeselinKonusu
        : null
    );

    formVars.bformuticariprint_709057_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticariprint_96573_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticariprint_904040_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticariprint_655326_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null
    );

    formVars.bformuticariprint_363998_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );

    formVars.bformuticariprint_59667_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticariprint_846743_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticariprint_301253_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticariprint_499516_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticariprint_806100_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp755011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_727135_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_419406_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticariprint_464905_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );

    formVars.bformuticariprint_919241_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );

    formVars.bformuticariprint_74883_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].facebook
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].facebook
        : null
    );

    formVars.bformuticariprint_136717_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].instagram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].instagram
        : null
    );

    formVars.bformuticariprint_212231_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tikTok
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tikTok
        : null
    );

    formVars.bformuticariprint_628115_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].twitter
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].twitter
        : null
    );

    formVars.bformuticariprint_774689_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].youTube
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].youTube
        : null
    );

    formVars.bformuticariprint_176725_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].digerSosyalMedya
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].digerSosyalMedya
        : null
    );

    formVars.bformuticariprint_42241_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimSekliCD
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimSekliCD
        : null
    );

    formVars.bformuticariprint_151733_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formats
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formats
        : null
    );

    formVars.bformuticariprint_643220_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formatAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formatAciklama
        : null
    );

    formVars.bformuticariprint_174173_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );

    formVars.bformuticariprint_314315_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayimBolgesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayimBolgesi
        : null
    );

    formVars.bformuticariprint_363699_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].markaUrunVarsa
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].markaUrunVarsa
        : null
    );

    formVars.bformuticariprint_631318_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );

    stateVars.isComp466555Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_731780_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_530996_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );

    formVars.bformuticariprint_35217_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticariprint_215099_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticariprint_503111_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticariprint_800510_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziKonu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziKonu
        : null
    );

    formVars.bformuticariprint_125636_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );

    formVars.bformuticariprint_949599_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziBolumNo
        : null
    );

    formVars.bformuticariprint_180071_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );

    formVars.bformuticariprint_496974_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticariprint_408183_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );

    formVars.bformuticariprint_554864_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticariprint_711138_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticariprint_193622_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticariprint_296139_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticariprint_54329_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticariprint_139423_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticariprint_315845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp434573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_953799_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_795343_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );

    formVars.bformuticariprint_894235_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticariprint_752355_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticariprint_37671_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticariprint_183550_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kacBolum
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kacBolum
        : null
    );

    formVars.bformuticariprint_780979_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sinopsis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sinopsis
        : null
    );

    formVars.bformuticariprint_175576_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimYeri
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimYeri
        : null
    );

    formVars.bformuticariprint_563208_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticariprint_861385_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticariprint_482186_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticariprint_398647_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    stateVars.isComp535471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_433250_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_401545_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );

    formVars.bformuticariprint_548831_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticariprint_241884_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticariprint_154279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticariprint_306823_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );

    formVars.bformuticariprint_821567_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticariprint_135744_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );

    formVars.bformuticariprint_109874_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );

    formVars.bformuticariprint_43394_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmMecra
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmMecra
        : null
    );

    formVars.bformuticariprint_49563_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticariprint_437312_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticariprint_763581_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null
    );

    formVars.bformuticariprint_478463_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );

    formVars.bformuticariprint_161165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticariprint_597208_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticariprint_108219_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticariprint_599586_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticariprint_179006_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp564896Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_779822_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_139034_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );

    formVars.bformuticariprint_213030_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );

    formVars.bformuticariprint_895754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );

    formVars.bformuticariprint_569899_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserYerTarih
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserYerTarih
        : null
    );

    formVars.bformuticariprint_179729_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKapasitesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKapasitesi
        : null
    );

    formVars.bformuticariprint_936236_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );

    formVars.bformuticariprint_46982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserAfis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserAfis
        : null
    );

    formVars.bformuticariprint_925632_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKayit
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKayit
        : null
    );

    formVars.bformuticariprint_753776_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGoruntu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGoruntu
        : null
    );

    formVars.bformuticariprint_118061_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserCDvd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserCDvd
        : null
    );

    formVars.bformuticariprint_447965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticariprint_636418_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticariprint_606315_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    formVars.bformuticariprint_243523_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserHologram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserHologram
        : null
    );

    formVars.bformuticariprint_252338_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelir
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelir
        : null
    );

    formVars.bformuticariprint_63361_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelirPay
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelirPay
        : null
    );

    stateVars.isComp315445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_160425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_634083_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].urun
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].urun
        : null
    );

    formVars.bformuticariprint_375623_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].parca
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].parca
        : null
    );

    formVars.bformuticariprint_84279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].besteciSozYazari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].besteciSozYazari
        : null
    );

    formVars.bformuticariprint_952938_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].izinTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].izinTuruID
        : null
    );

    stateVars.dataSource_952938 = this.state.sbIzinTuruList;
    stateVars.dataSource_952938 = this.state.sbIzinTuruList;
    formVars.bformuticariprint_330499_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].mecras
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].mecras
        : null
    );

    formVars.bformuticariprint_575302_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].spot
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].spot
        : null
    );

    formVars.bformuticariprint_275256_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmSayisi
        : null
    );

    formVars.bformuticariprint_33436_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].versiyonSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].versiyonSayisi
        : null
    );

    formVars.bformuticariprint_601579_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticariprint_925097_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticariprint_713576_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticariprint_776748_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsiv
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsiv
        : null
    );

    formVars.bformuticariprint_883613_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsivSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsivSure
        : null
    );

    formVars.bformuticariprint_69287_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].script
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].script
        : null
    );

    formVars.bformuticariprint_423768_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticariprint_680656_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    formVars.bformuticariprint_826306_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );

    formVars.bformuticariprint_508876_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );

    formVars.bformuticariprint_958009_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].munhasirlikID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].munhasirlikID
        : null
    );

    stateVars.dataSource_958009 = this.state.sbMunhasirlikList;
    stateVars.dataSource_958009 = this.state.sbMunhasirlikList;
    stateVars.isComp572237Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_41842_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_769963_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );

    formVars.bformuticariprint_745722_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticariprint_678596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticariprint_13402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticariprint_98625_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );

    formVars.bformuticariprint_977010_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticariprint_939868_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneVeKoltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneVeKoltuk
        : null
    );

    formVars.bformuticariprint_605461_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].koltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].koltuk
        : null
    );

    formVars.bformuticariprint_348650_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );

    formVars.bformuticariprint_785985_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );

    formVars.bformuticariprint_114157_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticariprint_961538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticariprint_531317_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );

    formVars.bformuticariprint_595383_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticariprint_945615_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticariprint_997705_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    formVars.bformuticariprint_834746_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );

    stateVars.isComp307737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_970226_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_284840_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );

    formVars.bformuticariprint_584964_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticariprint_151522_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticariprint_818678_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );

    formVars.bformuticariprint_300311_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );

    formVars.bformuticariprint_596953_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvBolumNo
        : null
    );

    formVars.bformuticariprint_38491_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvYayimTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvYayimTarihi
        : null
    );

    formVars.bformuticariprint_701444_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );

    formVars.bformuticariprint_475588_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticariprint_382382_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticariprint_841151_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticariprint_691237_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticariprint_232600_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticariprint_653975_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticariprint_607210_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp269167Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticariprint_709919_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticariprint_830230_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null
    );

    stateVars.dataSource_830230 = this.state.sbProjeTuruList;
    stateVars.dataSource_830230 = this.state.sbProjeTuruList;
    formVars.bformuticariprint_169770_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );

    formVars.bformuticariprint_211926_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );

    formVars.bformuticariprint_409878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].aciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_208142_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf = await ReactSystemFunctions.componentToPDF(
      ReactSystemFunctions.convertToTypeByName("510076", "string"),
      ReactSystemFunctions.convertToTypeByName("NORMAL", "string"),
      ReactSystemFunctions.convertToTypeByName("", "string")
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPrintComponent_208142_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_208142_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      str1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].ticketNo
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].ticketNo
            : null
        ),
        "string"
      ),
      str2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].projeTuru
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].projeTuru
            : null
        ),
        "string"
      ),
      str3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].projeAd
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].projeAd
            : null
        ),
        "string"
      ),
      str4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].eserAd
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].eserAd
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuTicariPrint/BFormuTicariPrintComponent_208142_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getPdfFileName = result?.data.getPdfFileName;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPrintComponent_208142_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_208142_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(this, this.state.ComponentToPdf),
      ReactSystemFunctions.toString(
        this,
        stateVars.getPdfFileName?.length > 0
          ? stateVars.getPdfFileName[0].pdfilename
          : this.state.getPdfFileName?.length > 0
          ? this.state.getPdfFileName[0].pdfilename
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_455722_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_499516_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_499516_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_499516_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_499516_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_499516_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_499516_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_139423_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_139423_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_139423_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_139423_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_139423_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_139423_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_599586_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_599586_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_599586_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_599586_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_599586_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_599586_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_636418_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_636418_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_636418_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_636418_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_636418_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_636418_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_776748_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_776748_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp119700Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_776748_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_776748_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_776748_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_776748_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp119700Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_423768_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_423768_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_423768_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_423768_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_423768_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_423768_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_945615_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_945615_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_945615_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_945615_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_945615_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_945615_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariPrintComponent_653975_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_653975_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_653975_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPrintComponent_653975_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPrintComponent_653975_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticariprint_653975_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
