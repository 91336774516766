import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  MultiSelect,
  Tooltip
} from "../../components/web-components";
import { Spacer } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { DagitimAltList_ScreenBase } from "./dagitimaltlist-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class DagitimAltList_Screen extends DagitimAltList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              dagitimaltlist_334501_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              dagitimaltlist_192205_value: "bbe036b9-d26f-4574-bb1a-08db2e2bf8f1",
              dagitimaltlist_839293_value:
                this.state.getFilterParametersByUserName?.at?.(0)?.dagitimAltListYil ?? undefined,
              dagitimaltlist_330389_value:
                this.state.getFilterParametersByUserName?.at?.(0)?.dagitimAltListQ ?? undefined,
              dagitimaltlist_894279_value:
                this.state.getFilterParametersByUserName?.at?.(0)?.dagitimAltListSearch ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="dagitimaltlist_header"
                style={
                  {
                    borderBottomRightRadius: 12,
                    borderBottomLeftRadius: 12,
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="87500"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="815399"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="741779"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="653137"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.DagitimAltListComponent_653137_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="dagitimaltlist_334501_value">
                        <Image
                          id="334501"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>

                    <Label
                      id="161810"
                      value={ReactSystemFunctions.getMyUsername()}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingLeft: 42,
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="930765"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Spacer id="597962" direction="horizontal" size={24} style={{} as any}>
                      <Label
                        id="625521"
                        value={ReactSystemFunctions.translate(this.ml, 625521, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "rgba(183, 14, 14, 1)"
                          } as any
                        }
                      ></Label>
                    </Spacer>
                  </KCol>

                  <KCol
                    id="277752"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="588351"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Tooltip
                        id="192408"
                        title={ReactSystemFunctions.translate(this.ml, 192408, "title", this.defaultML)}
                        placement="top"
                        style={
                          {
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                            borderBottomLeftRadius: 4,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="dagitimaltlist_192205_value">
                          <Image
                            id="192205"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.DagitimAltListComponent_192205_onClick();
                            }}
                            showCursorPointer
                            placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                            zoomOnClick={false}
                            imageFit="fit"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                height: "32px",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></Image>
                        </Form.Item>
                      </Tooltip>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="dagitimaltlist_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "block"
                  } as any
                }
              >
                <KDrawer
                  id="464411"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible464411: false }, () => {
                      this.callCallbackFunction(464411);
                    });
                  }}
                  visible={this.state.NavVisible464411}
                  push={true}
                  children={
                    this.state.NavVisible464411 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible464411: false }, () => {
                            this.callCallbackFunction(464411);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KRow
                  id="360655"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingRight: 50,
                      paddingBottom: 16,
                      paddingLeft: 48,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="888435"
                    xs={16}
                    sm={16}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="568453"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="148500"
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="dagitimaltlist_839293_value">
                          <MultiSelect
                            id="839293"
                            kuikaRef={this.dagitimaltlist_839293_value_kuikaMultiSelectRef}
                            options={this.state.sbQuarterYearList}
                            placeholder={ReactSystemFunctions.translate(this.ml, 839293, "placeholder", this.defaultML)}
                            allowClear={true}
                            autoClearSearchValue={true}
                            mode="tags"
                            showSearch={true}
                            autoRefresh={false}
                            datavaluefield="quarterYear"
                            datatextfield="quarterYear"
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></MultiSelect>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="192404"
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "center",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="dagitimaltlist_330389_value">
                          <MultiSelect
                            id="330389"
                            kuikaRef={this.dagitimaltlist_330389_value_kuikaMultiSelectRef}
                            options={this.state.sbQuarterList}
                            placeholder={ReactSystemFunctions.translate(this.ml, 330389, "placeholder", this.defaultML)}
                            allowClear={true}
                            autoClearSearchValue={true}
                            mode="tags"
                            showSearch={true}
                            autoRefresh={false}
                            datavaluefield="quarterKod"
                            datatextfield="quarterKod"
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></MultiSelect>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="878474"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="dagitimaltlist_894279_value">
                          <MultiSelect
                            id="894279"
                            kuikaRef={this.dagitimaltlist_894279_value_kuikaMultiSelectRef}
                            options={this.state.sbDagitimYeriList_AltCari}
                            placeholder={ReactSystemFunctions.translate(this.ml, 894279, "placeholder", this.defaultML)}
                            allowClear={true}
                            autoClearSearchValue={true}
                            mode="tags"
                            showSearch={true}
                            autoRefresh={false}
                            datavaluefield="alt_CariId"
                            datatextfield="alt_CariAd"
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></MultiSelect>
                        </Form.Item>
                      </KCol>
                    </KRow>
                  </KCol>

                  <KCol
                    id="232135"
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    xxl={2}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Icon
                      id="270085"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.DagitimAltListComponent_270085_onClick();
                      }}
                      showCursorPointer
                      iconName="playlist_play"
                      hoverText="Sonuçları Göster"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontSize: "24pt",
                          color: "rgba(245, 34, 45, 1)"
                        } as any
                      }
                    ></Icon>
                  </KCol>

                  <KCol
                    id="25075"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Button
                      id="598594"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.DagitimAltListComponent_598594_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 598594, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 32,
                          borderTopRightRadius: 32,
                          borderBottomRightRadius: 32,
                          borderBottomLeftRadius: 32,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderColor: "rgba(172, 38, 42, 1)",
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 0,
                          paddingRight: 12,
                          paddingBottom: 0,
                          paddingLeft: 12,
                          height: "36px",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "rgba(172, 38, 42, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Button>
                  </KCol>
                </KRow>

                <KRow
                  id="615081"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 32,
                      paddingRight: 48,
                      paddingBottom: 16,
                      paddingLeft: 48,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="774854"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KTable
                      id="660947"
                      kuikaRef={this.dagitimaltlist_660947_value_kuikaTableRef}
                      form={this.props.form}
                      dataSource={this.state.spDagitimList_AltCari}
                      size="middle"
                      bordered={true}
                      showHeader={true}
                      loading={false}
                      nodatafoundmessage={ReactSystemFunctions.translate(
                        this.ml,
                        660947,
                        "nodatafoundmessage",
                        this.defaultML
                      )}
                      sorter={false}
                      pagination={false}
                      striped={false}
                      stripedColor="#F5F7FA"
                      insertRowActive={false}
                      transformedOnMobileResolution={false}
                      fixedHeader={false}
                      editable={false}
                      tableWidthMode="fit"
                      globalSearch={false}
                      searchable={false}
                      filtering={false}
                      pageSize={10}
                      showSizeChanger={false}
                      columnChooser={false}
                      resizableColumns={false}
                      insertRowPosition="top"
                      showNoDataFound={true}
                      multiSelect={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KTableHeader
                        id="365128"
                        hideOnMobileResolution={false}
                        textDirection="Default"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(155, 155, 155, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KTableHeader>

                      <KTableRow
                        id="339983"
                        hoverFontColor="red"
                        hoverBgColor="yellow"
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopWidth: 1,
                            borderColor: "rgba(204, 204, 204, 1)",
                            borderStyle: "solid",
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KTableColumn
                          id="133595"
                          title={ReactSystemFunctions.translate(this.ml, 133595, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="431410"
                            value="[datafield:quarterYear]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="993136"
                          title={ReactSystemFunctions.translate(this.ml, 993136, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="343483"
                            value="[datafield:quarterKod]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="822531"
                          visibility={this.state.isComp822531Visible}
                          title={ReactSystemFunctions.translate(this.ml, 822531, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="166617"
                            value="[datafield:dagitimYeriKodu]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="468136"
                          title={ReactSystemFunctions.translate(this.ml, 468136, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              textAlign: "-webkit-left",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="408011"
                            value="[datafield:alt_CariAd]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="885016"
                          title={ReactSystemFunctions.translate(this.ml, 885016, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              textAlign: "-webkit-right",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Spacer
                            id="892726"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0
                              } as any
                            }
                          >
                            <Label
                              id="352743"
                              value="[datafield:tTutar]"
                              formatter=""
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="450855"
                              value="[datafield:_simge]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </Spacer>
                        </KTableColumn>

                        <KTableColumn
                          id="608287"
                          title={ReactSystemFunctions.translate(this.ml, 608287, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              textAlign: "-webkit-right",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="756031"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="602093"
                              value="[datafield:tHakedisTutar]"
                              formatter=""
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="62324"
                              value="[datafield:_simge]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KTableColumn>

                        <KTableColumn
                          id="547217"
                          title={ReactSystemFunctions.translate(this.ml, 547217, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              textAlign: "-webkit-right",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="7158"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="332798"
                              value="[datafield:tStopajTutar]"
                              formatter=""
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="129949"
                              value="[datafield:_simge]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KTableColumn>

                        <KTableColumn
                          id="213343"
                          title={ReactSystemFunctions.translate(this.ml, 213343, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              textAlign: "-webkit-right",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Spacer
                            id="737772"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0
                              } as any
                            }
                          >
                            <Label
                              id="25124"
                              value="[datafield:tOdenecekTutar]"
                              formatter=""
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="352398"
                              value="[datafield:_simge]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </Spacer>
                        </KTableColumn>

                        <KTableColumn
                          id="162409"
                          title={ReactSystemFunctions.translate(this.ml, 162409, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              textAlign: "-webkit-right",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Spacer
                            id="765584"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0
                              } as any
                            }
                          >
                            <Icon
                              id="871646"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.DagitimAltListComponent_871646_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp871646Visible}
                              iconName="info"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontSize: "24px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Icon>

                            <Icon
                              id="578618"
                              visibility={this.state.isComp578618Visible}
                              iconName="visibility"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontSize: "24px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Icon>
                          </Spacer>
                        </KTableColumn>
                      </KTableRow>
                    </KTable>

                    <KPagination
                      id="865924"
                      onChange={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.DagitimAltListComponent_865924_onChange();
                      }}
                      visibility={this.state.isComp865924Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.spDagitimList_AltCariCount[0]?.dagitimList_AltCariCount?.toString(),
                          "0"
                        )
                      }
                      kuikaRef={this.dagitimaltlist_865924_value_kuikaPaginationRef}
                      total={this.state.spDagitimList_AltCariCount?.at?.(0)?.dagitimList_AltCariCount ?? undefined}
                      pageSize={10}
                      defaultCurrent={1}
                      current={1}
                      defaultPageSize={10}
                      disabled={false}
                      hideOnSinglePage={true}
                      showLessItems={false}
                      showQuickJumper={false}
                      showSizeChanger={true}
                      showFirstPageButton={false}
                      showLastPageButton={false}
                      showNumbers={true}
                      showText={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 0,
                          paddingBottom: 8,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-right"
                        } as any
                      }
                    ></KPagination>
                  </KCol>
                </KRow>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(DagitimAltList_Screen))))
  )
);
export { tmp as DagitimAltList_Screen };
//export default tmp;
//export { tmp as DagitimAltList_Screen };
