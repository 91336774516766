import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAltKatalogBaglantiliMeslekBirligiKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAltKatalogBaglantiliMeslekBirligiKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  AltKatalogBaglantiliMeslekBirligiSave: number;
  AltKatalogBaglantiliMeslekBirligiSave_dummy: number;
  GoBack: string;
  GoBack_dummy: string;
  sbMeslekBirligiAdList: any[];
  sbMeslekBirligiAdList_dummy: any[];
  getAltKatalogBaglantiliMeslekBirligiKart: any[];
  getAltKatalogBaglantiliMeslekBirligiKart_dummy: any[];
}

export class AltKatalogBaglantiliMeslekBirligiKart_ScreenBase extends React.PureComponent<
  IAltKatalogBaglantiliMeslekBirligiKart_ScreenProps,
  any
> {
  altkatalogbaglantilimeslekbirligikart_131824_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "1bc8374c-2579-40f8-b09d-0220d9a59097",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 604367, PropertyName: "label", Value: "Kaydet" },
        { Id: 29196, PropertyName: "value", Value: "Alt Katalog Bağlantı" },
        { Id: 993411, PropertyName: "value", Value: "Yayımcı" },
        { Id: 980508, PropertyName: "placeholder", Value: "..." },
        { Id: 400481, PropertyName: "value", Value: "Meslek Birliği Adı" },
        { Id: 131824, PropertyName: "placeholder", Value: "..." },
        { Id: 11299, PropertyName: "value", Value: "IPI (CAE / Name Number)" },
        { Id: 302589, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.altkatalogbaglantilimeslekbirligikart_131824_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      AltKatalogBaglantiliMeslekBirligiSave: "",
      GoBack: "",
      sbMeslekBirligiAdList: [],
      getAltKatalogBaglantiliMeslekBirligiKart: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("altkatalogbaglantilimeslekbirligikart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AltKatalogBaglantiliMeslekBirligiKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("altkatalogbaglantilimeslekbirligikart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("altkatalogbaglantilimeslekbirligikart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AltKatalogBaglantiliMeslekBirligiKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      altkatalogbaglantilimeslekbirligikart_980508_value:
        this.state.getAltKatalogBaglantiliMeslekBirligiKart?.at?.(0)?.yayimci ?? undefined,
      altkatalogbaglantilimeslekbirligikart_131824_value:
        this.state.getAltKatalogBaglantiliMeslekBirligiKart?.at?.(0)?.meslekBirligiID ?? undefined,
      altkatalogbaglantilimeslekbirligikart_302589_value:
        this.state.getAltKatalogBaglantiliMeslekBirligiKart?.at?.(0)?.ipi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  AltKatalogBaglantiliMeslekBirligiKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKatalogBaglantiliMeslekBirligiKart/AltKatalogBaglantiliMeslekBirligiKartPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbMeslekBirligiAdList = result?.data.sbMeslekBirligiAdList;

    formVars.altkatalogbaglantilimeslekbirligikart_131824_value =
      stateVars.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? stateVars.getAltKatalogBaglantiliMeslekBirligiKart[0].meslekBirligiID
        : this.state.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? this.state.getAltKatalogBaglantiliMeslekBirligiKart[0].meslekBirligiID
        : null;
    formVars.altkatalogbaglantilimeslekbirligikart_131824_options = stateVars.sbMeslekBirligiAdList;
    stateVars.getAltKatalogBaglantiliMeslekBirligiKart = result?.data.getAltKatalogBaglantiliMeslekBirligiKart;
    formVars.altkatalogbaglantilimeslekbirligikart_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? stateVars.getAltKatalogBaglantiliMeslekBirligiKart[0].yayimci
        : this.state.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? this.state.getAltKatalogBaglantiliMeslekBirligiKart[0].yayimci
        : null
    );
    formVars.altkatalogbaglantilimeslekbirligikart_131824_value =
      stateVars.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? stateVars.getAltKatalogBaglantiliMeslekBirligiKart[0].meslekBirligiID
        : this.state.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? this.state.getAltKatalogBaglantiliMeslekBirligiKart[0].meslekBirligiID
        : null;
    formVars.altkatalogbaglantilimeslekbirligikart_131824_options = stateVars.sbMeslekBirligiAdList;
    formVars.altkatalogbaglantilimeslekbirligikart_302589_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? stateVars.getAltKatalogBaglantiliMeslekBirligiKart[0].ipi
        : this.state.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? this.state.getAltKatalogBaglantiliMeslekBirligiKart[0].ipi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKatalogBaglantiliMeslekBirligiKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogBaglantiliMeslekBirligiKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.altkatalogbaglantilimeslekbirligikart_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? stateVars.getAltKatalogBaglantiliMeslekBirligiKart[0].yayimci
        : this.state.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? this.state.getAltKatalogBaglantiliMeslekBirligiKart[0].yayimci
        : null
    );

    formVars.altkatalogbaglantilimeslekbirligikart_131824_value = ReactSystemFunctions.value(
      this,
      stateVars.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? stateVars.getAltKatalogBaglantiliMeslekBirligiKart[0].meslekBirligiID
        : this.state.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? this.state.getAltKatalogBaglantiliMeslekBirligiKart[0].meslekBirligiID
        : null
    );

    stateVars.dataSource_131824 = this.state.sbMeslekBirligiAdList;
    stateVars.dataSource_131824 = this.state.sbMeslekBirligiAdList;
    formVars.altkatalogbaglantilimeslekbirligikart_302589_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? stateVars.getAltKatalogBaglantiliMeslekBirligiKart[0].ipi
        : this.state.getAltKatalogBaglantiliMeslekBirligiKart?.length > 0
        ? this.state.getAltKatalogBaglantiliMeslekBirligiKart[0].ipi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AltKatalogBaglantiliMeslekBirligiKartComponent_604367_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AltKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmAltKatalogiID ?? this.props.screenInputs.prmaltkatalogiid,
        "Guid"
      ),
      IPI_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkatalogbaglantilimeslekbirligikart_302589_value", "value", "", "", "")
        ),
        "string"
      ),
      MeslekBirligiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "altkatalogbaglantilimeslekbirligikart_131824_value",
            "value",
            "sbMeslekBirligiAdList",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Yayimci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkatalogbaglantilimeslekbirligikart_980508_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKatalogBaglantiliMeslekBirligiKart/AltKatalogBaglantiliMeslekBirligiKartComponent_604367_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltKatalogBaglantiliMeslekBirligiSave = result?.data.altKatalogBaglantiliMeslekBirligiSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKatalogBaglantiliMeslekBirligiKartComponent_604367_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKatalogBaglantiliMeslekBirligiKartComponent_604367_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKatalogBaglantiliMeslekBirligiKartComponent_430249_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
