import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IIlce_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IIlce_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: string;
  GoBack_dummy: string;
  IlceSearch: any[];
  IlceSearch_dummy: any[];
  NAVIGATE: string;
  NAVIGATE_dummy: string;
  IlceDeleteById: number;
  IlceDeleteById_dummy: number;
}

export class Ilce_List_ScreenBase extends React.PureComponent<IIlce_List_ScreenProps, any> {
  ilce_list_399769_value_kuikaTableRef: React.RefObject<any>;
  ilce_list_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "ef237037-e79b-49b0-b59e-ebbe38feab99",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 172649, PropertyName: "value", Value: "ilce List" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search..." },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 3453451, PropertyName: "title", Value: "Ilce tanim" },
        { Id: 2453451, PropertyName: "value", Value: "[datafield:ilcetanim]" },
        { Id: 3453452, PropertyName: "title", Value: "Mh kodu" },
        { Id: 2453452, PropertyName: "value", Value: "[datafield:mhkodu]" },
        { Id: 3453453, PropertyName: "title", Value: "Il mh kodu" },
        { Id: 2453453, PropertyName: "value", Value: "[datafield:ilmhkodu]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.ilce_list_399769_value_kuikaTableRef = React.createRef();
    this.ilce_list_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      IlceSearch: [],
      NAVIGATE: "",
      IlceDeleteById: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ilce_list", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ilce_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("ilce_list", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ilce_list", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ilce_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ilce_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ilce_List/ilce_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IlceSearch = result?.data.ilceSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ilce_ListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ilce_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.IlceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ilce_ListComponent_848317_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ilce_ListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ilce_List/ilce_ListComponent_416063_onPressEnter", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IlceSearch = result?.data.ilceSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ilce_ListComponent_416063_onPressEnter1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ilce_ListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.IlceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ilce_ListComponent_416063_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ilce_List/ilce_ListComponent_416063_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IlceSearch = result?.data.ilceSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ilce_ListComponent_416063_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ilce_ListComponent_416063_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.IlceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ilce_ListComponent_484095_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ilce_List/ilce_ListComponent_484095_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IlceSearch = result?.data.ilceSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ilce_ListComponent_484095_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ilce_ListComponent_484095_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.IlceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ilce_ListComponent_346132_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("ilce_Form", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ilce_List",
      "ilce_Form",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "78466",
      null,
      "left",
      null,
      "512px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ilce_ListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ilce_Form",
      "Id",
      ReactSystemFunctions.value(this, "ilce_list_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ilce_List",
      "ilce_Form",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "left",
      null,
      "512px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ilce_ListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ilce_list_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "ilce_List/ilce_ListComponent_505853_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.IlceDeleteById = result?.data.ilceDeleteById;
      stateVars.IlceSearch = result?.data.ilceSearch;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ilce_ListComponent_505853_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ilce_ListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.IlceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ilce_ListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ilce_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ilce_List/ilce_ListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IlceSearch = result?.data.ilceSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ilce_ListComponent_172599_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ilce_ListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.IlceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [78466, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ilce_ListPageInit();
    }
  }
}
