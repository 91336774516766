import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPopupMuzikBankasiSecimi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPopupMuzikBankasiSecimi_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: string;
  GoBack_dummy: string;
  spUpdateStokID: any[];
  spUpdateStokID_dummy: any[];
  spPopup_Msb1_AlbumKoduList: any[];
  spPopup_Msb1_AlbumKoduList_dummy: any[];
  spPopup_sb1_KatalogKoduList: any[];
  spPopup_sb1_KatalogKoduList_dummy: any[];
  sbEserTipiPopupList: any[];
  sbEserTipiPopupList_dummy: any[];
  sbAnaKatalogList: any[];
  sbAnaKatalogList_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  spPopup_Msb2_TrackNoList: any[];
  spPopup_Msb2_TrackNoList_dummy: any[];
  sbAltKatalogList: any[];
  sbAltKatalogList_dummy: any[];
  spPopup_Bsb3_AlbumKoduList: any[];
  spPopup_Bsb3_AlbumKoduList_dummy: any[];
  spPopup_Bsb4_TrackNoList: any[];
  spPopup_Bsb4_TrackNoList_dummy: any[];
  isComp775567Visible: "visible" | "hidden";
  isComp752083Visible: "visible" | "hidden";
}

export class PopupMuzikBankasiSecimi_ScreenBase extends React.PureComponent<IPopupMuzikBankasiSecimi_ScreenProps, any> {
  popupmuzikbankasisecimi_773448_value_kuikaSelectBoxRef: React.RefObject<any>;
  popupmuzikbankasisecimi_476648_value_kuikaSelectBoxRef: React.RefObject<any>;
  popupmuzikbankasisecimi_748810_value_kuikaSelectBoxRef: React.RefObject<any>;
  popupmuzikbankasisecimi_718349_value_kuikaSelectBoxRef: React.RefObject<any>;
  popupmuzikbankasisecimi_709009_value_kuikaSelectBoxRef: React.RefObject<any>;
  popupmuzikbankasisecimi_640463_value_kuikaSelectBoxRef: React.RefObject<any>;
  popupmuzikbankasisecimi_620384_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "1dde5989-afa6-4005-af07-35160bce53ec",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 413085, PropertyName: "label", Value: "Vazgeç" },
        { Id: 473840, PropertyName: "label", Value: "Müzik Seç" },
        { Id: 11106, PropertyName: "value", Value: "Müzik Seçimi" },
        { Id: 430759, PropertyName: "value", Value: "Eser Tipi" },
        { Id: 773448, PropertyName: "placeholder", Value: "..." },
        { Id: 27292, PropertyName: "value", Value: "Albüm" },
        { Id: 476648, PropertyName: "placeholder", Value: "..." },
        { Id: 396075, PropertyName: "value", Value: "Track No" },
        { Id: 748810, PropertyName: "placeholder", Value: "..." },
        { Id: 973505, PropertyName: "value", Value: "Ana Katalog" },
        { Id: 718349, PropertyName: "placeholder", Value: "..." },
        { Id: 890705, PropertyName: "value", Value: "Alt Katalog" },
        { Id: 709009, PropertyName: "placeholder", Value: "..." },
        { Id: 313536, PropertyName: "value", Value: "Albüm" },
        { Id: 640463, PropertyName: "placeholder", Value: "..." },
        { Id: 773273, PropertyName: "value", Value: "Track No" },
        { Id: 620384, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.popupmuzikbankasisecimi_773448_value_kuikaSelectBoxRef = React.createRef();
    this.popupmuzikbankasisecimi_476648_value_kuikaSelectBoxRef = React.createRef();
    this.popupmuzikbankasisecimi_748810_value_kuikaSelectBoxRef = React.createRef();
    this.popupmuzikbankasisecimi_718349_value_kuikaSelectBoxRef = React.createRef();
    this.popupmuzikbankasisecimi_709009_value_kuikaSelectBoxRef = React.createRef();
    this.popupmuzikbankasisecimi_640463_value_kuikaSelectBoxRef = React.createRef();
    this.popupmuzikbankasisecimi_620384_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      spUpdateStokID: [],
      spPopup_Msb1_AlbumKoduList: [],
      spPopup_sb1_KatalogKoduList: [],
      sbEserTipiPopupList: [],
      sbAnaKatalogList: [],
      ChangeVisibilityOf: "",
      spPopup_Msb2_TrackNoList: [],
      sbAltKatalogList: [],
      spPopup_Bsb3_AlbumKoduList: [],
      spPopup_Bsb4_TrackNoList: [],
      isComp775567Visible: "hidden",
      isComp752083Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("popupmuzikbankasisecimi", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PopupMuzikBankasiSecimiPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("popupmuzikbankasisecimi", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("popupmuzikbankasisecimi", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PopupMuzikBankasiSecimiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  PopupMuzikBankasiSecimiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PopupMuzikBankasiSecimi/PopupMuzikBankasiSecimiPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPopup_Msb1_AlbumKoduList = result?.data.spPopup_Msb1_AlbumKoduList;

    formVars.popupmuzikbankasisecimi_476648_options = stateVars.spPopup_Msb1_AlbumKoduList;
    stateVars.spPopup_sb1_KatalogKoduList = result?.data.spPopup_sb1_KatalogKoduList;
    stateVars.sbEserTipiPopupList = result?.data.sbEserTipiPopupList;

    formVars.popupmuzikbankasisecimi_773448_options = stateVars.sbEserTipiPopupList;
    stateVars.sbAnaKatalogList = result?.data.sbAnaKatalogList;

    formVars.popupmuzikbankasisecimi_718349_options = stateVars.sbAnaKatalogList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupMuzikBankasiSecimiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_773448 = this.state.sbEserTipiPopupList;
    stateVars.dataSource_773448 = this.state.sbEserTipiPopupList;

    stateVars.dataSource_476648 = this.state.spPopup_Msb1_AlbumKoduList;
    stateVars.dataSource_476648 = this.state.spPopup_Msb1_AlbumKoduList;

    stateVars.dataSource_718349 = this.state.sbAnaKatalogList;
    stateVars.dataSource_718349 = this.state.sbAnaKatalogList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_413085_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_473840_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      AlbumKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_476648_value",
            "value",
            "spPopup_Msb1_AlbumKoduList",
            "albumKodu",
            "albumKodu"
          )
        ),
        "string"
      ),
      TrackNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_748810_value",
            "value",
            "spPopup_Msb2_TrackNoList",
            "trackNo",
            "trackNo"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_773448_value",
            "value",
            "sbEserTipiPopupList",
            "id",
            "kod"
          )
        ),
        "M"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "PopupMuzikBankasiSecimi/PopupMuzikBankasiSecimiComponent_473840_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateStokID = result?.data.spUpdateStokID;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_473840_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_473840_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_473840_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      AlbumKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_640463_value",
            "value",
            "spPopup_Bsb3_AlbumKoduList",
            "albumKodu",
            "albumKodu"
          )
        ),
        "string"
      ),
      TrackNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_620384_value",
            "value",
            "spPopup_Bsb4_TrackNoList",
            "trackNo",
            "trackNo"
          )
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_773448_value",
            "value",
            "sbEserTipiPopupList",
            "id",
            "kod"
          )
        ),
        "B"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "PopupMuzikBankasiSecimi/PopupMuzikBankasiSecimiComponent_473840_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateStokID = result?.data.spUpdateStokID;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_473840_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_473840_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_473840_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_710216_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_773448_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp775567Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp752083Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_773448_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_773448_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_773448_value",
            "value",
            "sbEserTipiPopupList",
            "id",
            "kod"
          )
        ),
        "M"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp775567Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_773448_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_773448_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_773448_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_773448_value",
            "value",
            "sbEserTipiPopupList",
            "id",
            "kod"
          )
        ),
        "B"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp752083Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_476648_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AlbumKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_476648_value",
            "value",
            "spPopup_Msb1_AlbumKoduList",
            "albumKodu",
            "albumKodu"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PopupMuzikBankasiSecimi/PopupMuzikBankasiSecimiComponent_476648_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPopup_Msb2_TrackNoList = result?.data.spPopup_Msb2_TrackNoList;

    formVars.popupmuzikbankasisecimi_748810_options = stateVars.spPopup_Msb2_TrackNoList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_476648_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_476648_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_748810 = this.state.spPopup_Msb2_TrackNoList;
    stateVars.dataSource_748810 = this.state.spPopup_Msb2_TrackNoList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_718349_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_718349_value",
            "value",
            "sbAnaKatalogList",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PopupMuzikBankasiSecimi/PopupMuzikBankasiSecimiComponent_718349_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbAltKatalogList = result?.data.sbAltKatalogList;

    formVars.popupmuzikbankasisecimi_709009_options = stateVars.sbAltKatalogList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_718349_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_718349_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_709009 = this.state.sbAltKatalogList;
    stateVars.dataSource_709009 = this.state.sbAltKatalogList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_709009_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AnaKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_718349_value",
            "value",
            "sbAnaKatalogList",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      AltKatalogID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_709009_value",
            "value",
            "sbAltKatalogList",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PopupMuzikBankasiSecimi/PopupMuzikBankasiSecimiComponent_709009_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPopup_Bsb3_AlbumKoduList = result?.data.spPopup_Bsb3_AlbumKoduList;

    formVars.popupmuzikbankasisecimi_640463_options = stateVars.spPopup_Bsb3_AlbumKoduList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_709009_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_709009_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_640463 = this.state.spPopup_Bsb3_AlbumKoduList;
    stateVars.dataSource_640463 = this.state.spPopup_Bsb3_AlbumKoduList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PopupMuzikBankasiSecimiComponent_640463_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AlbumKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "popupmuzikbankasisecimi_640463_value",
            "value",
            "spPopup_Bsb3_AlbumKoduList",
            "albumKodu",
            "albumKodu"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PopupMuzikBankasiSecimi/PopupMuzikBankasiSecimiComponent_640463_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPopup_Bsb4_TrackNoList = result?.data.spPopup_Bsb4_TrackNoList;

    formVars.popupmuzikbankasisecimi_620384_options = stateVars.spPopup_Bsb4_TrackNoList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PopupMuzikBankasiSecimiComponent_640463_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PopupMuzikBankasiSecimiComponent_640463_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_620384 = this.state.spPopup_Bsb4_TrackNoList;
    stateVars.dataSource_620384 = this.state.spPopup_Bsb4_TrackNoList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
