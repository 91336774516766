import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAksiyonKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAksiyonKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setSaveBFormuAksiyonlar: number;
  setSaveBFormuAksiyonlar_dummy: number;
  GoBack: string;
  GoBack_dummy: string;
  sbAksiyonTuruList: any[];
  sbAksiyonTuruList_dummy: any[];
  sbKUserList: any[];
  sbKUserList_dummy: any[];
  getBFormuAksiyonKartById: any[];
  getBFormuAksiyonKartById_dummy: any[];
  SetValueOf: string;
  SetValueOf_dummy: string;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  setAksiyonTurInsert: number;
  setAksiyonTurInsert_dummy: number;
  isComp789071Visible: "visible" | "hidden";
  isComp221915Visible: "visible" | "hidden";
}

export class AksiyonKart_ScreenBase extends React.PureComponent<IAksiyonKart_ScreenProps, any> {
  aksiyonkart_784594_value_kuikaDatePickerRef: React.RefObject<any>;
  aksiyonkart_130659_value_kuikaSelectBoxRef: React.RefObject<any>;
  aksiyonkart_457979_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "da94f335-f70f-488d-b1a6-f481c152a228",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 812307, PropertyName: "label", Value: "Kaydet" },
        { Id: 583955, PropertyName: "value", Value: "Güncel Gelişmeler" },
        { Id: 27304, PropertyName: "value", Value: "Tarih" },
        { Id: 784594, PropertyName: "placeholder", Value: "..." },
        { Id: 287167, PropertyName: "value", Value: "Güncellemeyi Yapan" },
        { Id: 130659, PropertyName: "placeholder", Value: "..." },
        { Id: 398932, PropertyName: "value", Value: "Güncelleme Türü" },
        { Id: 457979, PropertyName: "placeholder", Value: "..." },
        { Id: 775523, PropertyName: "placeholder", Value: "..." },
        { Id: 698573, PropertyName: "value", Value: "Güncel Durum Detayı" },
        { Id: 135733, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.aksiyonkart_784594_value_kuikaDatePickerRef = React.createRef();
    this.aksiyonkart_130659_value_kuikaSelectBoxRef = React.createRef();
    this.aksiyonkart_457979_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setSaveBFormuAksiyonlar: "",
      GoBack: "",
      sbAksiyonTuruList: [],
      sbKUserList: [],
      getBFormuAksiyonKartById: [],
      SetValueOf: "",
      ChangeVisibilityOf: "",
      setAksiyonTurInsert: "",
      isComp789071Visible: "visible",
      isComp221915Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("aksiyonkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AksiyonKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("aksiyonkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("aksiyonkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AksiyonKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      aksiyonkart_784594_value: this.state.getBFormuAksiyonKartById?.at?.(0)?.aksiyonTarihi ?? undefined,
      aksiyonkart_130659_value: this.state.getBFormuAksiyonKartById?.at?.(0)?.aksiyonYapan ?? undefined,
      aksiyonkart_457979_value: this.state.getBFormuAksiyonKartById?.at?.(0)?.aksiyonTurID ?? undefined,
      aksiyonkart_135733_value: this.state.getBFormuAksiyonKartById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  AksiyonKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AksiyonKart/AksiyonKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbAksiyonTuruList = result?.data.sbAksiyonTuruList;

    formVars.aksiyonkart_457979_value =
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonTurID
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonTurID
        : null;
    formVars.aksiyonkart_457979_options = stateVars.sbAksiyonTuruList;
    stateVars.sbKUserList = result?.data.sbKUserList;

    formVars.aksiyonkart_130659_value =
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonYapan
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonYapan
        : null;
    formVars.aksiyonkart_130659_options = stateVars.sbKUserList;
    stateVars.getBFormuAksiyonKartById = result?.data.getBFormuAksiyonKartById;
    formVars.aksiyonkart_784594_value = ReactSystemFunctions.value(
      this,
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonTarihi
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonTarihi
        : null
    );
    formVars.aksiyonkart_784594_defaultvalue = moment().format("YYYY-MM-DDTHH:mm:ss");
    formVars.aksiyonkart_130659_value =
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonYapan
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonYapan
        : null;
    formVars.aksiyonkart_130659_options = stateVars.sbKUserList;
    formVars.aksiyonkart_457979_value =
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonTurID
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonTurID
        : null;
    formVars.aksiyonkart_457979_options = stateVars.sbAksiyonTuruList;
    formVars.aksiyonkart_135733_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aciklama
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aciklama
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AksiyonKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AksiyonKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewDisplay ?? this.props.screenInputs.newdisplay, "N")) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("aksiyonkart_130659_value", "string"),
        ReactSystemFunctions.getMyUsername(),
        null,
        "UserName"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AksiyonKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AksiyonKartPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AksiyonKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.aksiyonkart_784594_value = ReactSystemFunctions.value(
      this,
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonTarihi
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonTarihi
        : null
    );

    formVars.aksiyonkart_130659_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonYapan
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonYapan
        : null
    );

    stateVars.dataSource_130659 = this.state.sbKUserList;
    stateVars.dataSource_130659 = this.state.sbKUserList;
    formVars.aksiyonkart_457979_value = ReactSystemFunctions.value(
      this,
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aksiyonTurID
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aksiyonTurID
        : null
    );

    stateVars.dataSource_457979 = this.state.sbAksiyonTuruList;
    stateVars.dataSource_457979 = this.state.sbAksiyonTuruList;
    formVars.aksiyonkart_135733_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBFormuAksiyonKartById?.length > 0
        ? stateVars.getBFormuAksiyonKartById[0].aciklama
        : this.state.getBFormuAksiyonKartById?.length > 0
        ? this.state.getBFormuAksiyonKartById[0].aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AksiyonKartComponent_812307_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_784594_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "aksiyonkart_784594_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_457979_value", "value", "sbAksiyonTuruList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "aksiyonkart_457979_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_130659_value", "value", "sbKUserList", "userName", "")
        ),
        null
      ),
      message: "*",
      formName: "aksiyonkart_130659_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_135733_value", "value", "", "", "")
        ),
        "string"
      ),
      AksiyonTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_784594_value", "value", "", "", "")
        ),
        "Date"
      ),
      AksiyonTurID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_457979_value", "value", "sbAksiyonTuruList", "id", "id")
        ),
        "Guid"
      ),
      AksiyonYapan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_130659_value", "value", "sbKUserList", "userName", "userName")
        ),
        "string"
      ),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BasvuruFormuID ?? this.props.screenInputs.basvuruformuid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AksiyonKart/AksiyonKartComponent_812307_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setSaveBFormuAksiyonlar = result?.data.setSaveBFormuAksiyonlar;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AksiyonKartComponent_812307_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AksiyonKartComponent_812307_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AksiyonKartComponent_748636_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AksiyonKartComponent_410346_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp789071Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp221915Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("visible", "string")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AksiyonKartComponent_372564_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AksiyonTanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "aksiyonkart_775523_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AksiyonKart/AksiyonKartComponent_372564_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setAksiyonTurInsert = result?.data.setAksiyonTurInsert;
    stateVars.sbAksiyonTuruList = result?.data.sbAksiyonTuruList;

    formVars.aksiyonkart_457979_options = stateVars.sbAksiyonTuruList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AksiyonKartComponent_372564_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AksiyonKartComponent_372564_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("aksiyonkart_775523_value", "string"),
      "",
      null
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp221915Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp789071Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("visible", "string")
    );

    stateVars.dataSource_457979 = this.state.sbAksiyonTuruList;
    stateVars.dataSource_457979 = this.state.sbAksiyonTuruList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AksiyonKartComponent_912306_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("aksiyonkart_775523_value", "string"),
      "",
      null
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp221915Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp789071Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("visible", "string")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
