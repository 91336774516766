import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDatePicker,
  KDrawer,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextArea,
  TextInput
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { UrunKart_ScreenBase } from "./urunkart-base";

import { EserKayitKart_Screen } from "../eserkayitkart/eserkayitkart";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { UrunPayDagilimKart_Screen } from "../urunpaydagilimkart/urunpaydagilimkart";
import { UrunPayDagilimKartelifsiz_Screen } from "../urunpaydagilimkartelifsiz/urunpaydagilimkartelifsiz";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class UrunKart_Screen extends UrunKart_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              urunkart_942611_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              urunkart_868633_value: this.state.getStokKartbyId?.at?.(0)?.eserTipiID ?? undefined,
              urunkart_6285_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
              urunkart_370468_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAlternatif ?? undefined,
              urunkart_563580_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAdapte ?? undefined,
              urunkart_986980_value: this.state.getStokKartbyId?.at?.(0)?.albumKodu ?? undefined,
              urunkart_519113_value: this.state.getStokKartbyId?.at?.(0)?.albumAd ?? undefined,
              urunkart_140490_value: this.state.getStokKartbyId?.at?.(0)?.trackNo ?? undefined,
              urunkart_607171_value: this.state.getStokKartbyId?.at?.(0)?.trackVersiyon ?? undefined,
              urunkart_391357_value: this.state.getStokKartbyId?.at?.(0)?.trackSure ?? undefined,
              urunkart_884751_value: this.state.getStokKartbyId?.at?.(0)?.trackAd ?? undefined,
              urunkart_520044_value: this.state.getStokKartbyId?.at?.(0)?.sozlesmeTarihi ?? undefined,
              urunkart_972982_value: this.state.getStokKartbyId?.at?.(0)?.iswc ?? undefined,
              urunkart_360462_value: this.state.getStokKartbyId?.at?.(0)?.releaseTarihi ?? undefined,
              urunkart_872732_value: this.state.getStokKartbyId?.at?.(0)?.yapimSahibi ?? undefined,
              urunkart_776878_value: this.state.getStokKartbyId?.at?.(0)?.isrc ?? undefined,
              urunkart_869356_value: this.state.getStokKartbyId?.at?.(0)?.ticariAlbumCikaran ?? undefined,
              urunkart_723070_value: this.state.getStokKartbyId?.at?.(0)?.ticariAlbumAd ?? undefined,
              urunkart_586230_value: this.state.getStokKartbyId?.at?.(0)?.ticariAd ?? undefined,
              urunkart_159707_value: this.state.getStokKartbyId?.at?.(0)?.ticariReleaseTarihi ?? undefined,
              urunkart_110409_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
              urunkart_523571_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAlternatif ?? undefined,
              urunkart_824162_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAdapte ?? undefined,
              urunkart_402655_value: this.state.getStokKartbyId?.at?.(0)?.sozDili ?? undefined,
              urunkart_565130_value: this.state.getStokKartbyId?.at?.(0)?.sozilksatir ?? undefined,
              urunkart_69214_value: this.state.getStokKartbyId?.at?.(0)?.tescilveMerci ?? undefined,
              urunkart_370470_value: this.state.getStokKartbyId?.at?.(0)?.iswc ?? undefined,
              urunkart_600359_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
              urunkart_251494_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAlternatif ?? undefined,
              urunkart_745458_value: this.state.getStokKartbyId?.at?.(0)?.stokAdAdapte ?? undefined,
              urunkart_285047_value: this.state.getStokKartbyId?.at?.(0)?.sozDili ?? undefined,
              urunkart_337728_value: this.state.getStokKartbyId?.at?.(0)?.sozilksatir ?? undefined,
              urunkart_614200_value: this.state.getStokKartbyId?.at?.(0)?.tescilveMerci ?? undefined,
              urunkart_298012_value: this.state.getStokKartbyId?.at?.(0)?.iswc ?? undefined,
              urunkart_578735_value: this.state.getStokKartbyId?.at?.(0)?.anaKatalogID ?? undefined,
              urunkart_234730_value: this.state.getStokKartbyId?.at?.(0)?.altKatalogID ?? undefined,
              urunkart_659821_value: this.state.getStokKartbyId?.at?.(0)?.stokAd ?? undefined,
              urunkart_130710_value: this.state.getStokKartbyId?.at?.(0)?.albumKodu ?? undefined,
              urunkart_590309_value: this.state.getStokKartbyId?.at?.(0)?.albumAd ?? undefined,
              urunkart_749098_value: this.state.getStokKartbyId?.at?.(0)?.trackNo ?? undefined,
              urunkart_999749_value: this.state.getStokKartbyId?.at?.(0)?.trackVersiyon ?? undefined,
              urunkart_932213_value: this.state.getStokKartbyId?.at?.(0)?.trackSure ?? undefined,
              urunkart_761278_value: this.state.getStokKartbyId?.at?.(0)?.temsilcilikDurumu ?? undefined,
              urunkart_971818_value: this.state.getStokKartbyId?.at?.(0)?.besteciler ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="urunkart_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="999842"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="747477"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="267418"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="515750"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.UrunKartComponent_515750_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="urunkart_942611_value">
                        <Image
                          id="942611"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="265416"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="554046"
                      value={ReactSystemFunctions.translate(this.ml, 554046, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="204240"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Icon
                      id="622923"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.UrunKartComponent_622923_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontSize: "24px",
                          color: "rgba(172, 38, 42, 1)"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="urunkart_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "block"
                  } as any
                }
              >
                <KDrawer
                  id="304331"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible304331: false }, () => {
                      this.callCallbackFunction(304331);
                    });
                  }}
                  visible={this.state.NavVisible304331}
                  push={true}
                  children={
                    this.state.NavVisible304331 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible304331: false }, () => {
                            this.callCallbackFunction(304331);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KRow
                  id="283913"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 48,
                      paddingBottom: 48,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="110232"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="944212"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 16,
                          paddingBottom: 16,
                          width: "900px",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="285020"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="550896"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="636117"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="12763"
                              value={ReactSystemFunctions.translate(this.ml, 12763, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="urunkart_868633_value">
                              <KSelectBox
                                id="868633"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.UrunKartComponent_868633_onChange();
                                }}
                                kuikaRef={this.urunkart_868633_value_kuikaSelectBoxRef}
                                options={this.state.sbEserTipiListYeni}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  868633,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="id"
                                datatextfield="tanim"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="149100"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="352631"
                          visibility={this.state.isComp352631Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiListYeni[0]?.kod?.toString(), "M")
                          }
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="155111"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="123450"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="863003"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="168968"
                                  value={ReactSystemFunctions.translate(this.ml, 168968, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_6285_value">
                                  <TextInput
                                    id="6285"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      6285,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="479252"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="568414"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="59872"
                                  value={ReactSystemFunctions.translate(this.ml, 59872, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_370468_value">
                                  <TextInput
                                    id="370468"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      370468,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="989121"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="517455"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="8015"
                                  value={ReactSystemFunctions.translate(this.ml, 8015, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_563580_value">
                                  <TextInput
                                    id="563580"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      563580,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="834686"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="556095"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="199175"
                                  value={ReactSystemFunctions.translate(this.ml, 199175, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_986980_value">
                                  <TextInput
                                    id="986980"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      986980,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="91899"
                                xs={10}
                                sm={10}
                                md={10}
                                lg={10}
                                xl={10}
                                xxl={10}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="207512"
                                  value={ReactSystemFunctions.translate(this.ml, 207512, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_519113_value">
                                  <TextInput
                                    id="519113"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      519113,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="199046"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="482170"
                                  value={ReactSystemFunctions.translate(this.ml, 482170, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_140490_value">
                                  <TextInput
                                    id="140490"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      140490,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="424900"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="780652"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="93994"
                                  value={ReactSystemFunctions.translate(this.ml, 93994, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_607171_value">
                                  <TextInput
                                    id="607171"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      607171,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="428700"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="864844"
                                  value={ReactSystemFunctions.translate(this.ml, 864844, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_391357_value">
                                  <TextInput
                                    id="391357"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      391357,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="6786"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="370656"
                                  visibility={this.state.isComp370656Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 370656, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_884751_value">
                                  <TextInput
                                    id="884751"
                                    visibility={this.state.isComp884751Visible}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      884751,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="850989"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="98927"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="254531"
                                  value={ReactSystemFunctions.translate(this.ml, 254531, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_520044_value">
                                  <KDatePicker
                                    id="520044"
                                    kuikaRef={this.urunkart_520044_value_kuikaDateRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      520044,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    format="DD/MM/YYYY"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KDatePicker>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="172146"
                                xs={10}
                                sm={10}
                                md={10}
                                lg={10}
                                xl={10}
                                xxl={10}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="560288"
                                  value={ReactSystemFunctions.translate(this.ml, 560288, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_972982_value">
                                  <TextInput
                                    id="972982"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      972982,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="637031"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="778437"
                                  value={ReactSystemFunctions.translate(this.ml, 778437, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_360462_value">
                                  <KDatePicker
                                    id="360462"
                                    kuikaRef={this.urunkart_360462_value_kuikaDateRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      360462,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    format="DD/MM/YYYY"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KDatePicker>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="483020"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="990164"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="997552"
                                  value={ReactSystemFunctions.translate(this.ml, 997552, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_872732_value">
                                  <TextInput
                                    id="872732"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      872732,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="318940"
                                xs={10}
                                sm={10}
                                md={10}
                                lg={10}
                                xl={10}
                                xxl={10}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="665254"
                                  value={ReactSystemFunctions.translate(this.ml, 665254, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_776878_value">
                                  <TextInput
                                    id="776878"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      776878,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="339367"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>
                            </KRow>

                            <KRow
                              id="620717"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingRight: 16,
                                  paddingBottom: 16,
                                  paddingLeft: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="611003"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="385167"
                                  value={ReactSystemFunctions.translate(this.ml, 385167, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>

                            <KRow
                              id="276379"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="965380"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="256214"
                                  value={ReactSystemFunctions.translate(this.ml, 256214, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_869356_value">
                                  <TextInput
                                    id="869356"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      869356,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="761297"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 0,
                                    paddingLeft: 8,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="654716"
                                  value={ReactSystemFunctions.translate(this.ml, 654716, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_723070_value">
                                  <TextInput
                                    id="723070"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      723070,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="371986"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 0,
                                    paddingLeft: 8,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="752850"
                                  value={ReactSystemFunctions.translate(this.ml, 752850, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_586230_value">
                                  <TextInput
                                    id="586230"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      586230,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="751330"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingLeft: 8,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="119094"
                                  value={ReactSystemFunctions.translate(this.ml, 119094, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_159707_value">
                                  <KDatePicker
                                    id="159707"
                                    kuikaRef={this.urunkart_159707_value_kuikaDateRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      159707,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    format="DD/MM/YYYY"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KDatePicker>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="439236"
                          visibility={this.state.isComp439236Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiListYeni[0]?.kod?.toString(), "T")
                          }
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(245, 166, 35, 0.02)",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="350223"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="24743"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="476207"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="126278"
                                  value={ReactSystemFunctions.translate(this.ml, 126278, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_110409_value">
                                  <TextInput
                                    id="110409"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      110409,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="12214"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="778074"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="644974"
                                  value={ReactSystemFunctions.translate(this.ml, 644974, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_523571_value">
                                  <TextInput
                                    id="523571"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      523571,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="15350"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="637912"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="526195"
                                  value={ReactSystemFunctions.translate(this.ml, 526195, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_824162_value">
                                  <TextInput
                                    id="824162"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      824162,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="291074"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="779615"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="606496"
                                  value={ReactSystemFunctions.translate(this.ml, 606496, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_402655_value">
                                  <TextInput
                                    id="402655"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      402655,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="484388"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="912223"
                                  value={ReactSystemFunctions.translate(this.ml, 912223, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_565130_value">
                                  <TextInput
                                    id="565130"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      565130,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="305066"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="299635"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="959899"
                                  value={ReactSystemFunctions.translate(this.ml, 959899, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_69214_value">
                                  <TextInput
                                    id="69214"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      69214,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="245676"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="345213"
                                  value={ReactSystemFunctions.translate(this.ml, 345213, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_370470_value">
                                  <TextInput
                                    id="370470"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      370470,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="971312"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="904262"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="873789"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="778673"
                                    xs={20}
                                    sm={20}
                                    md={20}
                                    lg={20}
                                    xl={20}
                                    xxl={20}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="985024"
                                      value={ReactSystemFunctions.translate(this.ml, 985024, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="966654"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="684462"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.UrunKartComponent_684462_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isCompurunkart_684462AuthorizationVisible}
                                      visibilityByAuthorization={this.state.isCompurunkart_684462AuthorizationVisible}
                                      iconName="add_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingLeft: 16,
                                          fontSize: "24px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KTable
                                  id="261881"
                                  kuikaRef={this.urunkart_261881_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getEserKayitlariListByStokId}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    261881,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="240385"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(155, 155, 155, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="893225"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(211, 211, 211, 1)",
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="967055"
                                      title={ReactSystemFunctions.translate(this.ml, 967055, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="563782"
                                        value="[datafield:seslendiren]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="382867"
                                      title={ReactSystemFunctions.translate(this.ml, 382867, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="15895"
                                        value="[datafield:yapimSirketi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="799944"
                                      title={ReactSystemFunctions.translate(this.ml, 799944, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="588618"
                                        value="[datafield:kayitYili]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="917033"
                                      title={ReactSystemFunctions.translate(this.ml, 917033, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="446711"
                                        value="[datafield:isrc]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="435281"
                                      title={ReactSystemFunctions.translate(this.ml, 435281, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="493942"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="155599"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.UrunKartComponent_155599_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isCompurunkart_155599AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompurunkart_155599AuthorizationVisible
                                          }
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              fontSize: "24px",
                                              color: "rgba(183, 14, 14, 1)"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="351695"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.UrunKartComponent_351695_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isCompurunkart_351695AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompurunkart_351695AuthorizationVisible
                                          }
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </HorizontalStack>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="960168"
                          visibility={this.state.isComp960168Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiListYeni[0]?.kod?.toString(), "R")
                          }
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(245, 166, 35, 0.02)",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="323437"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="483481"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="551808"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="574931"
                                  value={ReactSystemFunctions.translate(this.ml, 574931, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_600359_value">
                                  <TextInput
                                    id="600359"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      600359,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="397221"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="750831"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="410211"
                                  value={ReactSystemFunctions.translate(this.ml, 410211, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_251494_value">
                                  <TextInput
                                    id="251494"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      251494,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="811230"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="819903"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="148170"
                                  value={ReactSystemFunctions.translate(this.ml, 148170, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_745458_value">
                                  <TextInput
                                    id="745458"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      745458,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="593343"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="695476"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="609799"
                                  value={ReactSystemFunctions.translate(this.ml, 609799, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_285047_value">
                                  <TextInput
                                    id="285047"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      285047,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="443537"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="113936"
                                  value={ReactSystemFunctions.translate(this.ml, 113936, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_337728_value">
                                  <TextInput
                                    id="337728"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      337728,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="657049"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="559208"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="541616"
                                  value={ReactSystemFunctions.translate(this.ml, 541616, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_614200_value">
                                  <TextInput
                                    id="614200"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      614200,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="354870"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="840446"
                                  value={ReactSystemFunctions.translate(this.ml, 840446, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_298012_value">
                                  <TextInput
                                    id="298012"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      298012,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="230593"
                          visibility={this.state.isComp230593Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(this.state.sbEserTipiListYeni[0]?.kod?.toString(), "B")
                          }
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(184, 233, 134, 0.05)",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="190567"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="378857"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="639012"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="179534"
                                  value={ReactSystemFunctions.translate(this.ml, 179534, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_578735_value">
                                  <KSelectBox
                                    id="578735"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.UrunKartComponent_578735_onChange();
                                    }}
                                    kuikaRef={this.urunkart_578735_value_kuikaSelectBoxRef}
                                    options={this.state.sbAnaKatalogList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      578735,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={true}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="anaKatalogAd"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="632329"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="374539"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="581235"
                                  value={ReactSystemFunctions.translate(this.ml, 581235, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_234730_value">
                                  <KSelectBox
                                    id="234730"
                                    kuikaRef={this.urunkart_234730_value_kuikaSelectBoxRef}
                                    options={this.state.sbAltKatalogList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      234730,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={true}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="altKatalogKodAd"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="993577"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="691562"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="568440"
                                  value={ReactSystemFunctions.translate(this.ml, 568440, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_659821_value">
                                  <TextInput
                                    id="659821"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      659821,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="933316"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="109230"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="767695"
                                  value={ReactSystemFunctions.translate(this.ml, 767695, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_130710_value">
                                  <TextInput
                                    id="130710"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      130710,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="723872"
                                xs={10}
                                sm={10}
                                md={10}
                                lg={10}
                                xl={10}
                                xxl={10}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="295390"
                                  value={ReactSystemFunctions.translate(this.ml, 295390, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_590309_value">
                                  <TextInput
                                    id="590309"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      590309,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="384169"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="452135"
                                  value={ReactSystemFunctions.translate(this.ml, 452135, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_749098_value">
                                  <TextInput
                                    id="749098"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      749098,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="656056"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="976748"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="130105"
                                  value={ReactSystemFunctions.translate(this.ml, 130105, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_999749_value">
                                  <TextInput
                                    id="999749"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      999749,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="989221"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="731289"
                                  value={ReactSystemFunctions.translate(this.ml, 731289, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_932213_value">
                                  <TextInput
                                    id="932213"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      932213,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="507345"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="740657"
                                  value={ReactSystemFunctions.translate(this.ml, 740657, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_761278_value">
                                  <KSelectBox
                                    id="761278"
                                    kuikaRef={this.urunkart_761278_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_761278}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      761278,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="830612"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="366671"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="312838"
                                  value={ReactSystemFunctions.translate(this.ml, 312838, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="urunkart_971818_value">
                                  <TextArea
                                    id="971818"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      971818,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoSize={false}
                                    showCount={false}
                                    formatter="None"
                                    readOnly={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextArea>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="868926"
                          visibility={this.state.isComp868926Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="75103"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="443662"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="397603"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="707570"
                                  visibility={this.state.isComp707570Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isNotEqualTo(
                                      this.state.sbEserTipiListYeni[0]?.kod?.toString(),
                                      "R"
                                    )
                                  }
                                  value={ReactSystemFunctions.translate(this.ml, 707570, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="944587"
                                  visibility={this.state.isComp944587Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.sbEserTipiListYeni[0]?.kod?.toString(),
                                      "R"
                                    )
                                  }
                                  value={ReactSystemFunctions.translate(this.ml, 944587, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="970522"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="492388"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.UrunKartComponent_492388_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isCompurunkart_492388AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompurunkart_492388AuthorizationVisible}
                                  iconName="add_circle"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="604090"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="357246"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="981337"
                                  kuikaRef={this.urunkart_981337_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getEserPayDagilimListByStokId}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    981337,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="58399"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(155, 155, 155, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="941884"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="242926"
                                      title={ReactSystemFunctions.translate(this.ml, 242926, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="930735"
                                        value="[datafield:adSoyad]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="833397"
                                      title={ReactSystemFunctions.translate(this.ml, 833397, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="870872"
                                        value="[datafield:gorevi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="944008"
                                      title={ReactSystemFunctions.translate(this.ml, 944008, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="238471"
                                        value="[datafield:hakedisOran]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="3319"
                                      visibility={this.state.isComp3319Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.sbEserTipiListYeni[0]?.kod?.toString(),
                                          "R"
                                        )
                                      }
                                      title={ReactSystemFunctions.translate(this.ml, 3319, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="461838"
                                        value="[datafield:yayimciTanim]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="855521"
                                      visibility={this.state.isComp855521Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(
                                          this.state.sbEserTipiListYeni[0]?.kod?.toString(),
                                          "R"
                                        )
                                      }
                                      title={ReactSystemFunctions.translate(this.ml, 855521, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="961374"
                                        value="[datafield:mBirligiTanim]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="537689"
                                      visibility={this.state.isCompurunkart_537689AuthorizationVisible}
                                      visibilityByAuthorization={this.state.isCompurunkart_537689AuthorizationVisible}
                                      title={ReactSystemFunctions.translate(this.ml, 537689, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="878313"
                                        value="[datafield:muzOran]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="525158"
                                      visibility={this.state.isCompurunkart_525158AuthorizationVisible}
                                      visibilityByAuthorization={this.state.isCompurunkart_525158AuthorizationVisible}
                                      title={ReactSystemFunctions.translate(this.ml, 525158, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="289488"
                                        value="[datafield:muzOranTemsili]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="838769"
                                      title={ReactSystemFunctions.translate(this.ml, 838769, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="351838"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="211241"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.UrunKartComponent_211241_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isCompurunkart_211241AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompurunkart_211241AuthorizationVisible
                                          }
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              color: "rgba(183, 14, 14, 1)"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="553764"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.UrunKartComponent_553764_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isCompurunkart_553764AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompurunkart_553764AuthorizationVisible
                                          }
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "24px",
                                              color: "rgba(183, 14, 14, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </HorizontalStack>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="737157"
                              visibility={this.state.isComp737157Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isNotEqualTo(
                                  this.state.sbEserTipiListYeni[0]?.kod?.toString(),
                                  "R"
                                )
                              }
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="576742"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="660197"
                                  value={ReactSystemFunctions.translate(this.ml, 660197, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="851712"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="555720"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.UrunKartComponent_555720_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isCompurunkart_555720AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompurunkart_555720AuthorizationVisible}
                                  iconName="add_circle"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="580090"
                              visibility={this.state.isComp580090Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isNotEqualTo(
                                  this.state.sbEserTipiListYeni[0]?.kod?.toString(),
                                  "R"
                                )
                              }
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="842169"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="473103"
                                  kuikaRef={this.urunkart_473103_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getEserPayDagilimTelifsizListByStokId}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    473103,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  style={
                                    {
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="901720"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(155, 155, 155, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="59254"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="326858"
                                      title={ReactSystemFunctions.translate(this.ml, 326858, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="328365"
                                        value="[datafield:dagitimYapilacakKisi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="811023"
                                      title={ReactSystemFunctions.translate(this.ml, 811023, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="325514"
                                        value="[datafield:gorevi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="556966"
                                      visibility={this.state.isCompurunkart_556966AuthorizationVisible}
                                      visibilityByAuthorization={this.state.isCompurunkart_556966AuthorizationVisible}
                                      title={ReactSystemFunctions.translate(this.ml, 556966, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="342468"
                                        value="[datafield:hakedisOran]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="413960"
                                      title={ReactSystemFunctions.translate(this.ml, 413960, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="626157"
                                        value="[datafield:yayimciTanim]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="379983"
                                      title={ReactSystemFunctions.translate(this.ml, 379983, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          width: "50%",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="12721"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="353506"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.UrunKartComponent_353506_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isCompurunkart_353506AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompurunkart_353506AuthorizationVisible
                                          }
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="601868"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.UrunKartComponent_601868_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isCompurunkart_601868AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompurunkart_601868AuthorizationVisible
                                          }
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </HorizontalStack>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="468805"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="983221"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="885929"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="719555"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="902947"
                                  value={ReactSystemFunctions.translate(this.ml, 902947, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="982414"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="229594"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.UrunKartComponent_229594_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="article"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KTable
                              id="782375"
                              visibility={this.state.isComp782375Visible}
                              kuikaRef={this.urunkart_782375_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.spTarihceEser}
                              size="middle"
                              bordered={true}
                              showHeader={true}
                              loading={false}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                782375,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              sorter={false}
                              pagination={false}
                              striped={false}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              searchable={false}
                              filtering={false}
                              transformedOnMobileResolution={false}
                              fixedHeader={false}
                              editable={false}
                              tableWidthMode="fit"
                              globalSearch={false}
                              pageSize={10}
                              showSizeChanger={false}
                              columnChooser={false}
                              resizableColumns={false}
                              insertRowPosition="top"
                              showNoDataFound={true}
                              multiSelect={false}
                              export={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="989983"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(155, 155, 155, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="492880"
                                hoverFontColor="red"
                                hoverBgColor="yellow"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="742021"
                                  title={ReactSystemFunctions.translate(this.ml, 742021, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="547394"
                                    value="[datafield:ticketNo]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="932266"
                                  title={ReactSystemFunctions.translate(this.ml, 932266, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="254725"
                                    value="[datafield:quarterDate]"
                                    formatter="date"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="712601"
                                  title={ReactSystemFunctions.translate(this.ml, 712601, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="228427"
                                    value="[datafield:eserSahibi]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="939958"
                                  title={ReactSystemFunctions.translate(this.ml, 939958, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="78464"
                                    value="[datafield:lisansTuru]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="78823"
                                  title={ReactSystemFunctions.translate(this.ml, 78823, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="540961"
                                    value="[datafield:projeAd]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="96496"
                                  title={ReactSystemFunctions.translate(this.ml, 96496, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="623043"
                                    value="[datafield:projeTuru]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="544260"
                                  title={ReactSystemFunctions.translate(this.ml, 544260, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="980859"
                                    value="[datafield:kullanimTuru]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KCol>
                        </KRow>

                        <KRow
                          id="71001"
                          visibility={this.state.isComp71001Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isNotEmpty(this.state.sbEserTipiListYeni[0]?.id?.toString(), "")
                          }
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="831001"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="398542"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.UrunKartComponent_398542_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp398542Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isEqualTo(
                                  this.state.getStokKartbyId[0]?.isVazgecKaydetVisibility?.toString(),
                                  "1"
                                )
                              }
                              label={ReactSystemFunctions.translate(this.ml, 398542, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(172, 38, 42, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>

                          <KCol
                            id="138795"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="336812"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.UrunKartComponent_336812_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp336812Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isEqualTo(
                                  this.state.getStokKartbyId[0]?.isVazgecKaydetVisibility?.toString(),
                                  "1"
                                )
                              }
                              label={ReactSystemFunctions.translate(this.ml, 336812, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="right"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(172, 38, 42, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="374430"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible374430: false }, () => {
                      this.callCallbackFunction(374430);
                    });
                  }}
                  visible={this.state.NavVisible374430}
                  width="400"
                  push={true}
                  children={
                    this.state.NavVisible374430 && (
                      <UrunPayDagilimKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible374430: false }, () => {
                            this.callCallbackFunction(374430);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="107654"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible107654: false }, () => {
                      this.callCallbackFunction(107654);
                    });
                  }}
                  visible={this.state.NavVisible107654}
                  width="400"
                  push={true}
                  children={
                    this.state.NavVisible107654 && (
                      <UrunPayDagilimKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible107654: false }, () => {
                            this.callCallbackFunction(107654);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="407514"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible407514: false }, () => {
                      this.callCallbackFunction(407514);
                    });
                  }}
                  visible={this.state.NavVisible407514}
                  width="400"
                  push={true}
                  children={
                    this.state.NavVisible407514 && (
                      <UrunPayDagilimKartelifsiz_Screen
                        onClose={() => {
                          this.setState({ NavVisible407514: false }, () => {
                            this.callCallbackFunction(407514);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="739598"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible739598: false }, () => {
                      this.callCallbackFunction(739598);
                    });
                  }}
                  visible={this.state.NavVisible739598}
                  width="400"
                  push={true}
                  children={
                    this.state.NavVisible739598 && (
                      <UrunPayDagilimKartelifsiz_Screen
                        onClose={() => {
                          this.setState({ NavVisible739598: false }, () => {
                            this.callCallbackFunction(739598);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="231933"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible231933: false }, () => {
                      this.callCallbackFunction(231933);
                    });
                  }}
                  visible={this.state.NavVisible231933}
                  width="400px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible231933 && (
                      <EserKayitKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible231933: false }, () => {
                            this.callCallbackFunction(231933);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="514194"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible514194: false }, () => {
                      this.callCallbackFunction(514194);
                    });
                  }}
                  visible={this.state.NavVisible514194}
                  width="400px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible514194 && (
                      <EserKayitKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible514194: false }, () => {
                            this.callCallbackFunction(514194);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(UrunKart_Screen)))))
);
export { tmp as UrunKart_Screen };
//export default tmp;
//export { tmp as UrunKart_Screen };
