import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import { Image, KCol, KPanel, KRow, Label } from "../../components/web-components";
import { Spacer } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { BFormuTesekkur_ScreenBase } from "./bformutesekkur-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class BFormuTesekkur_Screen extends BFormuTesekkur_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{ bformutesekkur_980105_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af" }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div
              id="bformutesekkur_body"
              style={
                {
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  backgroundColor: "rgba(245, 245, 245, 1)",
                  backgroundImage:
                    "url(" +
                    KuikaAppManager.GetBackendUrl() +
                    "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  paddingTop: 128,
                  display: "block"
                } as any
              }
            >
              <KRow id="418160" align="top" justify="start" horizontalGutter={0} verticalGutter={0}>
                <KCol
                  id="419763"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      alignItems: "center",
                      textAlign: "-webkit-center"
                    } as any
                  }
                >
                  <KPanel
                    id="730863"
                    scrollRadius={false}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 0,
                        paddingBottom: 24,
                        maxWidth: "500px",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="5713"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 64,
                          paddingBottom: 24,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="648661"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="985495"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="866807"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Spacer id="692944" direction="horizontal" size={12} style={{} as any}>
                              <Label
                                id="465757"
                                value={ReactSystemFunctions.translate(this.ml, 465757, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></Label>
                            </Spacer>
                          </KCol>
                        </KRow>

                        <KRow
                          id="166915"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="138924"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                textAlign: "-webkit-center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="758498"
                              value={ReactSystemFunctions.translate(this.ml, 758498, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <Label
                      id="198001"
                      value={ReactSystemFunctions.translate(this.ml, 198001, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: "12px",
                          color: "rgba(208, 2, 27, 1)",
                          letterSpacing: "0.5px",
                          fontStyle: "italic"
                        } as any
                      }
                    ></Label>

                    <Label
                      id="414133"
                      value={ReactSystemFunctions.translate(this.ml, 414133, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: "12px",
                          color: "rgba(208, 2, 27, 1)",
                          letterSpacing: "0.5px",
                          fontStyle: "italic"
                        } as any
                      }
                    ></Label>

                    <KRow
                      id="983163"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 24,
                          paddingBottom: 24,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="92375"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformutesekkur_980105_value">
                          <Image
                            id="980105"
                            placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                            zoomOnClick={false}
                            imageFit="fit"
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingLeft: 16,
                                height: "24px",
                                textAlign: "-webkit-center"
                              } as any
                            }
                          ></Image>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="961346"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 24,
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="675946"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="677228"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="956162"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="905702"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.BFormuTesekkurComponent_905702_onClick();
                              }}
                              showCursorPointer
                              visibility={
                                this.state.isComp905702Visible == "hidden" ||
                                this.state.isCompbformutesekkur_905702AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityByAuthorization={
                                this.state.isCompbformutesekkur_905702AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isEqualTo(this.state.isLoggedIn?.toString(), "true")
                              }
                              value={ReactSystemFunctions.translate(this.ml, 905702, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="283175"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.BFormuTesekkurComponent_283175_onClick();
                              }}
                              showCursorPointer
                              visibility={
                                this.state.isComp283175Visible == "hidden" ||
                                this.state.isCompbformutesekkur_283175AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityByAuthorization={
                                this.state.isCompbformutesekkur_283175AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isEqualTo(this.state.isLoggedIn?.toString(), "true")
                              }
                              value={ReactSystemFunctions.translate(this.ml, 283175, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KPanel>
                </KCol>
              </KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(BFormuTesekkur_Screen))))
  )
);
export { tmp as BFormuTesekkur_Screen };
//export default tmp;
//export { tmp as BFormuTesekkur_Screen };
