import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICariIlgiliKisiler_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICariIlgiliKisiler_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setKartCariIlgiliKisilerSave: number;
  setKartCariIlgiliKisilerSave_dummy: number;
  GoBack: string;
  GoBack_dummy: string;
  getKartCariIlgiliKisi: any[];
  getKartCariIlgiliKisi_dummy: any[];
}

export class CariIlgiliKisiler_ScreenBase extends React.PureComponent<ICariIlgiliKisiler_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "a727a1b7-f4d4-48ad-b71a-a662d14f7fee",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 608676, PropertyName: "label", Value: "Kaydet" },
        { Id: 838135, PropertyName: "value", Value: "Ad Soyad" },
        { Id: 821142, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 856150, PropertyName: "value", Value: "Görevi" },
        { Id: 953737, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 929635, PropertyName: "value", Value: "Telefon" },
        { Id: 455759, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9473, PropertyName: "value", Value: "EMail" },
        { Id: 487916, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setKartCariIlgiliKisilerSave: "",
      GoBack: "",
      getKartCariIlgiliKisi: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("cariilgilikisiler", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CariIlgiliKisilerPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("cariilgilikisiler", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("cariilgilikisiler", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CariIlgiliKisilerPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      cariilgilikisiler_821142_value: this.state.getKartCariIlgiliKisi?.at?.(0)?.adSoyad ?? undefined,
      cariilgilikisiler_953737_value: this.state.getKartCariIlgiliKisi?.at?.(0)?.gorevi ?? undefined,
      cariilgilikisiler_455759_value: this.state.getKartCariIlgiliKisi?.at?.(0)?.telefon ?? undefined,
      cariilgilikisiler_487916_value: this.state.getKartCariIlgiliKisi?.at?.(0)?.eMail ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  CariIlgiliKisilerPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CariIlgiliKisiler/CariIlgiliKisilerPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getKartCariIlgiliKisi = result?.data.getKartCariIlgiliKisi;
    formVars.cariilgilikisiler_821142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].adSoyad
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].adSoyad
        : null
    );
    formVars.cariilgilikisiler_953737_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].gorevi
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].gorevi
        : null
    );
    formVars.cariilgilikisiler_455759_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].telefon
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].telefon
        : null
    );
    formVars.cariilgilikisiler_487916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].eMail
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].eMail
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariIlgiliKisilerPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariIlgiliKisilerPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.cariilgilikisiler_821142_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].adSoyad
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].adSoyad
        : null
    );

    formVars.cariilgilikisiler_953737_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].gorevi
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].gorevi
        : null
    );

    formVars.cariilgilikisiler_455759_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].telefon
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].telefon
        : null
    );

    formVars.cariilgilikisiler_487916_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartCariIlgiliKisi?.length > 0
        ? stateVars.getKartCariIlgiliKisi[0].eMail
        : this.state.getKartCariIlgiliKisi?.length > 0
        ? this.state.getKartCariIlgiliKisi[0].eMail
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CariIlgiliKisilerComponent_608676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariilgilikisiler_821142_value", "value", "", "", "")
        ),
        "string"
      ),
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmCariID ?? this.props.screenInputs.prmcariid,
        "Guid"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariilgilikisiler_487916_value", "value", "", "", "")
        ),
        "string"
      ),
      Gorevi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariilgilikisiler_953737_value", "value", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "cariilgilikisiler_455759_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CariIlgiliKisiler/CariIlgiliKisilerComponent_608676_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setKartCariIlgiliKisilerSave = result?.data.setKartCariIlgiliKisilerSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CariIlgiliKisilerComponent_608676_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CariIlgiliKisilerComponent_608676_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CariIlgiliKisilerComponent_905052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
