import { DatePicker, TimePicker } from "antd/es";
import { Guid } from "guid-typescript";
import _ from "lodash";
import moment from "moment";
import { PickerSharedProps } from "rc-picker/es/Picker";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { DashboardState } from "../kuika-cl-model-runtimes";

declare let window: any;

enum Displays {
  Date = 1,
  Time = 2,
  DateTime = 3,
  Month = 4
}
interface DateProps extends PickerSharedProps<any> {
  value?: string;
  defaultValue?: string;
  format?: string;
  use12Hours?: boolean;
  hourStep?: number;
  minuteStep?: number;
  secondStep?: number;
  onChange?: (value: string) => void;
  style?: any;
  placeholder?: string;
  disableBeforeDate?: any;
  className?: string;
  disabledDays?: (
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday"
    | "Weekends"
    | "Weekdays"
  )[];
}

interface DateState {
  uniqueKey: Guid;
  isOpen: boolean;
  disableBeforeDate?: any;
}

class KDatePicker extends PureComponent<DateProps & CommonProps, DateState> {
  private memoizedDynamicCssResult = "";

  constructor(props: DateProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create(),
      isOpen: false,
      disableBeforeDate: undefined
    };
  }

  componentDidMount = () => {
    this.setDynamicStyle();
    if (this.props && this.props.style && this.props.style?.fontSize) {
      const node = ReactDOM.findDOMNode(this) as Element;
      const inputs = node.getElementsByTagName("input");
      if (inputs && inputs.length) {
        for (let index = 0; index < inputs.length; index++) {
          inputs[index].style.fontSize = this.props.style?.fontSize;
        }
      }
    }
  };

  getCalculatedDisplay = (): Displays => {
    if (!this.props.format || this.props.format == "") {
      return Displays.Date;
    }
    let calculatedDisplay = Displays.Date;
    switch (this.props.format) {
      case "DD/MM/YYYY":
        calculatedDisplay = Displays.Date;
        break;
      case "MM/DD/YYYY":
        calculatedDisplay = Displays.Date;
        break;
      case "YYYY/MM/DD":
        calculatedDisplay = Displays.Date;
        break;
      case "YYYY/DD/MM":
        calculatedDisplay = Displays.Date;
        break;
      case "MM/DD":
        calculatedDisplay = Displays.Date;
        break;
      case "D M Y":
        calculatedDisplay = Displays.Date;
        break;
      case "YYYY-MM-DD HH:mm":
        calculatedDisplay = Displays.DateTime;
        break;
      case "DD-MM-YYYY HH:mm":
        calculatedDisplay = Displays.DateTime;
        break;
      case "DD-MM-YYYY HH:mm:ss":
        calculatedDisplay = Displays.DateTime;
        break;
      case "mm:ss":
        calculatedDisplay = Displays.Time;
        break;
      case "hh:mm:ss":
        calculatedDisplay = Displays.Time;
        break;
      case "HH:mm":
        calculatedDisplay = Displays.Time;
        break;
      case "YYYY-MM":
        calculatedDisplay = Displays.Month;
        break;
      default:
        calculatedDisplay = Displays.Date;
        break;
    }
    return calculatedDisplay;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    let result = "";
    if (this.props.style?.color || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-picker-input>input {
            `;
      if (this.props.style?.color) result += `color: ${this.props.style?.color} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    if (this.props.style?.fontSize || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-picker-input>input::-webkit-input-placeholder {
            `;
      if (this.props.style?.fontSize) result += `font-size: ${this.props.style?.fontSize} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    if (this.props.style?.backgroundColor?.length > 0 || this.props.style?.color?.length > 0) {
      result += `
          .${className.trim()} .ant-picker-clear {
            `;
      if (this.props.style?.backgroundColor?.length > 0)
        result += `background: ${this.props.style?.backgroundColor} !important;`;
      if (this.props.style?.color?.length > 0) result += `color: ${this.props.style?.color} !important;`;
      result += `;
      }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kdatepicker_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  disabledDate = (current) => {
    const cur = _.cloneDeep(current);
    const { disabledDays } = this.props;

    if (cur && disabledDays) {
      const day = cur.day();

      if (
        (disabledDays.includes("Sunday") && day === 0) ||
        (disabledDays.includes("Monday") && day === 1) ||
        (disabledDays.includes("Tuesday") && day === 2) ||
        (disabledDays.includes("Wednesday") && day === 3) ||
        (disabledDays.includes("Thursday") && day === 4) ||
        (disabledDays.includes("Friday") && day === 5) ||
        (disabledDays.includes("Saturday") && day === 6)
      ) {
        return true;
      }

      if (disabledDays.includes("Weekends") && (day === 0 || day === 6)) {
        return true;
      }

      if (disabledDays.includes("Weekdays") && day >= 1 && day <= 5) {
        return true;
      }
    }

    if (this.props.disableBeforeDate || this.state.disableBeforeDate) {
      const value =
        this.state.disableBeforeDate !== undefined
          ? moment(this.state.disableBeforeDate)
          : moment(this.props.disableBeforeDate);
      if (value) {
        if (this.getCalculatedDisplay() != Displays.Date) {
          return cur && cur < moment(value);
        }
        return cur.startOf("day") <= value.startOf("day").add(-1, "day");
      }
    }
    return undefined;
  };

  setDisableBeforeDate = (value: any) => {
    if (value) {
      this.setState({
        disableBeforeDate: value
      });
    }
  };

  convertToMoment = (value: string) => {
    return moment(value);
  };

  convertToString = (value: moment.Moment) => {
    if (value) {
      return value.format(this.getFormat());
    }
    return value;
  };

  getFormat = () => {
    if (this.getCalculatedDisplay() === Displays.Date) {
      return "YYYY-MM-DD";
    }
    if (this.getCalculatedDisplay() === Displays.Month) {
      return "YYYY-MM";
    }
    return "YYYY-MM-DDTHH:mm:ss";
  };

  handleChange = (value: any) => {
    const dateValue: any = this.convertToString(value);
    if (this.props.onChange) {
      this.props.onChange(dateValue);
    }
  };

  getGenericValue = () => {
    let result: any = {};
    if (this.props.value) {
      result = { value: this.convertToMoment(this.props.value) };
    }
    return result;
  };

  getGenericDefaultValue = () => {
    let result: any = {};
    if (this.props.defaultValue) {
      if (this.props.defaultValue.trim() == "") {
        return { defaultValue: null };
      }
      result = { defaultValue: this.convertToMoment(this.props.defaultValue.trim()) };
    }
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (props.defaultValue) {
      delete props.defaultValue;
    }
    if (props.disabledDate) {
      delete props.disabledDate;
    }
    if (props.value) {
      delete props.value;
    }
    if (_.isString(props.value) && props.value.length === 0) {
      delete props.value;
    }
    if (!style) {
      style = {
        width: "100%"
      };
    } else if (!style.width) {
      style.width = "100%";
    }
    if (style.display == "block") {
      style.display = "flex";
    } else if (style.display == "inline") {
      style.display = "inline-flex";
    }
    props.style = style;
    if (this.getCalculatedDisplay() === Displays.DateTime) {
      props.showHour = true;
      props.showMinute = true;
      props.showSecond = true;
    }
    return props;
  };

  getDisplayText = () => {
    const display = this.getCalculatedDisplay();
    let displayText = "Date";
    switch (display) {
      case Displays.Date:
        displayText = "Date";
        break;
      case Displays.DateTime:
        displayText = "Date & Time";
        break;
      case Displays.Time:
        displayText = "Time";
        break;
      case Displays.Month:
        displayText = "Month";
        break;
      default:
        displayText = "Date";
        break;
    }
    return displayText;
  };

  handleKeydown = (e: any) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 9 && this.state.isOpen) {
      this.setState({ isOpen: false });
      e.preventDefault();
      e.stopPropagation();
      const val = moment(e?.target?.value, this.props.format ?? "");
      if (val.isValid()) {
        this.handleChange(moment(val));
      }
      return false;
    }
    if (charCode === 9 && !this.state.isOpen) {
      if (this.props.onKeyDown) {
        this.props.onKeyDown(e, e.preventDefault());
      }
      return false;
    }
    const { format } = this.props;
    if (
      format !== "YYYY-MM-DD HH:mm:ss" &&
      format !== "DD-MM-YYYY HH:mm:ss" &&
      e.target.value.length === 10 &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 186
    ) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    if (
      charCode > 31 &&
      (charCode > 57 || charCode < 48) &&
      (charCode > 105 || charCode < 96) &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 186
    ) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    if (format === "YYYY/MM/DD" || format === "YYYY/DD/MM" || format === "YYYY-MM-DD HH:mm:ss") {
      this.addSlashToDateInput(e, 4, 7, charCode);
    } else if (format === "DD/MM/YYYY" || format === "MM/DD/YYYY" || format === "DD-MM-YYYY HH:mm:ss") {
      this.addSlashToDateInput(e, 2, 5, charCode);
    } else if (format === "MM/DD") {
      if (e.target.value.length === 5) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      this.addSlashToDateInput(e, 2, undefined, charCode);
    } else if (format === "YYYY/MM") {
      if (e.target.value.length === 7) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      this.addSlashToDateInput(e, 4, undefined, charCode);
    } else if (format === "D M Y") {
      e.target.value = e.target.value;
    } else if (format === undefined) {
      this.addSlashToDateInput(e, 4, 7, charCode);
    }
  };

  addSlashToDateInput = (e: any, firstLength: number, secondLength: number | undefined, charCode: number) => {
    if (e.target.value.length === firstLength && charCode !== 8 && charCode !== 46 && charCode !== 186) {
      e.target.value = `${e.target.value}/`;
    } else if (
      secondLength &&
      e.target.value.length === secondLength &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 186
    ) {
      e.target.value = `${e.target.value}/`;
    } else e.target.value = e.target.value.replace(/[^0-9/]/g, "");
  };

  handleOpenChange = (isOpen: boolean) => {
    if (window?.kuika?.dashboardState !== DashboardState.design) {
      this.setState({ isOpen });
    }
  };

  render(): ReactNode {
    const display = this.getDisplayText();
    return (
      <>
        {(display === "Date" || display === "Date & Time" || display === "Month") && (
          <DatePicker
            open={this.state.isOpen}
            onOpenChange={this.handleOpenChange}
            className={this.getClassName()}
            disabledDate={this.disabledDate}
            {...this.getProps()}
            showTime={display === "Date & Time" ? { defaultValue: moment("00:00:00", "HH:mm:ss") } : false}
            picker={display === "Month" ? "month" : "date"}
            {...this.getGenericValue()}
            {...this.getGenericDefaultValue()}
            onChange={this.handleChange}
            onKeyDown={(e) => this.handleKeydown(e)}
          ></DatePicker>
        )}
        {display === "Time" && (
          <TimePicker
            open={this.state.isOpen}
            onOpenChange={this.handleOpenChange}
            className={this.getClassName()}
            secondStep
            {...this.getProps()}
            {...this.getGenericValue()}
            {...this.getGenericDefaultValue()}
            onChange={this.handleChange}
          ></TimePicker>
        )}
      </>
    );
  }
}
const kDatePicker = withCommonEvents(KDatePicker);
export { kDatePicker as KDatePicker };
