import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDagitimAltList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDagitimAltList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: string;
  NAVIGATE_dummy: string;
  spDagitimList_AltCariEXCEL: any[];
  spDagitimList_AltCariEXCEL_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  GetExcelBase64: string;
  GetExcelBase64_dummy: string;
  PrintPdf: string;
  PrintPdf_dummy: string;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  sbQuarterYearList: any[];
  sbQuarterYearList_dummy: any[];
  sbQuarterList: any[];
  sbQuarterList_dummy: any[];
  sbDagitimYeriList_AltCari: any[];
  sbDagitimYeriList_AltCari_dummy: any[];
  SetValueOf: string;
  SetValueOf_dummy: string;
  FilterParametersSaveDagitimAltList: number;
  FilterParametersSaveDagitimAltList_dummy: number;
  spDagitimList_AltCari: any[];
  spDagitimList_AltCari_dummy: any[];
  spDagitimList_AltCariCount: any[];
  spDagitimList_AltCariCount_dummy: any[];
  GoBack: string;
  GoBack_dummy: string;
  isComp822531Visible: "visible" | "hidden";
  isComp871646Visible: "visible" | "hidden";
  isComp578618Visible: "visible" | "hidden";
  isComp865924Visible: "visible" | "hidden";
}

export class DagitimAltList_ScreenBase extends React.PureComponent<IDagitimAltList_ScreenProps, any> {
  dagitimaltlist_839293_value_kuikaMultiSelectRef: React.RefObject<any>;
  dagitimaltlist_330389_value_kuikaMultiSelectRef: React.RefObject<any>;
  dagitimaltlist_894279_value_kuikaMultiSelectRef: React.RefObject<any>;
  dagitimaltlist_660947_value_kuikaTableRef: React.RefObject<any>;
  dagitimaltlist_865924_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "99f89300-fd9c-46c4-a7e0-4a0845d3e85a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "DAĞITIM (Kendisi/Temsilci/Varis)" },
        { Id: 192408, PropertyName: "title", Value: "Sayfa Verilerini İndir" },
        { Id: 839293, PropertyName: "placeholder", Value: "Yıl Seçiniz..!" },
        { Id: 330389, PropertyName: "placeholder", Value: "Quarter Seçiniz..!" },
        { Id: 894279, PropertyName: "placeholder", Value: "Katalog ve/ya Eser Sahibi Seçiniz..!" },
        { Id: 598594, PropertyName: "label", Value: "Dağıtım (Eser Sahibi)" },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 133595, PropertyName: "title", Value: "Yıl" },
        { Id: 431410, PropertyName: "value", Value: "[datafield:quarteryear]" },
        { Id: 993136, PropertyName: "title", Value: "Q" },
        { Id: 343483, PropertyName: "value", Value: "[datafield:quarterkod]" },
        { Id: 822531, PropertyName: "title", Value: "Dağıtım Yeri Kodu" },
        { Id: 166617, PropertyName: "value", Value: "[datafield:dagitimyerikodu]" },
        { Id: 468136, PropertyName: "title", Value: "Dağıtım Yeri" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:alt_cariad]" },
        { Id: 885016, PropertyName: "title", Value: "Eser Sahibi Hakediş" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:ttutar]" },
        { Id: 450855, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 608287, PropertyName: "title", Value: "Kendisi/Temsilci/Varis Pay" },
        { Id: 602093, PropertyName: "value", Value: "[datafield:thakedistutar]" },
        { Id: 62324, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 547217, PropertyName: "title", Value: "StopajTutar" },
        { Id: 332798, PropertyName: "value", Value: "[datafield:tstopajtutar]" },
        { Id: 129949, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 213343, PropertyName: "title", Value: "Ödenecek Tutar" },
        { Id: 25124, PropertyName: "value", Value: "[datafield:todenecektutar]" },
        { Id: 352398, PropertyName: "value", Value: "[datafield:_simge]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dagitimaltlist_839293_value_kuikaMultiSelectRef = React.createRef();
    this.dagitimaltlist_330389_value_kuikaMultiSelectRef = React.createRef();
    this.dagitimaltlist_894279_value_kuikaMultiSelectRef = React.createRef();
    this.dagitimaltlist_660947_value_kuikaTableRef = React.createRef();
    this.dagitimaltlist_865924_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      spDagitimList_AltCariEXCEL: [],
      ExportExcel: "",
      GetExcelBase64: "",
      PrintPdf: "",
      getUserInfo: [],
      getFilterParametersByUserName: [],
      sbQuarterYearList: [],
      sbQuarterList: [],
      sbDagitimYeriList_AltCari: [],
      SetValueOf: "",
      FilterParametersSaveDagitimAltList: "",
      spDagitimList_AltCari: [],
      spDagitimList_AltCariCount: [],
      GoBack: "",
      isComp822531Visible: "hidden",
      isComp871646Visible: "hidden",
      isComp578618Visible: "hidden",
      isComp865924Visible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dagitimaltlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DagitimAltListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dagitimaltlist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dagitimaltlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DagitimAltListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dagitimaltlist_839293_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimAltListYil ?? undefined,
      dagitimaltlist_330389_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimAltListQ ?? undefined,
      dagitimaltlist_894279_value: this.state.getFilterParametersByUserName?.at?.(0)?.dagitimAltListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DagitimAltListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimAltList/DagitimAltListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.dagitimaltlist_839293_options = stateVars.sbQuarterYearList;
    formVars.dagitimaltlist_839293_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListYil
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListYil
        : null;
    formVars.dagitimaltlist_330389_options = stateVars.sbQuarterList;
    formVars.dagitimaltlist_330389_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListQ
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListQ
        : null;
    formVars.dagitimaltlist_894279_options = stateVars.sbDagitimYeriList_AltCari;
    formVars.dagitimaltlist_894279_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListSearch
        : null;
    stateVars.sbQuarterYearList = result?.data.sbQuarterYearList;

    formVars.dagitimaltlist_839293_options = stateVars.sbQuarterYearList;
    formVars.dagitimaltlist_839293_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListYil
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListYil
        : null;
    stateVars.sbQuarterList = result?.data.sbQuarterList;

    formVars.dagitimaltlist_330389_options = stateVars.sbQuarterList;
    formVars.dagitimaltlist_330389_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListQ
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListQ
        : null;
    stateVars.sbDagitimYeriList_AltCari = result?.data.sbDagitimYeriList_AltCari;

    formVars.dagitimaltlist_894279_options = stateVars.sbDagitimYeriList_AltCari;
    formVars.dagitimaltlist_894279_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListSearch
        : null;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimAltListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimAltListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("dagitimaltlist_839293_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].dagitimAltListYil
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].dagitimAltListYil
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("dagitimaltlist_330389_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].dagitimAltListQ
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].dagitimAltListQ
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("dagitimaltlist_894279_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].dagitimAltListSearch
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].dagitimAltListSearch
          : null
      ),
      null
    );

    formVars.dagitimaltlist_839293_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListYil
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListYil
        : null
    );

    stateVars.dataSource_839293 = this.state.sbQuarterYearList;
    stateVars.dataSource_839293 = this.state.sbQuarterYearList;

    formVars.dagitimaltlist_330389_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListQ
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListQ
        : null
    );

    stateVars.dataSource_330389 = this.state.sbQuarterList;
    stateVars.dataSource_330389 = this.state.sbQuarterList;

    formVars.dagitimaltlist_894279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].dagitimAltListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].dagitimAltListSearch
        : null
    );

    stateVars.dataSource_894279 = this.state.sbDagitimYeriList_AltCari;
    stateVars.dataSource_894279 = this.state.sbDagitimYeriList_AltCari;
    stateVars.isComp865924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spDagitimList_AltCariCount?.length > 0
            ? stateVars.spDagitimList_AltCariCount[0].dagitimList_AltCariCount
            : this.state.spDagitimList_AltCariCount?.length > 0
            ? this.state.spDagitimList_AltCariCount[0].dagitimList_AltCariCount
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DagitimAltListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimAltList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimAltListComponent_192205_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_839293_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_330389_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("DAĞITIM LİSTESİ", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      header_2: ReactSystemFunctions.convertToTypeByName("DAĞITIM LİSTESİ", "string"),
      footer_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimAltList/DagitimAltListComponent_192205_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimList_AltCariEXCEL = result?.data.spDagitimList_AltCariEXCEL;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    stateVars.GetExcelBase64 = result?.data.getExcelBase64;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimAltListComponent_192205_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimAltListComponent_192205_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.GetExcelBase64)
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimAltListComponent_270085_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      DagitimAltListPagination_0: ReactSystemFunctions.convertToTypeByName("1", "number"),
      DagitimAltListQ_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_330389_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimAltListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      DagitimAltListYil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_839293_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimAltList/DagitimAltListComponent_270085_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveDagitimAltList = result?.data.filterParametersSaveDagitimAltList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimAltListComponent_270085_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimAltListComponent_270085_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("dagitimaltlist_865924_value", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "any"),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimAltListComponent_270085_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DagitimAltListComponent_270085_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_839293_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_330389_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimaltlist_865924_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimaltlist_865924_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_839293_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_330389_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimAltList/DagitimAltListComponent_270085_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimList_AltCari = result?.data.spDagitimList_AltCari;

    stateVars.spDagitimList_AltCariCount = result?.data.spDagitimList_AltCariCount;
    stateVars.isComp865924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spDagitimList_AltCariCount?.length > 0
            ? stateVars.spDagitimList_AltCariCount[0].dagitimList_AltCariCount
            : this.state.spDagitimList_AltCariCount?.length > 0
            ? this.state.spDagitimList_AltCariCount[0].dagitimList_AltCariCount
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimAltListComponent_270085_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimAltListComponent_270085_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.spDagitimList_AltCari;
    formVars.dagitimaltlist_865924_total = ReactSystemFunctions.value(
      this,
      stateVars.spDagitimList_AltCariCount?.length > 0
        ? stateVars.spDagitimList_AltCariCount[0].dagitimList_AltCariCount
        : this.state.spDagitimList_AltCariCount?.length > 0
        ? this.state.spDagitimList_AltCariCount[0].dagitimList_AltCariCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimAltListComponent_598594_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimAltListComponent_871646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmKategoriKodu",
      ReactSystemFunctions.value(this, "dagitimaltlist_660947_value", "dagitimYeriKodu")
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmSimge",
      ReactSystemFunctions.value(this, "dagitimaltlist_660947_value", "_simge")
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmQuarterKod",
      ReactSystemFunctions.value(this, "dagitimaltlist_660947_value", "quarterKod")
    );
    KuikaAppManager.addToPageInputVariables(
      "DagitimDetailList",
      "prmQuarterYear",
      ReactSystemFunctions.value(this, "dagitimaltlist_660947_value", "quarterYear")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DagitimAltList",
      "DagitimDetailList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DagitimAltListComponent_865924_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      QuarterYear_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_839293_value",
            "value",
            "sbQuarterYearList",
            "quarterYear",
            "quarterYear"
          )
        ),
        "string"
      ),
      QuarterKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_330389_value",
            "value",
            "sbQuarterList",
            "quarterKod",
            "quarterKod"
          )
        ),
        "string"
      ),
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dagitimaltlist_894279_value",
            "value",
            "sbDagitimYeriList_AltCari",
            "alt_CariId",
            "alt_CariId"
          )
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimaltlist_865924_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dagitimaltlist_865924_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DagitimAltList/DagitimAltListComponent_865924_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spDagitimList_AltCari = result?.data.spDagitimList_AltCari;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimAltListComponent_865924_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimAltListComponent_865924_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.spDagitimList_AltCari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DagitimAltListPageInit();
    }
  }
}
