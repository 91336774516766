import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IFiyatlandirmaOzelKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IFiyatlandirmaOzelKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setKullanimSureleriUpdate: number;
  setKullanimSureleriUpdate_dummy: number;
  setKullanimSureleriInsertKart: number;
  setKullanimSureleriInsertKart_dummy: number;
  GoBack: string;
  GoBack_dummy: string;
  sbKullanimTuruListByRefKod: any[];
  sbKullanimTuruListByRefKod_dummy: any[];
  sbParaBirim: any[];
  sbParaBirim_dummy: any[];
  sbEserTipiINPopupFiyatlandirme: any[];
  sbEserTipiINPopupFiyatlandirme_dummy: any[];
  sbStokAdList: any[];
  sbStokAdList_dummy: any[];
  getKartKullanimSureleriById: any[];
  getKartKullanimSureleriById_dummy: any[];
  sbFiyatlandirmaTipi: any[];
  sbFiyatlandirmaTipi_dummy: any[];
  ChangeEnabledOf: string;
  ChangeEnabledOf_dummy: string;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  spGetFiyatListesindenBirimFiyat: any[];
  spGetFiyatListesindenBirimFiyat_dummy: any[];
  SetValueOf: string;
  SetValueOf_dummy: string;
  isComp608174Visible: "visible" | "hidden";
  isComp437788Visible: "visible" | "hidden";
  isComp268217Visible: "visible" | "hidden";
  isComp623453Visible: "visible" | "hidden";
  isComp863683Visible: "visible" | "hidden";
  isComp836646Visible: "visible" | "hidden";
  isComp225398Enabled: "enabled" | "disabled";
  isComp623453Enabled: "enabled" | "disabled";
}

export class FiyatlandirmaOzelKart_ScreenBase extends React.PureComponent<IFiyatlandirmaOzelKart_ScreenProps, any> {
  fiyatlandirmaozelkart_65727_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlandirmaozelkart_893293_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlandirmaozelkart_225398_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlandirmaozelkart_449985_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlandirmaozelkart_613812_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlandirmaozelkart_325713_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "b90dee6b-059a-4501-b202-879a86fc9bb4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 608676, PropertyName: "label", Value: "Kaydet" },
        { Id: 856150, PropertyName: "value", Value: "Müzik Kod / Ad Eser Açıklama" },
        { Id: 953737, PropertyName: "placeholder", Value: "..." },
        { Id: 838135, PropertyName: "value", Value: "Proje/Versiyon Bilgi" },
        { Id: 65727, PropertyName: "placeholder", Value: "..." },
        { Id: 917209, PropertyName: "value", Value: "Eser Tipi" },
        { Id: 929635, PropertyName: "value", Value: "Eser" },
        { Id: 738481, PropertyName: "value", Value: "Eser Sahibi" },
        { Id: 393626, PropertyName: "value", Value: "Fiyatlandırma Tipi" },
        { Id: 613812, PropertyName: "placeholder", Value: "..." },
        { Id: 635362, PropertyName: "value", Value: "Adet" },
        { Id: 236853, PropertyName: "value", Value: "Kullanım Süresi" },
        { Id: 891560, PropertyName: "placeholder", Value: "Dk." },
        { Id: 310591, PropertyName: "placeholder", Value: "Sn." },
        { Id: 268217, PropertyName: "value", Value: "Liste Fiyatı" },
        { Id: 863683, PropertyName: "value", Value: "Özel Fiyat" },
        { Id: 836646, PropertyName: "value", Value: "Fiyat" },
        { Id: 882920, PropertyName: "value", Value: "Para Birimi" },
        { Id: 325713, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.fiyatlandirmaozelkart_65727_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlandirmaozelkart_893293_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlandirmaozelkart_225398_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlandirmaozelkart_449985_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlandirmaozelkart_613812_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlandirmaozelkart_325713_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setKullanimSureleriUpdate: 0,
      setKullanimSureleriInsertKart: 0,
      GoBack: "",
      sbKullanimTuruListByRefKod: [],
      sbParaBirim: [],
      sbEserTipiINPopupFiyatlandirme: [],
      sbStokAdList: [],
      getKartKullanimSureleriById: [],
      sbFiyatlandirmaTipi: [],
      ChangeEnabledOf: "",
      ChangeVisibilityOf: "",
      spGetFiyatListesindenBirimFiyat: [],
      SetValueOf: "",
      isComp608174Visible: "hidden",
      isComp437788Visible: "hidden",
      isComp268217Visible: "hidden",
      isComp623453Visible: "hidden",
      isComp863683Visible: "hidden",
      isComp836646Visible: "hidden",
      isComp225398Enabled: "enabled",
      isComp623453Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("fiyatlandirmaozelkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.FiyatlandirmaOzelKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("fiyatlandirmaozelkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("fiyatlandirmaozelkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.FiyatlandirmaOzelKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      fiyatlandirmaozelkart_953737_value: this.state.getKartKullanimSureleriById?.at?.(0)?.muzikKod ?? undefined,
      fiyatlandirmaozelkart_65727_value: this.state.getKartKullanimSureleriById?.at?.(0)?.kullanimTuruID ?? undefined,
      fiyatlandirmaozelkart_225398_value: this.state.getKartKullanimSureleriById?.at?.(0)?.muzikotekStokID ?? undefined,
      fiyatlandirmaozelkart_449985_value: this.state.getKartKullanimSureleriById?.at?.(0)?.muzikotekStokID ?? undefined,
      fiyatlandirmaozelkart_613812_value:
        this.state.getKartKullanimSureleriById?.at?.(0)?.fiyatlandirmaTipiID ?? undefined,
      fiyatlandirmaozelkart_589195_value: this.state.getKartKullanimSureleriById?.at?.(0)?.sureSaat ?? undefined,
      fiyatlandirmaozelkart_891560_value: this.state.getKartKullanimSureleriById?.at?.(0)?.sureDakika ?? undefined,
      fiyatlandirmaozelkart_310591_value: this.state.getKartKullanimSureleriById?.at?.(0)?.sureSaniye ?? undefined,
      fiyatlandirmaozelkart_623453_value: this.state.getKartKullanimSureleriById?.at?.(0)?.fiyat ?? undefined,
      fiyatlandirmaozelkart_650664_value: this.state.getKartKullanimSureleriById?.at?.(0)?.fiyatOzel ?? undefined,
      fiyatlandirmaozelkart_325713_value: this.state.getKartKullanimSureleriById?.at?.(0)?.paraBirimOzelID ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  FiyatlandirmaOzelKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PTuru ?? this.props.screenInputs.pturu,
        "string"
      ),
      BasvuruTuruToEserTipiID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu,
        "number"
      ),
      EserTipiID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_893293_value",
            "value",
            "sbEserTipiINPopupFiyatlandirme",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Id_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ProjeTuruRefKod_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PTuru ?? this.props.screenInputs.pturu,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "FiyatlandirmaOzelKart/FiyatlandirmaOzelKartPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbKullanimTuruListByRefKod = result?.data.sbKullanimTuruListByRefKod;

    formVars.fiyatlandirmaozelkart_65727_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].kullanimTuruID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].kullanimTuruID
        : null;
    formVars.fiyatlandirmaozelkart_65727_options = stateVars.sbKullanimTuruListByRefKod;
    stateVars.sbParaBirim = result?.data.sbParaBirim;

    formVars.fiyatlandirmaozelkart_325713_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].paraBirimOzelID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].paraBirimOzelID
        : null;
    formVars.fiyatlandirmaozelkart_325713_options = stateVars.sbParaBirim;
    stateVars.sbEserTipiINPopupFiyatlandirme = result?.data.sbEserTipiINPopupFiyatlandirme;

    formVars.fiyatlandirmaozelkart_893293_options = stateVars.sbEserTipiINPopupFiyatlandirme;
    stateVars.sbStokAdList = result?.data.sbStokAdList;

    formVars.fiyatlandirmaozelkart_225398_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikotekStokID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikotekStokID
        : null;
    formVars.fiyatlandirmaozelkart_225398_options = stateVars.sbStokAdList;

    formVars.fiyatlandirmaozelkart_449985_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikotekStokID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikotekStokID
        : null;
    formVars.fiyatlandirmaozelkart_449985_options = stateVars.sbStokAdList;
    stateVars.getKartKullanimSureleriById = result?.data.getKartKullanimSureleriById;
    formVars.fiyatlandirmaozelkart_953737_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikKod
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikKod
        : null
    );
    formVars.fiyatlandirmaozelkart_65727_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].kullanimTuruID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].kullanimTuruID
        : null;
    formVars.fiyatlandirmaozelkart_65727_options = stateVars.sbKullanimTuruListByRefKod;
    formVars.fiyatlandirmaozelkart_225398_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikotekStokID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikotekStokID
        : null;
    formVars.fiyatlandirmaozelkart_225398_options = stateVars.sbStokAdList;
    formVars.fiyatlandirmaozelkart_449985_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikotekStokID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikotekStokID
        : null;
    formVars.fiyatlandirmaozelkart_449985_options = stateVars.sbStokAdList;
    formVars.fiyatlandirmaozelkart_613812_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].fiyatlandirmaTipiID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].fiyatlandirmaTipiID
        : null;
    formVars.fiyatlandirmaozelkart_613812_options = stateVars.sbFiyatlandirmaTipi;
    formVars.fiyatlandirmaozelkart_589195_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].sureSaat
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].sureSaat
        : null
    );
    formVars.fiyatlandirmaozelkart_891560_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].sureDakika
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].sureDakika
        : null
    );
    formVars.fiyatlandirmaozelkart_310591_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].sureSaniye
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].sureSaniye
        : null
    );
    formVars.fiyatlandirmaozelkart_623453_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].fiyat
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].fiyat
        : null
    );
    formVars.fiyatlandirmaozelkart_650664_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].fiyatOzel
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].fiyatOzel
        : null
    );
    formVars.fiyatlandirmaozelkart_325713_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].paraBirimOzelID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].paraBirimOzelID
        : null;
    formVars.fiyatlandirmaozelkart_325713_options = stateVars.sbParaBirim;
    stateVars.sbFiyatlandirmaTipi = result?.data.sbFiyatlandirmaTipi;

    formVars.fiyatlandirmaozelkart_613812_value =
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].fiyatlandirmaTipiID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].fiyatlandirmaTipiID
        : null;
    formVars.fiyatlandirmaozelkart_613812_options = stateVars.sbFiyatlandirmaTipi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "E")) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp225398Enabled", "string"),
        ReactSystemFunctions.convertToTypeByName("disabled", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "N")) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp225398Enabled", "string"),
        ReactSystemFunctions.convertToTypeByName("enabled", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "2")) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp225398Enabled", "string"),
        ReactSystemFunctions.convertToTypeByName("enabled", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp623453Enabled", "string"),
      ReactSystemFunctions.convertToTypeByName("disabled", "string")
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "2x")) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp608174Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.fiyatlandirmaozelkart_953737_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikKod
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikKod
        : null
    );

    formVars.fiyatlandirmaozelkart_65727_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].kullanimTuruID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].kullanimTuruID
        : null
    );

    stateVars.dataSource_65727 = this.state.sbKullanimTuruListByRefKod;
    stateVars.dataSource_65727 = this.state.sbKullanimTuruListByRefKod;

    stateVars.dataSource_893293 = this.state.sbEserTipiINPopupFiyatlandirme;
    stateVars.dataSource_893293 = this.state.sbEserTipiINPopupFiyatlandirme;
    formVars.fiyatlandirmaozelkart_225398_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikotekStokID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikotekStokID
        : null
    );

    stateVars.dataSource_225398 = this.state.sbStokAdList;
    stateVars.dataSource_225398 = this.state.sbStokAdList;
    formVars.fiyatlandirmaozelkart_449985_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].muzikotekStokID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].muzikotekStokID
        : null
    );

    stateVars.dataSource_449985 = this.state.sbStokAdList;
    stateVars.dataSource_449985 = this.state.sbStokAdList;
    formVars.fiyatlandirmaozelkart_613812_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].fiyatlandirmaTipiID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].fiyatlandirmaTipiID
        : null
    );

    stateVars.dataSource_613812 = this.state.sbFiyatlandirmaTipi;
    stateVars.dataSource_613812 = this.state.sbFiyatlandirmaTipi;
    formVars.fiyatlandirmaozelkart_589195_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].sureSaat
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].sureSaat
        : null
    );

    formVars.fiyatlandirmaozelkart_891560_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].sureDakika
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].sureDakika
        : null
    );

    formVars.fiyatlandirmaozelkart_310591_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].sureSaniye
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].sureSaniye
        : null
    );

    stateVars.isComp268217Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "1") === true
        ? "visible"
        : "hidden";
    formVars.fiyatlandirmaozelkart_623453_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].fiyat
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].fiyat
        : null
    );

    stateVars.isComp623453Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "1") === true
        ? "visible"
        : "hidden";
    stateVars.isComp863683Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "1") === true
        ? "visible"
        : "hidden";
    stateVars.isComp836646Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "2") === true
        ? "visible"
        : "hidden";
    formVars.fiyatlandirmaozelkart_650664_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].fiyatOzel
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].fiyatOzel
        : null
    );

    formVars.fiyatlandirmaozelkart_325713_value = ReactSystemFunctions.value(
      this,
      stateVars.getKartKullanimSureleriById?.length > 0
        ? stateVars.getKartKullanimSureleriById[0].paraBirimOzelID
        : this.state.getKartKullanimSureleriById?.length > 0
        ? this.state.getKartKullanimSureleriById[0].paraBirimOzelID
        : null
    );

    stateVars.dataSource_325713 = this.state.sbParaBirim;
    stateVars.dataSource_325713 = this.state.sbParaBirim;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  FiyatlandirmaOzelKartComponent_608676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_650664_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_650664_value",
      condition: ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "2")
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_325713_value", "value", "sbParaBirim", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_325713_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_225398_value", "value", "sbStokAdList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_225398_value",
      condition: ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "1")
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_589195_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_589195_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_65727_value",
            "value",
            "sbKullanimTuruListByRefKod",
            "id",
            "id"
          )
        ),
        null
      ),
      message: "Kullanım Türü Bilgisi Seçilmeli!",
      formName: "fiyatlandirmaozelkart_65727_value",
      condition: ReactSystemFunctions.isEqualTo(this.props.screenInputs.PTuru ?? this.props.screenInputs.pturu, "x")
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FiyatOzel_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_650664_value", "value", "", "", "")
        ),
        "number"
      ),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_953737_value", "value", "", "", "")
        ),
        "string"
      ),
      ParaBirimOzelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_325713_value", "value", "sbParaBirim", "id", "id")
        ),
        "Guid"
      ),
      MuzikotekStokID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_225398_value", "value", "sbStokAdList", "id", "id")
        ),
        "Guid"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_891560_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_589195_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_310591_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_65727_value",
            "value",
            "sbKullanimTuruListByRefKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      FiyatlandirmaTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_613812_value",
            "value",
            "sbFiyatlandirmaTipi",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "E")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "FiyatlandirmaOzelKart/FiyatlandirmaOzelKartComponent_608676_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKullanimSureleriUpdate = result?.data.setKullanimSureleriUpdate;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartComponent_608676_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartComponent_608676_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartComponent_608676_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_589195_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_589195_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_225398_value", "value", "sbStokAdList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_225398_value",
      condition: ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "1")
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_65727_value",
            "value",
            "sbKullanimTuruListByRefKod",
            "id",
            "id"
          )
        ),
        null
      ),
      message: "Kullanım Türü Bilgisi Seçilmeli!",
      formName: "fiyatlandirmaozelkart_65727_value",
      condition: ReactSystemFunctions.isEqualTo(this.props.screenInputs.PTuru ?? this.props.screenInputs.pturu, "x")
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_650664_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_650664_value",
      condition: ReactSystemFunctions.isEqualTo(this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu, "2")
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_325713_value", "value", "sbParaBirim", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "fiyatlandirmaozelkart_325713_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      NEWID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_953737_value", "value", "", "", "")
        ),
        "string"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_589195_value", "value", "", "", "")
        ),
        "number"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_891560_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_310591_value", "value", "", "", "")
        ),
        "number"
      ),
      MuzikotekStokID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_225398_value", "value", "sbStokAdList", "id", "id")
        ),
        "Guid"
      ),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_65727_value",
            "value",
            "sbKullanimTuruListByRefKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BFId ?? this.props.screenInputs.bfid,
        "Guid"
      ),
      FiyatOzel_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_650664_value", "value", "", "", "")
        ),
        "number"
      ),
      ParaBirimOzelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmaozelkart_325713_value", "value", "sbParaBirim", "id", "id")
        ),
        "Guid"
      ),
      FiyatlandirmaTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_613812_value",
            "value",
            "sbFiyatlandirmaTipi",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "N")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "FiyatlandirmaOzelKart/FiyatlandirmaOzelKartComponent_608676_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKullanimSureleriInsertKart = result?.data.setKullanimSureleriInsertKart;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartComponent_608676_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.FiyatlandirmaOzelKartComponent_608676_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartComponent_608676_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatlandirmaOzelKartComponent_463063_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatlandirmaOzelKartComponent_893293_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruTuruToEserTipiID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BTuru ?? this.props.screenInputs.bturu,
        "number"
      ),
      EserTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_893293_value",
            "value",
            "sbEserTipiINPopupFiyatlandirme",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "FiyatlandirmaOzelKart/FiyatlandirmaOzelKartComponent_893293_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbStokAdList = result?.data.sbStokAdList;

    formVars.fiyatlandirmaozelkart_225398_options = stateVars.sbStokAdList;

    formVars.fiyatlandirmaozelkart_449985_options = stateVars.sbStokAdList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatlandirmaOzelKartComponent_893293_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartComponent_893293_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_225398 = this.state.sbStokAdList;
    stateVars.dataSource_225398 = this.state.sbStokAdList;

    stateVars.dataSource_449985 = this.state.sbStokAdList;
    stateVars.dataSource_449985 = this.state.sbStokAdList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatlandirmaOzelKartComponent_613812_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KSId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      FiyatlandirmaTipiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmaozelkart_613812_value",
            "value",
            "sbFiyatlandirmaTipi",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "FiyatlandirmaOzelKart/FiyatlandirmaOzelKartComponent_613812_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetFiyatListesindenBirimFiyat = result?.data.spGetFiyatListesindenBirimFiyat;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatlandirmaOzelKartComponent_613812_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaOzelKartComponent_613812_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("fiyatlandirmaozelkart_623453_value", "string"),
      ReactSystemFunctions.value(
        this,
        stateVars.spGetFiyatListesindenBirimFiyat?.length > 0
          ? stateVars.spGetFiyatListesindenBirimFiyat[0].birimFiyat
          : this.state.spGetFiyatListesindenBirimFiyat?.length > 0
          ? this.state.spGetFiyatListesindenBirimFiyat[0].birimFiyat
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
