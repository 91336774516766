import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITProjeTuru_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITProjeTuru_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: string;
  GoBack_dummy: string;
  ProjeTuruSelectById: any[];
  ProjeTuruSelectById_dummy: any[];
  ProjeTuruSave: number;
  ProjeTuruSave_dummy: number;
}

export class TProjeTuru_Form_ScreenBase extends React.PureComponent<ITProjeTuru_Form_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "19b4aab1-2b39-465b-b971-8721164808fb",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 969349, PropertyName: "value", Value: "Proje Türü Form" },
        { Id: 5207271, PropertyName: "value", Value: "Aktif" },
        { Id: 9736910, PropertyName: "value", Value: "Sira" },
        { Id: 4934427, PropertyName: "value", Value: "Tanim" },
        { Id: 83328630, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 4475104, PropertyName: "value", Value: "Uzun tanim" },
        { Id: 42124670, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 3002963, PropertyName: "value", Value: "Ref kod" },
        { Id: 24663130, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 3336936, PropertyName: "value", Value: "Proje turu ref kod" },
        { Id: 20442090, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9544, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      ProjeTuruSelectById: [],
      ProjeTuruSave: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tprojeturu_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TProjeTuru_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tprojeturu_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tprojeturu_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TProjeTuru_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tprojeturu_form_57731350_value: this.state.ProjeTuruSelectById?.at?.(0)?.aktif ?? undefined,
      tprojeturu_form_20534630_value: this.state.ProjeTuruSelectById?.at?.(0)?.sira ?? undefined,
      tprojeturu_form_83328630_value: this.state.ProjeTuruSelectById?.at?.(0)?.tanim ?? undefined,
      tprojeturu_form_42124670_value: this.state.ProjeTuruSelectById?.at?.(0)?.uzunTanim ?? undefined,
      tprojeturu_form_24663130_value: this.state.ProjeTuruSelectById?.at?.(0)?.refKod ?? undefined,
      tprojeturu_form_20442090_value: this.state.ProjeTuruSelectById?.at?.(0)?.projeTuruRefKod ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TProjeTuru_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TProjeTuru_Form/TProjeTuru_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProjeTuruSelectById = result?.data.projeTuruSelectById;
    formVars.tprojeturu_form_57731350_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].aktif
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].aktif
        : null
    );
    formVars.tprojeturu_form_20534630_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].sira
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].sira
        : null
    );
    formVars.tprojeturu_form_83328630_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].tanim
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].tanim
        : null
    );
    formVars.tprojeturu_form_42124670_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].uzunTanim
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].uzunTanim
        : null
    );
    formVars.tprojeturu_form_24663130_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].refKod
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].refKod
        : null
    );
    formVars.tprojeturu_form_20442090_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].projeTuruRefKod
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].projeTuruRefKod
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TProjeTuru_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TProjeTuru_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.tprojeturu_form_57731350_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].aktif
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].aktif
        : null
    );

    formVars.tprojeturu_form_20534630_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].sira
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].sira
        : null
    );

    formVars.tprojeturu_form_83328630_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].tanim
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].tanim
        : null
    );

    formVars.tprojeturu_form_42124670_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].uzunTanim
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].uzunTanim
        : null
    );

    formVars.tprojeturu_form_24663130_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].refKod
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].refKod
        : null
    );

    formVars.tprojeturu_form_20442090_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProjeTuruSelectById?.length > 0
        ? stateVars.ProjeTuruSelectById[0].projeTuruRefKod
        : this.state.ProjeTuruSelectById?.length > 0
        ? this.state.ProjeTuruSelectById[0].projeTuruRefKod
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TProjeTuru_FormComponent_887155_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TProjeTuru_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      Aktif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tprojeturu_form_57731350_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tprojeturu_form_20442090_value", "value", "", "", "")
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tprojeturu_form_24663130_value", "value", "", "", "")
        ),
        "string"
      ),
      Sira_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tprojeturu_form_20534630_value", "value", "", "", "")
        ),
        "number"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tprojeturu_form_83328630_value", "value", "", "", "")
        ),
        "string"
      ),
      UzunTanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tprojeturu_form_42124670_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TProjeTuru_Form/TProjeTuru_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProjeTuruSave = result?.data.projeTuruSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TProjeTuru_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TProjeTuru_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
