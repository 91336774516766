import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import { Button, Header, Icon, KCol, KRow, Label, TextInput } from "../../components/web-components";
import { KSelectBox, Spacer } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { BaglantiliMeslekBirligiKart_ScreenBase } from "./baglantilimeslekbirligikart-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class BaglantiliMeslekBirligiKart_Screen extends BaglantiliMeslekBirligiKart_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              baglantilimeslekbirligikart_131824_value:
                this.state.getBaglantiliMeslekBirligiKartById?.at?.(0)?.meslekBirligiAdID ?? undefined,
              baglantilimeslekbirligikart_980508_value:
                this.state.getBaglantiliMeslekBirligiKartById?.at?.(0)?.kayitliESAdi ?? undefined,
              baglantilimeslekbirligikart_302589_value:
                this.state.getBaglantiliMeslekBirligiKartById?.at?.(0)?.ipi ?? undefined,
              baglantilimeslekbirligikart_954747_value:
                this.state.getBaglantiliMeslekBirligiKartById?.at?.(0)?.korumaBolgesiID ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="baglantilimeslekbirligikart_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    letterSpacing: "0.5px"
                  } as any
                }
              >
                <KRow
                  id="277645"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="8014"
                    xs={16}
                    sm={16}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="683518"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Icon
                      id="339660"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.BaglantiliMeslekBirligiKartComponent_339660_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontSize: "24px",
                          color: "rgba(172, 38, 42, 1)"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="baglantilimeslekbirligikart_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="619378"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="432113"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="400481"
                      value={ReactSystemFunctions.translate(this.ml, 400481, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="baglantilimeslekbirligikart_131824_value">
                      <KSelectBox
                        id="131824"
                        kuikaRef={this.baglantilimeslekbirligikart_131824_value_kuikaSelectBoxRef}
                        options={this.state.sbMeslekBirligiAdList}
                        placeholder={ReactSystemFunctions.translate(this.ml, 131824, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        showSearch={true}
                        onSearch={{}}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="tanim"
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="187805"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="606402"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="993411"
                      value={ReactSystemFunctions.translate(this.ml, 993411, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="baglantilimeslekbirligikart_980508_value">
                      <TextInput
                        id="980508"
                        placeholder={ReactSystemFunctions.translate(this.ml, 980508, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="675593"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="479358"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="11299"
                      value={ReactSystemFunctions.translate(this.ml, 11299, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="baglantilimeslekbirligikart_302589_value">
                      <TextInput
                        id="302589"
                        placeholder={ReactSystemFunctions.translate(this.ml, 302589, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="448302"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="964991"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="979337"
                      value={ReactSystemFunctions.translate(this.ml, 979337, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="baglantilimeslekbirligikart_954747_value">
                      <KSelectBox
                        id="954747"
                        kuikaRef={this.baglantilimeslekbirligikart_954747_value_kuikaSelectBoxRef}
                        options={this.state.sbKorumaBolgesiList}
                        placeholder={ReactSystemFunctions.translate(this.ml, 954747, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        showSearch={true}
                        onSearch={{}}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="tanim"
                        style={
                          {
                            borderBottomWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            height: "36px",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgba(47, 47, 47, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="87166"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="602881"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Spacer id="720381" direction="horizontal" size={10} style={{} as any}>
                      <Button
                        id="604367"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.BaglantiliMeslekBirligiKartComponent_604367_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 604367, "label", this.defaultML)}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 200,
                            borderTopRightRadius: 200,
                            borderBottomRightRadius: 200,
                            borderBottomLeftRadius: 200,
                            borderColor: "rgba(172, 38, 42, 1)",
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(172, 38, 42, 1)",
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            width: "140px",
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></Button>
                    </Spacer>
                  </KCol>
                </KRow>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(
      withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(BaglantiliMeslekBirligiKart_Screen)))
    )
  )
);
export { tmp as BaglantiliMeslekBirligiKart_Screen };
//export default tmp;
//export { tmp as BaglantiliMeslekBirligiKart_Screen };
